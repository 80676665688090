import axios from "axios";
import { EntryProtocolAttachmentDTO, EntryProtocolCreationUpdateDTO, EntryProtocolDTO, EntryProtocolObjectDTO, GetAllEntryProtocolAttachmentsDTO, GetAllEntryProtocolAttachmentsFiltersDTO, GetAllEntryProtocolsDTO, GetAllEntryProtocolsFiltersDTO, ReportRequestDTO, ReportRequestExcelDTO } from "./dto";
import { EntryProtocolService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { format } from 'date-fns';

export class EntryProtocolServiceImpl implements EntryProtocolService {

    // ENTRY PROTOCOL
    public CreateEntryProtocol(data: EntryProtocolCreationUpdateDTO): Promise<string> {
        return axios.post("/api/entry-protocol",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateEntryProtocol(data: FormData, id: string): Promise<string> {
        return axios.put("/api/entry-protocol/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteEntryProtocol(id: string): Promise<void> {
        return axios.delete("/api/entry-protocol/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllEntryProtocols(filters: GetAllEntryProtocolsFiltersDTO): Promise<GetAllEntryProtocolsDTO> {

        return axios.get("/api/entry-protocol", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                attachment: filters.attachmentId !== '' ? filters.attachmentId : undefined,
                customer: filters.client !== '' ? filters.client : undefined,
                entryChannel: filters.entryMeans !== '' ? filters.entryMeans : undefined,
                from: filters.from  ? format(filters.from , 'yyyy-MM-dd') : undefined,
                to: filters.to ? format(filters.to , 'yyyy-MM-dd') : undefined,
                date: filters.protocolDay  ? format(filters.protocolDay , 'yyyy-MM-dd') : undefined,
                headQuarterOffice: filters.headquarter != '' ? filters.headquarter : undefined,
                object: filters.objectId !== '' ?  filters.objectId : undefined,
                objectSpec: filters.objectSpec !== '' ?  filters.objectSpec : undefined,
                sender: filters.sender !== '' ? filters.sender : undefined,
                year: filters.year !== '' ? filters.year : undefined,
                protocolDate: filters.protocolDate ? format(filters.protocolDate, 'yyyy-MM-dd') : undefined,
                sort: filters.sort,
                number: filters.number !== '' ?  filters.number : undefined
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetDistinta(filters: ReportRequestDTO): Promise<any> {
        return axios.get("/api/download/entry-protocol-distinta", {
            params: {
                attachments: filters.attachments,
                entryChannel: filters.entryChannel !== '' ? filters.entryChannel : undefined,
                destinationOffice: filters.destinationOffice !== '' ? filters.destinationOffice : undefined,
                startProtocolNumber: filters.startProtocoloNumber !== ''  ? filters.startProtocoloNumber : undefined,
                endProtocolNumber: filters.endProtocolNumber !== ''  ? filters.endProtocolNumber : undefined,
                headQuarterOffice: filters.headQuarterOffice != '' ? filters.headQuarterOffice : undefined,
                year: filters.year !== '' ? filters.year : undefined,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => {
            const base64String = response.data;

            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/pdf',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'distinta.pdf');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public GetProtExcel(filters: ReportRequestExcelDTO): Promise<any> {
        return axios.get("/api/download/entry-protocol-excel", {
            params: {
                from: filters.from  ? format(filters.from , 'yyyy-MM-dd') : undefined,
                headQuarterOffice: filters.headQuarterOffice != '' ? filters.headQuarterOffice : undefined,
                to: filters.to  ? format(filters.to , 'yyyy-MM-dd') : undefined,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => {
            const base64String = response.data;

            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'protocollo.xls');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public GetEntryProtocolById(id: string): Promise<EntryProtocolDTO> {
        return axios.get("/api/entry-protocol/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    // ENTRY PROTOCOL FILE
    public RemoveFileFromProtocol(protocolId: string): Promise<void> {
        return axios.delete("/api/entry-protocol/remove-file/" + protocolId,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UploadAttachmentFile(data: FormData, id: string): Promise<void> {
        return axios.put("/api/entry-protocol/attachment-file/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    // ENTRY PROTOCOL OBJECT
    public CreateEntryProtocolObject(data: EntryProtocolObjectDTO): Promise<void> {
        return axios.post("/api/entry-protocol-object",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateEntryProtocolObject(data: EntryProtocolObjectDTO, id: string): Promise<void> {
        return axios.put("/api/entry-protocol-object/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteEntryProtocolObject(id: string): Promise<void> {
        return axios.delete("/api/entry-protocol-object/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllEntryProtocolObjects(): Promise<EntryProtocolObjectDTO[]> {
        return axios.get("/api/entry-protocol-object", {
            params: {
                itemsPerPage: 0,
                page: 0
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }

    public GetEntryProtocolObjectById(id: string): Promise<EntryProtocolObjectDTO> {
        return axios.get("/api/entry-protocol-object/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    // ENTRY PROTOCOL ATTACHMENT
    public CreateEntryProtocolAttachment(data: EntryProtocolAttachmentDTO): Promise<void> {
        return axios.post("/api/entry-protocol-attachment",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateEntryProtocolAttachment(data: EntryProtocolAttachmentDTO, id: string): Promise<void> {
        return axios.put("/api/entry-protocol-attachment/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteEntryProtocolAttachment(id: string): Promise<void> {
        return axios.delete("/api/entry-protocol-attachment/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllEntryProtocolAttachments(filters: GetAllEntryProtocolAttachmentsFiltersDTO): Promise<GetAllEntryProtocolAttachmentsDTO> {
        return axios.get("/api/entry-protocol-attachment", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                sort: filters.sort,
                order: filters.order,
                page: filters.page ? filters.page : 0,
                bio: filters.bio,
                deliberationGG26: filters.deliberationGG26,
                deliberationM24: filters.deliberationM24,
                deliberationSQNPI: filters.deliberationSQNPI,
                generics: filters.generics,
                globalGap: filters.globalGap,
                sqnpi: filters.sqnpi
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetEntryProtocolAttachmentById(id: string): Promise<EntryProtocolAttachmentDTO> {
        return axios.get("/api/entry-protocol-attachment/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

}