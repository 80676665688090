import { useEffect, useState } from 'react'
import { TrashIcon } from '../../../../ui/icons/trash'
import Button from '../../../../ui/molecules/button'
import { SelectCustom } from '../../../../ui/molecules/select'
import '../styles.scss'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { GetAllEntryProtocolAttachments, resetGetAllEntryProtocolAttachmentsStatus } from '../../../protocol/entry/slice'
import { OptionType } from '../../../../utils'
import { addUpdateRIDDefaultAttachmentId, addUpdateRIDDefaultDocumentNote, setUpdateRIDAttachmentIdByIndex, setUpdateRIDAttachmentIds, setUpdateRIDDocumentNoteByIndex, setUpdateRIDDocumentNotes } from '../slice'
import { CompilationStateDTO } from '../dataService/dto'
import TextArea from '../../../../ui/molecules/textArea'

export function RightEditRID() {
    const dispatch = useAppDispatch()
    const ridState = useAppSelector(state => state.rid)
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const [attachments, setAttachments] = useState<OptionType[]>([])

    const NoAvailableAttachments = () => {
        return attachments.filter(att => ridState.ridUpdateRequest.attachmentIds.includes(att.value))
    }

    const onCompilationState = (state: CompilationStateDTO | undefined) => {
        return state === CompilationStateDTO.COMPLETATA ? 'COMPLETATA' : 'IN CORSO'
    }

    useEffect(() => {
        dispatch(GetAllEntryProtocolAttachments({page: 0, itemsPerPage: 0})) 
    }, [])

    useEffect(() => {
        if(entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const data = entryProtocolState.getAllEntryProtocolAttachmentsResponse.data.map(att => {
                return {
                    value: att.id,
                    label: `${att.description} [${att.coding}]`
                } as OptionType
            })
            setAttachments(data)
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    
    return (
        <div>
            <div className='pt-5 pb-3 text-left' style={{padding: '24px 24px 12px 24px'}}>
                <span className='rid-right-title'>Stato richiesta integrazione documenti</span>
            </div>

            <div className='pt-3 pb-3' style={{backgroundColor: '#fff', display: 'flex', alignItems: 'center', border: '1px solid #D9E6E8'}}>
                <div style={{flex: '0.5', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className='tit-1-status'>Compilazione</div>
                    <div className='tit-2-status' style={{color: ridState.getRidByIdResponse?.compilationState !== 'IN_CORSO' ? '#26AB45' : '#FF941A'}}>
                        {onCompilationState(ridState.getRidByIdResponse?.compilationState as CompilationStateDTO)}
                    </div>
                </div>
                <div style={{flex: '0.5',  display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className='tit-1-status'>Ricevuta</div>
                    <div className='tit-2-status' style={{color: ridState.getRidByIdResponse?.requestState === 'APERTA' ? '#FF941A' : '#26AB45'}}>
                        {ridState.getRidByIdResponse?.requestState}
                    </div>
                </div>
            </div>

            <div className='text-left' style={{padding: '24px 24px 12px 24px'}}>
                <span className='rid-right-title'>Documenti richiesti</span>
            </div>

            {
                ridState.ridUpdateRequest.attachmentIds &&
                ridState.ridUpdateRequest.attachmentIds.map((att, index) => (
                    <div style={{padding: '0px 24px 12px 24px'}}>
                        <div className='mb-3' style={{display: 'flex', gap: '5px'}}>
                            <SelectCustom
                                disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                value={att}
                                options={attachments} 
                                disabledOptions={NoAvailableAttachments()}
                                placeholder=''
                                onChange={(value) => dispatch(setUpdateRIDAttachmentIdByIndex({data: value, index: index}))}
                                />
                            <button onClick={() => {
                                if(ridState.getRidByIdResponse?.compilationState !== CompilationStateDTO.COMPLETATA)
                                    dispatch(setUpdateRIDAttachmentIds(ridState.ridUpdateRequest.attachmentIds.filter(attId => attId !== att)))
                            }}>
                                <TrashIcon color='grey' size={20} />
                            </button>
                        </div>
                    </div>
                ))
            }

            <div style={{display: 'flex', justifyContent: 'flex-end',padding: '0px 24px 12px 24px'}}>
                <Button
                    disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                    iconPosition='off'
                    variant='outline'
                    color='dimgrey'
                    label='Aggiungi documento'
                    size='md'
                    onClick={() => dispatch(addUpdateRIDDefaultAttachmentId())}
                    />
            </div>

            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-right-title'>Documenti liberi</span>
            </div>

            {
                ridState.ridUpdateRequest.documentNotes &&
                ridState.ridUpdateRequest.documentNotes.map((note, index) => (
                    <div style={{ padding: '0px 24px 12px 24px' }}>
                        <div className='mb-3' style={{ display: 'flex', gap: '5px' }}>
                            <div style={{flex: '1'}}>
                                <TextArea
                                    value={ridState.ridUpdateRequest.documentNotes[index]}
                                    disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                    onChangeText={(text) => dispatch(setUpdateRIDDocumentNoteByIndex({ data: text, index: index }))}
                                />
                            </div>
                            <button onClick={() => {
                                if (ridState.getRidByIdResponse?.compilationState !== CompilationStateDTO.COMPLETATA)
                                    dispatch(setUpdateRIDDocumentNotes(ridState.ridUpdateRequest.documentNotes.filter(doc => doc !== note)))
                            }}>
                                <TrashIcon color='grey' size={20} />
                            </button>
                        </div>
                    </div>
                ))
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 24px 12px 24px' }}>
                <Button
                    disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                    iconPosition='off'
                    variant='outline'
                    color='dimgrey'
                    label='Aggiungi documento libero'
                    size='md'
                    onClick={() => dispatch(addUpdateRIDDefaultDocumentNote())}
                />
            </div>
        </div>
    )
}