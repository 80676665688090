import { GetAllRIDEntitiesDTO, GetAllRIDFiltersDTO, RIDCreationDTO, RIDEntityDTO, RIDUpdateDTO, RIDUpdateStateDTO } from "./dto";
import { RIDServiceImpl } from "./serviceImpl";

export interface RIDService {
    CreateRID(data: RIDCreationDTO): Promise<string>
    UpdateRID(data: RIDUpdateDTO, id: string): Promise<void>
    UpdateRIDState(data: RIDUpdateStateDTO, id: string): Promise<void>
    DeleteRID(id: string): Promise<void>
    GetAllRID(filters: GetAllRIDFiltersDTO): Promise<GetAllRIDEntitiesDTO>
    GetRIDById(id: string): Promise<RIDEntityDTO> 
}

export function NewRIDService(): RIDService {
    return new RIDServiceImpl()
}