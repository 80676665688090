import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import Button from "../../ui/molecules/button"
import Input from "../../ui/molecules/input/Input"
import { setTypeRegisterCreationCode, setTypeRegisterCreationName, TypeRegisterCreation } from "../gestioneClienti/registriAziendali/slice"

export function TypeRegisterRight() {
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)

    return (
        <div style={{ padding: '0px 24px 24px 24px' }}>
            <div className="text-left pb-3 pt-5">
                <span className="rb-title" >
                    Tipologia Registri
                </span>
            </div>

            <div className='pb-3'>
                <Input
                    label={'Nome'}
                    placeholder="Specifica nome..."
                    value={registerState.typeRegisterCreationRequest.name}
                    onChangeText={(text) => { dispatch(setTypeRegisterCreationName(text)) }}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Codice'}
                    placeholder="Specifica codice..."
                    value={registerState.typeRegisterCreationRequest.code}
                    onChangeText={(text) => { dispatch(setTypeRegisterCreationCode(text)) }}
                />
            </div>

            <div>
                <Button
                    variant='solid'
                    color='orange'
                    iconPosition='off'
                    size='md'
                    label='Aggiungi Tipologia Registro'
                    onClick={() => {
                        dispatch(TypeRegisterCreation(registerState.typeRegisterCreationRequest))
                    }
                    }
                />
            </div>
        </div>
    )
}