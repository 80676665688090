import { LogoBio } from "../../../../ui/icons/logoBio";
import { GlobalGapIcon } from "../../../../ui/icons/logoGlobalGap";
import { LogoSQNPI } from "../../../../ui/icons/logoSqnpi";
import { formatterDate } from "../../../../utils";
import { CertificationBoxProps } from "./certification.types";
import './styles.scss';

export const CertificationBox: React.FC<CertificationBoxProps> = ({
    ...props
}) => {
    return (
        <div className="pre-box">
            <div className="cert-box">
                <div style={{ display: 'flex', gap: '6px' }}>
                    <div style={{ flex: '0.8' }}>
                        <div className="subtitle">Schema certificazione</div>
                        {
                            props.customer.norm === 'NORMA_BIO' && (
                                <div className="title">Biologico</div>
                            )
                        }

                        {
                            props.customer.norm === 'PRODUZIONE_INTEGRATA' && (
                                <div className="title">Produzione Integrata</div>
                            )
                        }
                        {
                            props.customer.norm === 'DETERGENZA' && (
                                <div className="title">Detergenza</div>
                            )
                        }

                        {
                            props.customer.norm === 'GLOBAL_GAP' && (
                                <div className="title">GlobalG.A.P</div>
                            )
                        }

                        {
                            props.customer.norm === 'QUALITA_SICURA' && (
                                <div className="title">Qualità Sicura</div>
                            )
                        }

                        {
                            props.customer.norm === 'RESIDUO_ZERO' && (
                                <div className="title">Residuo Zero</div>
                            )
                        }

                    </div>
                    <div className="logo-container">
                        {
                            props.customer.norm === 'NORMA_BIO' && (
                                <LogoBio color="orange" size={100} />
                            )
                        }

                        {
                            props.customer.norm === 'PRODUZIONE_INTEGRATA' && (
                                <LogoSQNPI color="orange" size={100} />
                            )
                        }

                        {
                            props.customer.norm === 'GLOBAL_GAP' && (
                                <GlobalGapIcon />
                            )
                        }

                    </div>
                </div>

                <div className="cert-content pb-5">
                    <div className="cert-data">
                        <div className="content-bold">Codice</div>
                        <div className="content">{props.customer.clientCode}</div>
                    </div>

                    <div className="line"></div>
                    
                    <div className="cert-data">
                        <div className="content-bold">Attività</div>
                        <div className="content">IT-001</div>
                    </div>
                    <div className="line"></div>    
                    <div className="cert-data">
                        <div className="content-bold">Data Ingresso</div>
                        <div className="content">{formatterDate(props.customer.startingDate)}</div>
                    </div>
                    <div className="line"></div>
                    <div className="cert-data">
                        <div className="content-bold">Data Uscita</div>
                        <div className="content">{formatterDate(props.customer.endingDate)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
