import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../../utils"
import { AddedRegisterCreationDTO, AddedRegisterDTO, AddedRegisterUpdateDTO, BusinessRegisterCreationDTO, BusinessRegisterDTO, BusinessRegisterErrorsDTO, BusinessRegisterRequestReason, BusinessRegisterUpdateDTO, GetAllAddedRegistersDTO, GetAllAddedRegistersFiltersDTO, GetAllBusinessRegistersDTO, GetAllBusinessRegistersFiltersDTO, GetAllTypeRegistersDTO, GetAllTypeRegistersFiltersDTO, TypeRegisterDTO } from "./dataService/dto"
import { NewRegisterService } from "./dataService/service"

interface RegisterState {

    // BUSINESS REGISTER FLOW
    fromCreationToUpdate: boolean
    latestAvailableDate?: Date

    // REQUEST
    typeRegisterCreationRequest: TypeRegisterDTO
    typeRegisterUpdateRequest: TypeRegisterDTO
    typeRegisterIdToUpdate: string
    
    addedRegisterCreationRequest: AddedRegisterCreationDTO
    addedRegisterUpdateRequest: AddedRegisterUpdateDTO
    addedRegisterIdToUpdate: string

    businessRegisterCreationRequest: BusinessRegisterCreationDTO
    businessRegisterUpdateRequest: BusinessRegisterUpdateDTO
    businessRegisterIdToUpdate: string

    // ERRORS
    businessRegisterErrors: BusinessRegisterErrorsDTO

    // FILTERS
    getAllTypeRegistersFilters: GetAllTypeRegistersFiltersDTO
    getAllAddedRegistersFilters: GetAllAddedRegistersFiltersDTO
    getAllBusinessRegistersFilters: GetAllBusinessRegistersFiltersDTO

    // STATUS
    typeRegisterCreationStatus: PromiseStatuses
    typeRegisterUpdateStatus: PromiseStatuses
    typeRegisterDeleteStatus: PromiseStatuses
    getAllTypeRegistersStatus: PromiseStatuses
    getTypeRegisterByIdStatus: PromiseStatuses

    addedRegisterCreationStatus: PromiseStatuses
    addedRegisterUpdateStatus: PromiseStatuses
    addedRegisterDeleteStatus: PromiseStatuses
    getAllAddedRegistersStatus: PromiseStatuses
    getAddedRegisterByIdStatus: PromiseStatuses

    businessRegisterValidationStatus: PromiseStatuses
    businessRegisterCreationStatus: PromiseStatuses
    businessRegisterUpdateStatus: PromiseStatuses
    businessRegisterDeleteStatus: PromiseStatuses
    getAllBusinessRegistersStatus: PromiseStatuses
    getBusinessRegisterByIdStatus: PromiseStatuses

    // RESPONSE
    getAllTypeRegistersResponse?: GetAllTypeRegistersDTO
    getTypeRegisterByIdResponse?: TypeRegisterDTO

    addedRegisterCreationResponse?: string
    getAllAddedRegistersResponse?: GetAllAddedRegistersDTO
    getAddedRegisterByIdResponse?: AddedRegisterDTO

    businessRegisterCreationResponse?: string
    getAllBusinessRegistersResponse?: GetAllBusinessRegistersDTO
    getBusinessRegisterByIdResponse?: BusinessRegisterDTO
}


const initialState: RegisterState = {
    fromCreationToUpdate: false,

    // REQUEST
    typeRegisterCreationRequest: {
        name: '',
        code: ''
    },
    typeRegisterUpdateRequest: {
        name: '',
        code: ''
    },
    typeRegisterIdToUpdate: '',
    
    addedRegisterCreationRequest: {
        typeRegisterId: '',
        pageNumber: 10,
        businessRegisterId: '',
        officerId: '',
        userId: ''
    },
    addedRegisterUpdateRequest: {},
    addedRegisterIdToUpdate: '',

    businessRegisterCreationRequest: {
        officerId: '',
        userId: ''
    },
    businessRegisterUpdateRequest: {},
    businessRegisterIdToUpdate: '',

    // ERRORS
    businessRegisterErrors: {
        releaseMutualExclusion: false,
        requestProtocolId: false,
        requestReason: false,
        requestType: false,
        notification: false
    },

    // FILTERS
    getAllTypeRegistersFilters: {
        itemsPerPage: 0,
        page: 0
    },
    getAllAddedRegistersFilters: {
        itemsPerPage: 0,
        page: 0
    },
    getAllBusinessRegistersFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    typeRegisterCreationStatus: 'idle',
    typeRegisterUpdateStatus: 'idle',
    typeRegisterDeleteStatus: 'idle',
    getAllTypeRegistersStatus: 'idle',
    getTypeRegisterByIdStatus: 'idle',

    addedRegisterCreationStatus: 'idle',
    addedRegisterUpdateStatus: 'idle',
    addedRegisterDeleteStatus: 'idle',
    getAllAddedRegistersStatus: 'idle',
    getAddedRegisterByIdStatus: 'idle',

    businessRegisterValidationStatus: 'idle',
    businessRegisterCreationStatus: 'idle',
    businessRegisterDeleteStatus: 'idle',
    businessRegisterUpdateStatus: 'idle',
    getAllBusinessRegistersStatus: 'idle',
    getBusinessRegisterByIdStatus: 'idle'
}

export const TypeRegisterCreation = createAsyncThunk(
    'register/typeRegister/Creation',
    async (body: TypeRegisterDTO, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.CreateTypeRegister(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const TypeRegisterUpdate = createAsyncThunk(
    'register/typeRegister/Update',
    async (request: {body: TypeRegisterDTO, id: string}, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.UpdateTypeRegister(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const TypeRegisterDelete = createAsyncThunk(
    'register/typeRegister/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.DeleteTypeRegister(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllTypeRegisters = createAsyncThunk(
    'register/typeRegister/getAll',
    async (filters: GetAllTypeRegistersFiltersDTO, thunkApi): Promise<GetAllTypeRegistersDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetAllTypeRegisters(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetTypeRegisterById = createAsyncThunk(
    'register/typeRegister/getById',
    async (id: string, thunkApi): Promise<TypeRegisterDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetTypeRegisterById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AddedRegisterCreation = createAsyncThunk(
    'register/addedRegister/Creation',
    async (body: AddedRegisterCreationDTO, thunkApi): Promise<string> => {
        const RegisterService = NewRegisterService()

        return RegisterService.CreateAddedRegister(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AddedRegisterUpdate = createAsyncThunk(
    'register/addedRegister/Update',
    async (request: {body: AddedRegisterUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.UpdateAddedRegister(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AddedRegisterDelete = createAsyncThunk(
    'register/addedRegister/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.DeleteAddedRegister(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllAddedRegisters = createAsyncThunk(
    'register/addedRegister/getAll',
    async (filters: GetAllAddedRegistersFiltersDTO, thunkApi): Promise<GetAllAddedRegistersDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetAllAddedRegisters(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAddedRegisterById = createAsyncThunk(
    'register/addedRegister/getById',
    async (id: string, thunkApi): Promise<AddedRegisterDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetAddedRegisterById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const BusinessRegisterValidation = createAsyncThunk(
    'register/businessRegister/Validation',
    async (request: BusinessRegisterCreationDTO | BusinessRegisterUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetBusinessRegisterErrors())

        if(request.requestReason === undefined) {
            thunkApi.dispatch(setValidateBusinessRegisterRequestReason(true))
            isValid = false
        }

        if(request.requestType === undefined) {
            thunkApi.dispatch(setValidateBusinessRegisterRequestType(true))
            isValid = false
        }

        // NOTIFICATION VALIDATION TO BE IMPLEMENTED
        
        if(request.requestReason !== BusinessRegisterRequestReason.EMISSIONE_A_SEGUITO_NOTIFICA_VARIAZIONE && 
            request.requestReason !== BusinessRegisterRequestReason.EMISSIONE_PER_CLIENTE_IN_FASE_DI_AVVIO &&
            (request.requestProtocolId === undefined || request.requestProtocolId === null)) {
            thunkApi.dispatch(setValidateBusinessRegisterRequestProtocolId(true))
            isValid = false
        }

        if(request.releaseDate === undefined && request.releaseProtocolId === undefined) {
            thunkApi.dispatch(setValidateBusinessRegisterReleaseMutualExclusion(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)


export const BusinessRegisterCreation = createAsyncThunk(
    'register/businessRegister/Creation',
    async (body: BusinessRegisterCreationDTO, thunkApi): Promise<string> => {
        const RegisterService = NewRegisterService()

        return RegisterService.CreateBusinessRegister(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const BusinessRegisterUpdate = createAsyncThunk(
    'register/businessRegister/Update',
    async (request: {body: BusinessRegisterUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.UpdateBusinessRegister(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const BusinessRegisterDelete = createAsyncThunk(
    'register/businessRegister/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const RegisterService = NewRegisterService()

        return RegisterService.DeleteBusinessRegister(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllBusinessRegisters = createAsyncThunk(
    'register/businessRegister/getAll',
    async (filters: GetAllBusinessRegistersFiltersDTO, thunkApi): Promise<GetAllBusinessRegistersDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetAllBusinessRegisters(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetBusinessRegisterById = createAsyncThunk(
    'register/businessRegister/getById',
    async (id: string, thunkApi): Promise<BusinessRegisterDTO> => {
        const RegisterService = NewRegisterService()

        return RegisterService.GetBusinessRegisterById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const RegisterSlice = createSlice({
    name: 'register/slice',
    initialState,
    reducers: {
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },
        setLatestAvailableDate: (state, action) => {
            state.latestAvailableDate = action.payload
        },

        // REQUEST [TYPE REGISTER]
        setTypeRegisterCreationName: (state, action) => {
            state.typeRegisterCreationRequest.name = action.payload
        },
        setTypeRegisterCreationCode: (state, action) => {
            state.typeRegisterCreationRequest.code = action.payload
        },
        resetTypeRegisterCreationRequest: (state) => {
            state.typeRegisterCreationRequest = {
                name: '',
                code: ''
            }
        },

        setTypeRegisterUpdateName: (state, action) => {
            state.typeRegisterUpdateRequest.name = action.payload
        },
        setTypeRegisterUpdateCode: (state, action) => {
            state.typeRegisterUpdateRequest.code = action.payload
        },
        setTypeRegisterIdToUpdate: (state, action) => {
            state.typeRegisterIdToUpdate = action.payload
        },
        resetTypeRegisterUpdateRequest: (state) => {
            state.typeRegisterUpdateRequest = {
                name: '',
                code: ''
            }
        },

        // REQUEST [ADDED REGISTER]
        setAddedRegisterCreationTypeRegisterId: (state, action) => {
            state.addedRegisterCreationRequest.typeRegisterId = action.payload
        },
        setAddedRegisterCreationBusinessRegisterId: (state, action) => {
            state.addedRegisterCreationRequest.businessRegisterId = action.payload
        },
        setAddedRegisterCreationPageNumber: (state, action) => {
            state.addedRegisterCreationRequest.pageNumber = action.payload
        },
        setAddedRegisterCreationOfficerId: (state, action) => {
            state.addedRegisterCreationRequest.officerId = action.payload
        },
        setAddedRegisterCreationUserId: (state, action) => {
            state.addedRegisterCreationRequest.userId = action.payload
        },
        resetAddedRegisterCreationRequest: (state) => {
            state.addedRegisterCreationRequest = {
                pageNumber: 10,
                typeRegisterId: '',
                businessRegisterId: '',
                officerId: '',
                userId: ''
            }
        },

        setAddedRegisterUpdateTypeRegisterId: (state, action) => {
            state.addedRegisterUpdateRequest.typeRegisterId = action.payload
        },
        setAddedRegisterUpdatePageNumber: (state, action) => {
            state.addedRegisterUpdateRequest.pageNumber = action.payload
        },
        setAddedRegisterIdToUpdate: (state, action) => {
            state.addedRegisterIdToUpdate = action.payload
        },
        resetAddedRegisterUpdateRequest: (state) => {
            state.addedRegisterUpdateRequest = {}
        },

        // REQUEST [BUSINESS REGISTER]
        setBusinessRegisterCreationRequestProtocolId: (state, action) => {
            state.businessRegisterCreationRequest.requestProtocolId = action.payload
        },
        setBusinessRegisterCreationRequestType: (state, action) => {
            state.businessRegisterCreationRequest.requestType = action.payload
        },
        setBusinessRegisterCreationRequestReason: (state, action) => {
            state.businessRegisterCreationRequest.requestReason = action.payload
        },
        setBusinessRegisterCreationReleaseRegister: (state, action) => {
            state.businessRegisterCreationRequest.releaseRegister = action.payload
        },
        setBusinessRegisterCreationReleaseProtocolId: (state, action) => {
            state.businessRegisterCreationRequest.releaseProtocolId = action.payload
        },
        setBusinessRegisterCreationReleaseDate: (state, action) => {
            state.businessRegisterCreationRequest.releaseDate = action.payload
        },
        setBusinessRegisterCreationNote: (state, action) => {
            state.businessRegisterCreationRequest.note = action.payload
        },
        setBusinessRegisterCreationOfficerId: (state, action) => {
            state.businessRegisterCreationRequest.officerId = action.payload
        },
        setBusinessRegisterCreationUserId: (state, action) => {
            state.businessRegisterCreationRequest.userId = action.payload
        },
        resetBusinessRegisterCreationRequest: (state) => {
            state.businessRegisterCreationRequest = {
                officerId: '',
                userId: ''
            }
        },

        setBusinessRegisterUpdateRequestProtocolId: (state, action) => {
            state.businessRegisterUpdateRequest.requestProtocolId = action.payload
        },
        setBusinessRegisterUpdateRequestType: (state, action) => {
            state.businessRegisterUpdateRequest.requestType = action.payload
        },
        setBusinessRegisterUpdateRequestReason: (state, action) => {
            state.businessRegisterUpdateRequest.requestReason = action.payload
        },
        setBusinessRegisterUpdateReleaseRegister: (state, action) => {
            state.businessRegisterUpdateRequest.releaseRegister = action.payload
        },
        setBusinessRegisterUpdateReleaseProtocolId: (state, action) => {
            state.businessRegisterUpdateRequest.releaseProtocolId = action.payload
        },
        setBusinessRegisterUpdateReleaseDate: (state, action) => {
            state.businessRegisterUpdateRequest.releaseDate = action.payload
        },
        setBusinessRegisterUpdateNote: (state, action) => {
            state.businessRegisterUpdateRequest.note = action.payload
        },
        setBusinessRegisterIdToUpdate: (state, action) => {
            state.businessRegisterIdToUpdate = action.payload
        },
        resetBusinessRegisterUpdateRequest: (state) => {
            state.businessRegisterUpdateRequest = {}
        },

        // ERRORS
        setValidateBusinessRegisterRequestReason: (state, action) => {
            state.businessRegisterErrors.requestReason = action.payload
        },
        setValidateBusinessRegisterRequestType: (state, action) => {
            state.businessRegisterErrors.requestType = action.payload
        },
        setValidateBusinessRegisterNotification: (state, action) => {
            state.businessRegisterErrors.notification = action.payload
        },
        setValidateBusinessRegisterRequestProtocolId: (state, action) => {
            state.businessRegisterErrors.requestProtocolId = action.payload
        },
        setValidateBusinessRegisterReleaseMutualExclusion: (state, action) => {
            state.businessRegisterErrors.releaseMutualExclusion = action.payload
        },
        resetBusinessRegisterErrors: (state) => {
            state.businessRegisterErrors = {
                releaseMutualExclusion: false,
                requestProtocolId: false,
                requestReason: false,
                requestType: false,
                notification: false
            }
        },

        // FILTERS
        setGetAllTypeRegistersFiltersPage: (state, action) => {
            state.getAllTypeRegistersFilters.page = action.payload
        },
        setGetAllTypeRegistersFiltersItemsPerPage: (state, action) => {
            state.getAllTypeRegistersFilters.itemsPerPage = action.payload
        },
        setGetAllTypeRegistersFiltersOrder: (state, action) => {
            state.getAllTypeRegistersFilters.order = action.payload
        },
        setGetAllTypeRegistersFiltersSort: (state, action) => {
            state.getAllTypeRegistersFilters.sort = action.payload
        },
        resetGetAllTypeRegistersFilters: (state) => {
            state.getAllTypeRegistersFilters = {
                page: 0,
                itemsPerPage: 0
            }
        },

        setGetAllAddedRegistersFiltersPage: (state, action) => {
            state.getAllAddedRegistersFilters.page = action.payload
        },
        setGetAllAddedRegistersFiltersItemsPerPage: (state, action) => {
            state.getAllAddedRegistersFilters.itemsPerPage = action.payload
        },
        setGetAllAddedRegistersFiltersOrder: (state, action) => {
            state.getAllAddedRegistersFilters.order = action.payload
        },
        setGetAllAddedRegistersFiltersSort: (state, action) => {
            state.getAllAddedRegistersFilters.sort = action.payload
        },
        setGetAllAddedRegistersFiltersBusinessRegisterId: (state, action) => {
            state.getAllAddedRegistersFilters.businessRegisterId = action.payload
        },
        resetGetAllAddedRegistersFilters: (state) => {
            state.getAllAddedRegistersFilters = {
                page: 0,
                itemsPerPage: 0
            }
        },

        setGetAllBusinessRegistersFiltersPage: (state, action) => {
            state.getAllBusinessRegistersFilters.page = action.payload
        },
        setGetAllBusinessRegistersFiltersItemsPerPage: (state, action) => {
            state.getAllBusinessRegistersFilters.itemsPerPage = action.payload
        },
        setGetAllBusinessRegistersFiltersOrder: (state, action) => {
            state.getAllBusinessRegistersFilters.order = action.payload
        },
        setGetAllBusinessRegistersFiltersSort: (state, action) => {
            state.getAllBusinessRegistersFilters.sort = action.payload
        },
        setGetAllBusinessRegistersFiltersCustomerId: (state, action) => {
            state.getAllBusinessRegistersFilters.customerId = action.payload
        },
        setGetAllBusinessRegistersFiltersHeadQuarter: (state, action) => {
            state.getAllBusinessRegistersFilters.headQuarter = action.payload
        },
        setGetAllBusinessRegistersFiltersYear: (state, action) => {
            state.getAllBusinessRegistersFilters.year = action.payload
        },
        resetGetAllBusinessRegistersFilters: (state) => {
            state.getAllBusinessRegistersFilters = {
                page: 0,
                itemsPerPage: 0
            }
        },

        // STATUS
        resetTypeRegisterCreationStatus: (state) => {
            state.typeRegisterCreationStatus = 'idle'
        },
        resetTypeRegisterUpdateStatus: (state) => {
            state.typeRegisterUpdateStatus = 'idle'
        },
        resetTypeRegisterDeleteStatus: (state) => {
            state.typeRegisterDeleteStatus = 'idle'
        },
        resetGetAllTypeRegistersStatus: (state) => {
            state.getAllTypeRegistersStatus = 'idle'
        },
        resetGetTypeRegisterBydIdStatus: (state) => {
            state.getTypeRegisterByIdStatus = 'idle'
        },

        resetAddedRegisterCreationStatus: (state) => {
            state.addedRegisterCreationStatus = 'idle'
        },
        resetAddedRegisterUpdateStatus: (state) => {
            state.addedRegisterUpdateStatus = 'idle'
        },
        resetAddedRegisterDeleteStatus: (state) => {
            state.addedRegisterDeleteStatus = 'idle'
        },
        resetGetAllAddedRegisterStatus: (state) => {
            state.getAllAddedRegistersStatus = 'idle'
        },
        resetGetAddedRegisterById: (state) => {
            state.getAddedRegisterByIdStatus = 'idle'
        },

        resetBusinessRegisterValidationStatus: (state) => {
            state.businessRegisterValidationStatus = 'idle'
        },  
        resetBusinessRegisterCreationStatus: (state) => {
            state.businessRegisterCreationStatus = 'idle'
        },
        resetBusinessRegisterUpdateStatus: (state) => {
            state.businessRegisterUpdateStatus = 'idle'
        },
        resetBusinessRegisterDeleteStatus: (state) => {
            state.businessRegisterDeleteStatus = 'idle'
        },
        resetGetAllBusinessRegistersStatus: (state) => {
            state.getAllBusinessRegistersStatus = 'idle'
        },
        resetGetBusinessRegisterByIdStatus: (state) => {
            state.getBusinessRegisterByIdStatus = 'idle'
        },

        // RESPONSE
        resetGetAllAddedRegistersResponse: (state) => {
            state.getAllAddedRegistersResponse = undefined
        }
    },
    extraReducers(builder) {
        builder

            // [TYPE REGISTER]
            // CREATION
            .addCase(TypeRegisterCreation.pending, (state) => {
                state.typeRegisterCreationStatus = 'loading'
            })
            .addCase(TypeRegisterCreation.fulfilled, (state) => {
                state.typeRegisterCreationStatus = 'successfully'
            })
            .addCase(TypeRegisterCreation.rejected, (state) => {
                state.typeRegisterCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(TypeRegisterUpdate.pending, (state) => {
                state.typeRegisterUpdateStatus = 'loading'
            })
            .addCase(TypeRegisterUpdate.fulfilled, (state) => {
                state.typeRegisterUpdateStatus = 'successfully'
            })
            .addCase(TypeRegisterUpdate.rejected, (state) => {
                state.typeRegisterUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(TypeRegisterDelete.pending, (state) => {
                state.typeRegisterDeleteStatus = 'loading'
            })
            .addCase(TypeRegisterDelete.fulfilled, (state) => {
                state.typeRegisterDeleteStatus = 'successfully'
            })
            .addCase(TypeRegisterDelete.rejected, (state) => {
                state.typeRegisterDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllTypeRegisters.pending, (state) => {
                state.getAllTypeRegistersStatus = 'loading'
            })
            .addCase(GetAllTypeRegisters.fulfilled, (state, action) => {
                state.getAllTypeRegistersStatus = 'successfully'
                state.getAllTypeRegistersResponse = action.payload
            })
            .addCase(GetAllTypeRegisters.rejected, (state) => {
                state.getAllTypeRegistersStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetTypeRegisterById.pending, (state) => {
                state.getTypeRegisterByIdStatus = 'loading'
            })
            .addCase(GetTypeRegisterById.fulfilled, (state, action) => {
                state.getTypeRegisterByIdStatus = 'successfully'
                state.getTypeRegisterByIdResponse = action.payload
            })
            .addCase(GetTypeRegisterById.rejected, (state) => {
                state.getTypeRegisterByIdStatus = 'failed'
            })

            // [ADDED REGISTER]
            // CREATION
            .addCase(AddedRegisterCreation.pending, (state) => {
                state.addedRegisterCreationStatus = 'loading'
            })
            .addCase(AddedRegisterCreation.fulfilled, (state, action) => {
                state.addedRegisterCreationStatus = 'successfully'
                state.addedRegisterCreationResponse = action.payload
            })
            .addCase(AddedRegisterCreation.rejected, (state) => {
                state.addedRegisterCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(AddedRegisterUpdate.pending, (state) => {
                state.addedRegisterUpdateStatus = 'loading'
            })
            .addCase(AddedRegisterUpdate.fulfilled, (state) => {
                state.addedRegisterUpdateStatus = 'successfully'
            })
            .addCase(AddedRegisterUpdate.rejected, (state) => {
                state.addedRegisterUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(AddedRegisterDelete.pending, (state) => {
                state.addedRegisterDeleteStatus = 'loading'
            })
            .addCase(AddedRegisterDelete.fulfilled, (state) => {
                state.addedRegisterDeleteStatus = 'successfully'
            })
            .addCase(AddedRegisterDelete.rejected, (state) => {
                state.addedRegisterDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllAddedRegisters.pending, (state) => {
                state.getAllAddedRegistersStatus = 'loading'
            })
            .addCase(GetAllAddedRegisters.fulfilled, (state, action) => {
                state.getAllAddedRegistersStatus = 'successfully'
                state.getAllAddedRegistersResponse = action.payload
            })
            .addCase(GetAllAddedRegisters.rejected, (state) => {
                state.getAllAddedRegistersStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetAddedRegisterById.pending, (state) => {
                state.getAddedRegisterByIdStatus = 'loading'
            })
            .addCase(GetAddedRegisterById.fulfilled, (state, action) => {
                state.getAddedRegisterByIdStatus = 'successfully'
                state.getAddedRegisterByIdResponse = action.payload
            })
            .addCase(GetAddedRegisterById.rejected, (state) => {
                state.getAddedRegisterByIdStatus = 'failed'
            })

            // [BUSINESS REGISTER]
            // VALIDATION
            .addCase(BusinessRegisterValidation.pending, (state) => {
                state.businessRegisterValidationStatus = 'loading'
            })
            .addCase(BusinessRegisterValidation.fulfilled, (state, action) => {
                state.businessRegisterValidationStatus = 'successfully'
            })
            .addCase(BusinessRegisterValidation.rejected, (state) => {
                state.businessRegisterValidationStatus = 'failed'
            })

            // CREATION
            .addCase(BusinessRegisterCreation.pending, (state) => {
                state.businessRegisterCreationStatus = 'loading'
            })
            .addCase(BusinessRegisterCreation.fulfilled, (state, action) => {
                state.businessRegisterCreationStatus = 'successfully'
                state.businessRegisterCreationResponse = action.payload
            })
            .addCase(BusinessRegisterCreation.rejected, (state) => {
                state.businessRegisterCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(BusinessRegisterUpdate.pending, (state) => {
                state.businessRegisterUpdateStatus = 'loading'
            })
            .addCase(BusinessRegisterUpdate.fulfilled, (state) => {
                state.businessRegisterUpdateStatus = 'successfully'
            })
            .addCase(BusinessRegisterUpdate.rejected, (state) => {
                state.businessRegisterUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(BusinessRegisterDelete.pending, (state) => {
                state.businessRegisterDeleteStatus = 'loading'
            })
            .addCase(BusinessRegisterDelete.fulfilled, (state) => {
                state.businessRegisterDeleteStatus = 'successfully'
            })
            .addCase(BusinessRegisterDelete.rejected, (state) => {
                state.businessRegisterDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllBusinessRegisters.pending, (state) => {
                state.getAllBusinessRegistersStatus = 'loading'
            })
            .addCase(GetAllBusinessRegisters.fulfilled, (state, action) => {
                state.getAllBusinessRegistersStatus = 'successfully'
                state.getAllBusinessRegistersResponse = action.payload
            })
            .addCase(GetAllBusinessRegisters.rejected, (state) => {
                state.getAllBusinessRegistersStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetBusinessRegisterById.pending, (state) => {
                state.getBusinessRegisterByIdStatus = 'loading'
            })
            .addCase(GetBusinessRegisterById.fulfilled, (state, action) => {
                state.getBusinessRegisterByIdStatus = 'successfully'
                state.getBusinessRegisterByIdResponse = action.payload
            })
            .addCase(GetBusinessRegisterById.rejected, (state) => {
                state.getBusinessRegisterByIdStatus = 'failed'
            })
    },
})

export const {
    setFromCreationToUpdate,
    setLatestAvailableDate,

    // REQUEST
    setAddedRegisterCreationOfficerId,
    setAddedRegisterCreationPageNumber,
    setAddedRegisterCreationTypeRegisterId,
    setAddedRegisterCreationUserId,
    setAddedRegisterCreationBusinessRegisterId,
    setAddedRegisterIdToUpdate,
    setAddedRegisterUpdatePageNumber,
    setAddedRegisterUpdateTypeRegisterId,
    setTypeRegisterCreationCode,
    setTypeRegisterCreationName,
    setTypeRegisterIdToUpdate,
    setTypeRegisterUpdateCode,
    setTypeRegisterUpdateName,
    resetAddedRegisterCreationRequest,
    resetAddedRegisterUpdateRequest,
    resetTypeRegisterUpdateRequest,
    resetTypeRegisterCreationRequest,
    setBusinessRegisterCreationNote,
    setBusinessRegisterCreationOfficerId,
    setBusinessRegisterCreationReleaseDate,
    setBusinessRegisterCreationReleaseProtocolId,
    setBusinessRegisterCreationReleaseRegister,
    setBusinessRegisterCreationRequestProtocolId,
    setBusinessRegisterCreationRequestReason,
    setBusinessRegisterCreationRequestType,
    setBusinessRegisterCreationUserId,
    setBusinessRegisterUpdateNote,
    setBusinessRegisterUpdateReleaseDate,
    setBusinessRegisterUpdateReleaseProtocolId,
    setBusinessRegisterUpdateReleaseRegister,
    setBusinessRegisterUpdateRequestProtocolId,
    setBusinessRegisterUpdateRequestReason,
    setBusinessRegisterUpdateRequestType,
    setBusinessRegisterIdToUpdate,
    resetBusinessRegisterCreationRequest,
    resetBusinessRegisterUpdateRequest,

    // ERRORS
    setValidateBusinessRegisterNotification,
    setValidateBusinessRegisterReleaseMutualExclusion,
    setValidateBusinessRegisterRequestProtocolId,
    setValidateBusinessRegisterRequestReason,
    setValidateBusinessRegisterRequestType,
    resetBusinessRegisterErrors,
    
    // FILTERS
    setGetAllAddedRegistersFiltersItemsPerPage,
    setGetAllAddedRegistersFiltersOrder,
    setGetAllAddedRegistersFiltersPage,
    setGetAllAddedRegistersFiltersBusinessRegisterId,
    setGetAllAddedRegistersFiltersSort,
    setGetAllTypeRegistersFiltersItemsPerPage,
    setGetAllTypeRegistersFiltersOrder,
    setGetAllTypeRegistersFiltersPage,
    setGetAllTypeRegistersFiltersSort,
    resetGetAllAddedRegistersFilters,
    resetGetAllTypeRegistersFilters,
    setGetAllBusinessRegistersFiltersCustomerId,
    setGetAllBusinessRegistersFiltersHeadQuarter,
    setGetAllBusinessRegistersFiltersItemsPerPage,
    setGetAllBusinessRegistersFiltersOrder,
    setGetAllBusinessRegistersFiltersPage,
    setGetAllBusinessRegistersFiltersSort,
    setGetAllBusinessRegistersFiltersYear,
    resetGetAllBusinessRegistersFilters,

    // STATUS
    resetAddedRegisterCreationStatus,
    resetAddedRegisterDeleteStatus,
    resetAddedRegisterUpdateStatus,
    resetGetAddedRegisterById,
    resetGetAllAddedRegisterStatus,
    resetGetAllTypeRegistersStatus,
    resetGetTypeRegisterBydIdStatus,
    resetTypeRegisterCreationStatus,
    resetTypeRegisterDeleteStatus,
    resetTypeRegisterUpdateStatus,
    resetBusinessRegisterCreationStatus,
    resetBusinessRegisterDeleteStatus,
    resetBusinessRegisterUpdateStatus,
    resetGetAllBusinessRegistersStatus,
    resetGetBusinessRegisterByIdStatus,
    resetBusinessRegisterValidationStatus,
    
    // RESPONSE
    resetGetAllAddedRegistersResponse

} = RegisterSlice.actions

export default RegisterSlice.reducer