import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { keycloak } from './app/lib/keycloak';
import { persistor, store } from './app/lib/redux/store'
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';

keycloak.init({
  onLoad: 'login-required',
  redirectUri: window.location.href,
  pkceMethod: "S256"
})
  .then()
  .catch((error) => { })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

keycloak.onTokenExpired = () => {
  keycloak.updateToken(300)
    .catch(() => { keycloak.logout() });
}

keycloak.onAuthRefreshError = () => {
  keycloak.logout()
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
