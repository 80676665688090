import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { formatterDate, optionsFormatter, BusinessRegisterRequestMotivationOptions, BusinessRegisterTypologyRequestOptions } from "../../../../utils"
import { useEffect } from "react"
import { GetAllBusinessRegisters } from "../slice"

export function ReportRegistriAziendaliList() {
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)

    useEffect(() => {
        dispatch(GetAllBusinessRegisters(registerState.getAllBusinessRegistersFilters))
    }, [])

    return (
        <div>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Sede</th>
                        <th>Regione</th>
                        <th>Ragione Sociale</th>
                        <th>Cod. Cliente</th>
                        <th>Data Richiesta</th>
                        <th>Motivo Richiesta</th>
                        <th>Protocollo Richiesta</th>
                        <th>Tipo Richiesta</th>
                        <th>Data rilascio</th>
                        <th>Protocollo Rilascio</th>
                        <th>Numero</th>
                        <th>Tipo</th>
                        <th style={{ borderTopRightRadius: '8px' }}>Note</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registerState.getAllBusinessRegistersResponse?.data.map((regAz, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>{regAz.customerHeadQuarters}</td>
                                <td>{regAz.customerCountryRegion}</td>
                                <td>{regAz.customerCompanyName}</td>
                                <td>{regAz.customerClientCode}</td>
                                <td>{formatterDate(regAz.requestDate)}</td>
                                <td>{optionsFormatter(regAz.requestReason, BusinessRegisterRequestMotivationOptions)}</td>
                                <td>{regAz.requestProtocolInfo}</td>
                                <td>{optionsFormatter(regAz.requestType, BusinessRegisterTypologyRequestOptions)}</td>
                                <td>{formatterDate(regAz.releaseDate)}</td>
                                <td>{regAz.releaseProtocolInfo}</td>
                                <td>
                                    {regAz.addedRegistersInfo && regAz.addedRegistersInfo.map((reg, index) =>
                                        <p>{reg.progressive}</p>
                                    )}
                                </td>
                                <td>
                                    {regAz.addedRegistersInfo && regAz.addedRegistersInfo.map((reg, index) =>
                                        <p>{reg.name}</p>
                                    )}
                                </td>
                                <td>{regAz.note}</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}