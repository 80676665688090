/* TYPE REGISTER */

export interface TypeRegisterDTO {
    id?: string
    name: string
    code: string
}

export interface GetAllTypeRegistersFiltersDTO {
    page: number
    itemsPerPage: number
    order?: boolean
    sort?: string
}

export interface GetAllTypeRegistersDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: TypeRegisterDTO[]
}

/* ADDED REGISTER */

export interface AddedRegisterCreationDTO {
    pageNumber: number
    typeRegisterId: string
    businessRegisterId: string
    officerId: string
    userId: string
}

export interface AddedRegisterUpdateDTO {
    pageNumber?: number
    typeRegisterId?: string
}

export interface AddedRegisterDTO {
    id: string
    progressive: string
    pageNumber: number
    typeRegisterDTO: TypeRegisterDTO
    officerId: string
    userId: string
}

export interface GetAllAddedRegistersFiltersDTO {
    page: number
    itemsPerPage: number
    order?: boolean
    sort?: string
    businessRegisterId?: string
}

export interface GetAllAddedRegistersDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: AddedRegisterDTO[]
}

// BUSINESS REGISTER

export enum BusinessRegisterRequestType {
    CARTACEI = 'CARTACEI',
    INFORMATIZZATI = 'INFORMATIZZATI',
    ACCESSO_CODINFO_CLIENTI = 'ACCESSO_CODINFO_CLIENTI'
}

export enum BusinessRegisterRequestReason {
    EMISSIONE_PER_CLIENTE_IN_FASE_DI_AVVIO = 'EMISSIONE_PER_CLIENTE_IN_FASE_DI_AVVIO',
    EMISSIONE_A_SEGUITO_NOTIFICA_VARIAZIONE = 'EMISSIONE_A_SEGUITO_NOTIFICA_VARIAZIONE',
    RICHIESTA_PER_REGISTRI_ESAURITI = 'RICHIESTA_PER_REGISTRI_ESAURITI',
    RICHIESTA_PER_REGISTRI_SMARRITI = 'RICHIESTA_PER_REGISTRI_SMARRITI',
    RICHIESTA_REGISTRI_INFORMATIZZATI = 'RICHIESTA_REGISTRI_INFORMATIZZATI'
}

export enum BusinessRegisterReleaseRegister {
    PROTOCOLLO_RILASCIO = 'PROTOCOLLO_RILASCIO',
    CONSEGNATI_DAL_TECNICO_ISPETTORE = 'CONSEGNATI_DAL_TECNICO_ISPETTORE'
}

export interface BusinessRegisterCreationDTO {
    requestProtocolId?: string
    requestType?: BusinessRegisterRequestType
    requestReason?: BusinessRegisterRequestReason
    releaseRegister?: BusinessRegisterReleaseRegister,
    releaseProtocolId?: string
    releaseDate?: Date
    note?: string
    officerId: string
    userId: string
}

export interface BusinessRegisterErrorsDTO {
    requestReason: boolean
    requestType: boolean
    notification: boolean
    requestProtocolId: boolean
    releaseMutualExclusion: boolean
}

export interface BusinessRegisterUpdateDTO {
    requestProtocolId?: string
    requestType?: BusinessRegisterRequestType
    requestReason?: BusinessRegisterRequestReason
    releaseRegister?: BusinessRegisterReleaseRegister
    releaseProtocolId?: string
    releaseDate?: Date
    note?: string
}

export interface BusinessRegisterDTO {
    id: string
    requestDate: Date
    requestProtocolId: string
    requestProtocolInfo: string
    requestType: BusinessRegisterRequestType
    requestReason: BusinessRegisterRequestReason
    releaseRegister: BusinessRegisterReleaseRegister
    releaseProtocolId: string
    releaseProtocolInfo: string
    releaseDate: Date
    note: string
    officerId: string
    officerNameSurname: string
    headQuarter: string
    userId: string
    customerCompanyName: string
    customerCountryRegion: string
    customerClientCode: string
    customerActivity: string
    customerHeadQuarters: string
    addedRegisterIds: string[]
    addedRegistersInfo?: infoRegisters[]
}

export interface GetAllBusinessRegistersFiltersDTO {
    page: number
    itemsPerPage: number
    order?: boolean
    sort?: string
    customerId?: string
    headQuarter?: string
    year?: number
}

export interface GetAllBusinessRegistersDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: BusinessRegisterDTO[]
}

export interface infoRegisters {
   id: string
   name: string
   progressive: string
}

