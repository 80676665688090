import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import Button from "../../../ui/molecules/button"
import { ClientBanner } from "../../../ui/molecules/clientBanner"
import { Banner } from "../../../ui/organisms/banner"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { attivita_bio, normativa, optionsFormatter } from "../../../utils"
import { RIDList } from "./list"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { setRIDUserId } from "./slice"

export function RID() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    
    if(sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
                noPaddingLat
                headerLabel="Gestione R.I.D."
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuova R.I.D." color={"orange"}
                            onClick={() => {
                                navigate('/addRid')
                            }}
                        />

                    </div>
                }
            >
                <div style={{padding: '24px'}}>
                    <div className="pb-5">
                        <ClientBanner
                            region={sessionState.selected.customerRegion!}
                            client={sessionState.selected.customerName!}
                            code={sessionState.selected.customerCode!}
                            activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                            norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                        />
                    </div>

                    <div>
                        <RIDList />
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
            noPaddingLat
            headerLabel={"Gestione R.I.D."}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}