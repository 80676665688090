import { useNavigate } from "react-router-dom"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { ReportRegistriAziendaliList } from "./reportList"
import Button from "../../../../ui/molecules/button"
import { SelectCustom } from "../../../../ui/molecules/select"
import { sot, yearOptions } from "../../../../utils"
import { GetAllBusinessRegisters, resetGetAllBusinessRegistersFilters, setGetAllBusinessRegistersFiltersHeadQuarter, setGetAllBusinessRegistersFiltersYear } from "../slice"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"

export function ReportRegistriAziendali() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Clienti', 'Registri Aziendali']}
            noPaddingLat
            headerLabel="Report Registri Aziendali"
        >
            <div style={{ padding: '24px' }}>
                <div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
                        <div style={{ flex: '0.2' }} className="text-left">
                            <SelectCustom
                                key={registerState.getAllBusinessRegistersFilters.headQuarter || 'default'}
                                label="Sede"
                                placeholder={'Seleziona Sede'}
                                value={registerState.getAllBusinessRegistersFilters.headQuarter}
                                options={sot}
                                onChange={(value) => dispatch(setGetAllBusinessRegistersFiltersHeadQuarter(value))}
                            />
                        </div>
                        <div style={{ flex: '0.2' }} className="text-left">
                            <SelectCustom
                                key={registerState.getAllBusinessRegistersFilters.year || 'default'}
                                label="Anno"
                                placeholder={'Seleziona Anno'}
                                value={registerState.getAllBusinessRegistersFilters.year?.toString()}
                                options={yearOptions}
                                onChange={(value) => dispatch(setGetAllBusinessRegistersFiltersYear(value))}
                            />
                        </div>

                        <div style={{ alignSelf: 'flex-end', paddingBottom: '6px' }}>
                            <span>
                                <Button
                                    size={"lg"}
                                    iconPosition={"off"}
                                    variant={"link"}
                                    label="Pulisci"
                                    color={"gray"}
                                    onClick={() => dispatch(resetGetAllBusinessRegistersFilters())}
                                />
                            </span>
                        </div>

                        <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"outline"}
                                label="Cerca"
                                color={"blue"}
                                onClick={() => dispatch(GetAllBusinessRegisters(registerState.getAllBusinessRegistersFilters))}
                            />
                        </div>

                    </div>
                </div>
                <div className="mt-6">
                    <ReportRegistriAziendaliList />
                </div>
            </div>
        </Layout>
    )
}