import { useAppDispatch, useAppSelector } from '../../../../../lib/redux/hooks';
import { EditIcon } from '../../../../../ui/icons/edit';
import { TrashIcon } from '../../../../../ui/icons/trash';
import RadioButtonGroup from '../../../../../ui/molecules/RadioButtonGroup';
import { SingleCheckbox } from '../../../../../ui/molecules/SingleCheckbox';
import { PopupForm } from '../../../../../ui/organisms/popupForm';
import { CustomOptions } from '../../../../../utils';
import './styles.scss'
import { useEffect, useState } from "react";
import clsx from "clsx"
import { EditProtocolPopup } from '../../popup/editProtocol';
import { CompanyFolderDetailDTO } from '../../../dataService/dto';
import { DeleteCompanyFolder, GetAllCompanyFolders, resetCompanyFolderDeleteStatus, setSingleCFIdToUpdate, setUpdateCFActiveDetailConformityById, setUpdateCFActiveDetailHistoryById, setUpdateCFDetailRequestDeliveryOnHeadquarter, setUpdateCFDetailRequestNote, setUpdateCFHistoryDetailConformityById, setUpdateCFHistoryDetailHistoryById } from '../../../slice';
import { Alert } from '../../../../../ui/organisms/alert';
import Button from '../../../../../ui/molecules/button';
import { GetFileById, resetGetFileByIdStatus } from '../../../../../objectStorage/slice';

interface Props {
    protocols: {
        pd: CompanyFolderDetailDTO
        index: number
    }[]
    historyView: boolean
}

export function DetailTableCA(props: Props) {
    const {protocols, historyView} = props
    const dispatch = useAppDispatch()
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)
    const [editPopupShow, setEditPopupShow] = useState<boolean>(false)
    const [historyFlags, setHistoryFlags] = useState<boolean[]>([])
    const [conformityFlags, setConformityFlags] = useState<(boolean | null)[]>([])
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [protToDelete, setProtToDelete] = useState<CompanyFolderDetailDTO | null>(null)
    const [fileName, setFileName] = useState<string>('File')

    useEffect(() => {
        const hflags: boolean[] = []
        historyView ?
            cartellaAziendaleState.historyCFDetailsUpdateRequest.map(det => hflags.push(det.historic)) :
            cartellaAziendaleState.activeCFDetailsUpdateRequest.map(det => hflags.push(det.historic))
        setHistoryFlags(hflags)

        const cflags: (boolean | null)[] = []
        if(historyView)
            cartellaAziendaleState.historyCFDetailsUpdateRequest.map(det => cflags.push(det.conformityEvaluation))
        else
            cartellaAziendaleState.activeCFDetailsUpdateRequest.map(det => cflags.push(det.conformityEvaluation))
        setConformityFlags(cflags)
        
    }, [cartellaAziendaleState.historyCFDetailsUpdateRequest, cartellaAziendaleState.activeCFDetailsUpdateRequest])

    useEffect(() => {
        if (objectStorageState.getFileByIdStatus === 'successfully') {
            dispatch(resetGetFileByIdStatus())
            const base64Data = objectStorageState.getFileByIdResponse?.base64;
            if (base64Data && base64Data !== '') {
                const byteCharacters = atob(base64Data);
                const byteNumbers = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
                const blob = new Blob([byteNumbers], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                // Apri una finestra popup con un titolo personalizzato
                const newWindow = window.open('', '_blank', 'width=800,height=600');
                if (newWindow) {
                    newWindow.document.title = fileName;
                    const iframe = newWindow.document.createElement('iframe');
                    iframe.style.width = '100%';
                    iframe.style.height = '100%';
                    iframe.src = url;
                    newWindow.document.body.appendChild(iframe);
                }
            }
        }
    }, [objectStorageState.getFileByIdStatus]);

    useEffect(() => {
        if(cartellaAziendaleState.companyFolderDeleteStatus === 'successfully') {
            dispatch(resetCompanyFolderDeleteStatus())
            dispatch(GetAllCompanyFolders({
                customerId: cartellaAziendaleState.getAllCompanyFoldersFilters.customerId,
                itemsPerPage: 0,
                page: 0
            }))
            setShowAlert(false)
        }
    }, [cartellaAziendaleState.companyFolderDeleteStatus])

    const handleOnEdit = (pd: CompanyFolderDetailDTO) => {
        dispatch(setUpdateCFDetailRequestNote(pd.note))
        dispatch(setUpdateCFDetailRequestDeliveryOnHeadquarter(pd.deliveredOnHeadquarter))
        dispatch(setSingleCFIdToUpdate(pd.id))
        setEditPopupShow(true)
    }

    const handleOnView = (filename: string, fileId: string | null) => {
        if(fileId === null)
            return

        setFileName(filename)
        dispatch(GetFileById(fileId))
    }

    const getRadioValue = (flag: boolean | null): CustomOptions | null => {
        
        if(flag === null)
            return null
        return flag ? CustomOptions.YES : CustomOptions.NO
    }

    return (
        <div>
            <div>
                {/* <div className="table-head-ca uppercase" style={{display: 'flex'}}>
                    <th style={{flex: '5%'}}></th>
                    <th style={{flex: '25%'}}>Riferimento protocolli</th>
                    <th style={{flex: '40%', maxWidth: '1000px'}}>Note</th>
                    <th style={{flex: '15%', textAlign: 'center'}}>Storico</th>
                    <th style={{flex: '15%', textAlign: 'center'}}>Valutazione conformità</th>
                </div> */}
                <div className='pb-2'>
                    {
                        protocols.map((p, index) => 
                            <div
                            className="table-content-text-ca"
                            style={{borderTopWidth: index !== 0 ? 1 : 0, padding: index !== 0 ? '10px 0px 10px 0px' : '0px 0px 10px 0px'}}
                            key={index}
                            >
                                <div className='pt-25' style={{flex: '5%'}}>
                                    <div className="sub-options-ca" style={{justifyContent: 'center', alignItems: 'center'}}>
                                        <button onClick={() => handleOnEdit(p.pd)}>
                                            <EditIcon color='orange' size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setProtToDelete(p.pd)
                                            setShowAlert(true)
                                        }} className='ml-3'>
                                            <TrashIcon color='red' size={20}/>
                                        </button>
                                    </div>
                                </div>
                                <div className={clsx('pt-25', {'cursor-pointer': p.pd.fileId !== null})} 
                                    style={{flex: '25%', fontWeight: p.pd.fileId !== null ? '700' : undefined, fontSize: '14px'}}
                                    onClick={() => handleOnView(p.pd.entryProtocolDescription, p.pd.fileId)}>
                                    {p.pd.entryProtocolDescription}
                                </div>
                                <div className='pt-25' style={{flex: '40%', maxWidth: '1000px', fontSize: '14px'}}>{p.pd.note}</div>
                                <div className='pl-6' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '15%'}}>
                                    <div>
                                        <SingleCheckbox
                                            name={'active' + p.pd.id}
                                            checked={historyFlags[p.index]}
                                            setChecked={(value) => {
                                                historyView ? 
                                                    dispatch(setUpdateCFHistoryDetailHistoryById({index: p.index, data: value})) :
                                                    dispatch(setUpdateCFActiveDetailHistoryById({index: p.index, data: value}))
                                                    const hflags = [...historyFlags]
                                                    hflags[p.index] = value
                                                    setHistoryFlags(hflags)
                                            }}
                                            label=''
                                        />
                                    </div>
                                </div>
                                <div style={{flex: '15%', display: 'flex', justifyContent: 'center'}}>
                                    <RadioButtonGroup
                                        name={'Conformita' + p.pd.id}
                                        key={p.pd.id}
                                        options={Object.values(CustomOptions)}
                                        selectedValue={getRadioValue(conformityFlags[p.index])}
                                        onChange={(value) => {
                                            historyView ? 
                                                dispatch(setUpdateCFHistoryDetailConformityById({index: p.index, data: value === CustomOptions.YES ? true : false})) :
                                                dispatch(setUpdateCFActiveDetailConformityById({index: p.index, data: value === CustomOptions.YES ? true : false}))
                                            
                                                // const cflags = [...conformityFlags]
                                                // cflags[p.index] = value === CustomOptions.YES ? true : false
                                                // setConformityFlags(cflags)
                                        }}
                                        optionLabels={{
                                            [CustomOptions.YES]: 'SI',
                                            [CustomOptions.NO]: 'NO',
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                editPopupShow && (
                    <PopupForm title="Modifica protocollo alla cartella" close={() => {
                        setEditPopupShow(false)
                    }}>
                        <EditProtocolPopup close={() => setEditPopupShow(false)} />
                    </PopupForm>
                )
            }
             {
                (showAlert && protToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare il dettaglio?
                                </div>
                                <div className="mt-3 font-bold" style={{color: '#166428'}}>
                                    {protToDelete.entryProtocolDescription}
                                </div>
                            </div>
                            <div className="mt-6" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button 
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button 
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => dispatch(DeleteCompanyFolder(protToDelete.id))}
                                    />
                                </div>  
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}