import { AddedRegisterCreationDTO, AddedRegisterDTO, AddedRegisterUpdateDTO, BusinessRegisterCreationDTO, BusinessRegisterDTO, BusinessRegisterUpdateDTO, GetAllAddedRegistersDTO, GetAllAddedRegistersFiltersDTO, GetAllBusinessRegistersDTO, GetAllBusinessRegistersFiltersDTO, GetAllTypeRegistersDTO, GetAllTypeRegistersFiltersDTO, TypeRegisterDTO } from "./dto";
import { RegisterServiceImpl } from "./serviceImpl";

export interface RegisterService {
    // Type Register
    CreateTypeRegister(data: TypeRegisterDTO): Promise<void>
    UpdateTypeRegister(data: TypeRegisterDTO, id: string): Promise<void>
    DeleteTypeRegister(id: string): Promise<void>
    GetAllTypeRegisters(filters: GetAllTypeRegistersFiltersDTO): Promise<GetAllTypeRegistersDTO>
    GetTypeRegisterById(id: string): Promise<TypeRegisterDTO>

    // Added Register
    CreateAddedRegister(data: AddedRegisterCreationDTO): Promise<string>
    UpdateAddedRegister(data: AddedRegisterUpdateDTO, id: string): Promise<void>
    DeleteAddedRegister(id: string): Promise<void>
    GetAllAddedRegisters(filters: GetAllAddedRegistersFiltersDTO): Promise<GetAllAddedRegistersDTO>
    GetAddedRegisterById(id: string): Promise<AddedRegisterDTO>

    // Business Register
    CreateBusinessRegister(data: BusinessRegisterCreationDTO): Promise<string>
    UpdateBusinessRegister(data: BusinessRegisterUpdateDTO, id: string): Promise<void>
    DeleteBusinessRegister(id: string): Promise<void>
    GetAllBusinessRegisters(filters: GetAllBusinessRegistersFiltersDTO): Promise<GetAllBusinessRegistersDTO>
    GetBusinessRegisterById(id: string): Promise<BusinessRegisterDTO>
}

export function NewRegisterService(): RegisterService {
    return new RegisterServiceImpl()
}