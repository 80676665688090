import clsx from "clsx"
import { GenericBoxProps } from "./genericBox.types"
import './styles.scss'

export const GenericBox: React.FC<GenericBoxProps> = ({
    ...props
}) => {
    return (
        <div className={clsx("pre-box", {
            "cursor-pointer": props.onClick !== undefined
        })}
            onClick={() => props.onClick && props.onClick()}>
            <div className="generic-box">
                <div>
                    <div className="title">{props.title}</div>
                    <div className="description text-left">{props.description}</div>
                </div>
                <div className="mt-5">
                    {props.child}
                </div>
            </div>
        </div>
    )
}