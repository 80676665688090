import './style.scss';
import { RadioOption, RadioProps } from "./dto";


export function CustomRadio(props: RadioProps) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };

    return (
        <>
            {props.options.map((option: RadioOption) => (
                <label htmlFor={props.id} className="radio-label">
                    <input
                        disabled={props.disabled}
                        className="radio-input"
                        type="radio"
                        name={props.name}
                        id={props.id}
                        value={option.value.toString()}
                        onChange={handleChange}
                        checked={props.selectedValue === option.value}
                    />
                    <span className="custom-radio" />
                    {option.label}
                </label>
            ))}
        </>
    )
}