import { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetCustomerByCompanyNameResponseDTO } from "../../../pages/clienti/dto";
import { GetCustomersByCompanyName, GetCustomersById, resetCustomerByIdStatus, resetGetCustomersByCompanyName } from "../../../pages/clienti/slice";
import { RoundedCloseIcon } from "../../icons/CloseIcon";
import { formatterDate } from "../../../utils";

interface MultiSearchProps {
    value?: string[]
    defaultValue?: string | number
    onChangeText?: (text: string) => void
    startIcon?: ReactElement
    endIcon?: ReactElement
    disabled?: boolean
    label?: string
    supportingText?: string
    error?: boolean
    placeholder?: string,
    onClick?:() => void
    onSelect?: (result: string) => void;
    onSelectAddHandler?: (res: string[]) => void
    defaultClientIds?: string []
    searchOne?: boolean
}

const InputMultiSearch: React.FC<MultiSearchProps> = ({...props}) => {
    const dispatch = useAppDispatch()
    const [showResults, setShowResults] = useState<boolean>(false); // Stato per la riga selezionata
    const [searchResults, setSearchResults] = useState<GetCustomerByCompanyNameResponseDTO[]>([]); // Stato per i risultati della ricerca
    const [responseResults, setResponseResults] = useState<GetCustomerByCompanyNameResponseDTO[]>([]); // Stato per i risultati della ricerca
    const [selectedResultIds, setSelectedResultIds] = useState<string[]>([]); // Stato per la riga selezionata
    const [selectedResultClients, setSelectedResultClients] = useState<GetCustomerByCompanyNameResponseDTO[]>([]); // messaggio per la riga selezionata
    const clientiState = useAppSelector(state => state.clienti)

    const handleSelectResult = (result: GetCustomerByCompanyNameResponseDTO) => {
        const currSelected = [...selectedResultIds];
        currSelected.push(result.id);
        setSelectedResultIds(currSelected);
        const currSelectedMess = [...selectedResultClients];
        currSelectedMess.push(result);
        setSelectedResultClients(currSelectedMess);
        
        if (props.onSelectAddHandler) {
            props.onSelectAddHandler(currSelected);
        }
    
        setSearchResults([]);
        if (props.onSelect) {
            props.onSelect(result.id);
        }
    };
    

    const handleOnDelete = (c: GetCustomerByCompanyNameResponseDTO) => {
        const newIds = selectedResultIds.filter(id => id !== c.id); 
        setSelectedResultIds(newIds);
    
        const newClients = selectedResultClients.filter(client => client.id !== c.id);
        setSelectedResultClients(newClients);
    
        if (props.onSelectAddHandler) {
            props.onSelectAddHandler(newIds); 
        }
    };

    useEffect(() => {
        if(props.defaultClientIds) {
            setSelectedResultIds(props.defaultClientIds)
            props.defaultClientIds.map(id => dispatch(GetCustomersById(id)))
        }
    }, [])
    

    useEffect(() => {
        setSelectedResultIds([]);
        setShowResults(false)
        setSelectedResultClients([])
    }, [props.disabled])

    useEffect(() => {
        if(clientiState.getCustomersByIdResponseStatus === 'successfully' &&
            clientiState.getCustomersByIdResponse !== undefined
        ) {
            const currSelectedMess = [...selectedResultClients];
            currSelectedMess.push(clientiState.getCustomersByIdResponse);
            setSelectedResultClients(currSelectedMess);
            dispatch(resetCustomerByIdStatus('idle'))
        }
    }, [clientiState.getCustomersByIdResponseStatus])

    useEffect(() => {
        if(clientiState.getCustomersByCompanyNameStatus === 'successfully' &&
            clientiState.getCustomersByCompanyNameResponse !== undefined
        ) {
            setResponseResults(clientiState.getCustomersByCompanyNameResponse)
            setSearchResults(clientiState.getCustomersByCompanyNameResponse)
            setShowResults(true)
            dispatch(resetGetCustomersByCompanyName())
        }
    }, [clientiState.getCustomersByCompanyNameStatus])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
            {props.label && <span className="input-label">{props.label}</span>}
            <div
                className="input-container"
                style={{
                    backgroundColor: props.disabled ? 'rgba(242, 242, 250, 1)' : 'white',
                    borderColor: props.error ? 'rgba(255, 198, 194, 1)' : 'rgba(203, 203, 235, 1)',
                }}
            >
                {props.startIcon && <div style={{ maxHeight: '20px' }}>{props.startIcon}</div>}
                <input
                    placeholder={props.placeholder}
                    disabled={props.disabled || (props.searchOne && selectedResultClients.length > 0 )}
                    value={props.value}
                    onClick={props.onClick}
                    defaultValue={props.defaultValue}
                    onChange={(e) => {
                        if (e.target.value.length > 2) {
                            dispatch(GetCustomersByCompanyName(e.target.value))
                        }
                        else {
                            setShowResults(false)
                        }

                        props.onChangeText && props.onChangeText(e.target.value);

                        if (responseResults.length > 0) {
                            const filteredResults = responseResults.filter((customer: GetCustomerByCompanyNameResponseDTO) =>
                                customer.clientCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
                                customer.companyName.toLowerCase().includes(e.target.value.toLowerCase())
                            );
                            setSearchResults(filteredResults)
                        }


                    }}
                    className={"input-value" + (props.error ? '-error' : '')}
                    style={{
                        border: 0,
                        outline: 0,
                        height: '20px',
                        backgroundColor: 'white',
                        width: '100%',
                        color: '#62628A',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '14px',
                    }}
                />
                {props.endIcon && <div style={{ maxHeight: '20px' }}>{props.endIcon}</div>}
            </div>
            {props.supportingText && (
                <span className="input-supporting-text" style={{ color: props.error ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>
                    {props.supportingText}
                </span>
            )}
            {(searchResults.length > 0 && showResults) && (
                <ul className="search-results" style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%', border: '1px solid rgba(203, 203, 235, 1)', borderRadius: '4px', backgroundColor: 'white', color:'#37565A' }}>
                    {searchResults.map((customer) => (
                        <li
                            key={customer.id}
                            onClick={() => handleSelectResult(customer)}
                            style={{
                                padding: '8px',
                                cursor: 'pointer',
                                fontSize:'14px',
                                color: customer.endingDate !== null ? 'rgb(206, 23, 17)' : '',
                                backgroundColor: selectedResultIds?.includes(customer.id) ? 'rgba(203, 203, 235, 0.5)' : 'white',
                            }}
                        >
                            {customer.companyName} - {customer.countryRegion} - {customer.cuaa} - Data In: {formatterDate(customer.startingDate)} Data Out: {customer.endingDate ? formatterDate(customer.startingDate) : '-'}
                        </li>
                    ))}
                </ul>
            )}
            <div className="mt-3">
                {
                    selectedResultClients.map(client => (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',  paddingBottom: '3px'}}>
                            <span className="label-search-code text-brandPrimary-700">{client.companyName} - {client.countryRegion} - {client.cuaa} - Data In: {formatterDate(client.startingDate)} Data Out: {client.endingDate ? formatterDate(client.startingDate) : '-'}</span>
                            <button onClick={() => handleOnDelete(client)}>
                                <RoundedCloseIcon size={18} color="#4D797F" />
                            </button>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}

export default InputMultiSearch