import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import Button from "../../ui/molecules/button"
import Input from "../../ui/molecules/input/Input"
import { setTypeRegisterUpdateCode, setTypeRegisterUpdateName, TypeRegisterUpdate } from "../gestioneClienti/registriAziendali/slice"

interface Props {
    close: () => void
}

export function TypeRegisterEditPopup(props: Props) {
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)

    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ borderTopWidth: 1 }}>
                <div className='pb-3'>
                    <Input
                        label={'Nome'}
                        placeholder="Specifica nome..."
                        value={registerState.typeRegisterUpdateRequest.name}
                        onChangeText={(text) => {
                            dispatch(setTypeRegisterUpdateName(text))
                        }}
                    />
                </div>
                <div className='pb-3'>
                    <Input
                        label={'Codice'}
                        placeholder="Specifica codice..."
                        value={registerState.typeRegisterUpdateRequest.code}
                        onChangeText={(text) => {
                            dispatch(setTypeRegisterUpdateCode(text))
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(TypeRegisterUpdate({body: registerState.typeRegisterUpdateRequest, id: registerState.typeRegisterIdToUpdate}))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}