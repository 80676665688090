
import { GetAllWaiverFromFiltersDTO, GetAllWaiverFromResponse, createWaiverFromDTO, createUpdateWaiverFromDTO } from "./dto"
import { WaiverFromCodexServiceImpl } from "./serviceImpl"


export interface WaiverFromCodexService {
    GetAllWaiverFromCodex(filters: GetAllWaiverFromFiltersDTO): Promise<GetAllWaiverFromResponse>
    GetWaiverFromExcel(filters: GetAllWaiverFromFiltersDTO): Promise<any>
    CreateWaiverFromCodex(data: createUpdateWaiverFromDTO): Promise<void>
    UpdateWaiverFromCodex(data: createUpdateWaiverFromDTO, id: string): Promise<void>

}

export function NewWaiverFromCodexService(): WaiverFromCodexService {
    return new WaiverFromCodexServiceImpl()
}