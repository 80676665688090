import axios from "axios";
import { AddedRegisterCreationDTO, AddedRegisterDTO, AddedRegisterUpdateDTO, BusinessRegisterCreationDTO, BusinessRegisterDTO, BusinessRegisterUpdateDTO, GetAllAddedRegistersDTO, GetAllAddedRegistersFiltersDTO, GetAllBusinessRegistersDTO, GetAllBusinessRegistersFiltersDTO, GetAllTypeRegistersDTO, GetAllTypeRegistersFiltersDTO, TypeRegisterDTO } from "./dto";
import { RegisterService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class RegisterServiceImpl implements RegisterService {

    /* TYPE REGISTER */

    public CreateTypeRegister(data: TypeRegisterDTO): Promise<void> {
        return axios.post("/api/type-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateTypeRegister(data: TypeRegisterDTO, id: string): Promise<void> {
        return axios.put("/api/type-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteTypeRegister(id: string): Promise<void> {
        return axios.delete("/api/type-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllTypeRegisters(filters: GetAllTypeRegistersFiltersDTO): Promise<GetAllTypeRegistersDTO> {
        return axios.get("/api/type-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetTypeRegisterById(id: string): Promise<TypeRegisterDTO> {
        return axios.get("/api/type-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* ADDED REGISTER */

    public CreateAddedRegister(data: AddedRegisterCreationDTO): Promise<string> {
        return axios.post("/api/added-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateAddedRegister(data: AddedRegisterUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/added-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteAddedRegister(id: string): Promise<void> {
        return axios.delete("/api/added-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllAddedRegisters(filters: GetAllAddedRegistersFiltersDTO): Promise<GetAllAddedRegistersDTO> {
        return axios.get("/api/added-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                businessRegisterId: filters.businessRegisterId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAddedRegisterById(id: string): Promise<AddedRegisterDTO> {
        return axios.get("/api/added-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* BUSINESS REGISTER */
    public CreateBusinessRegister(data: BusinessRegisterCreationDTO): Promise<string> {
        return axios.post("/api/business-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateBusinessRegister(data: BusinessRegisterUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/business-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteBusinessRegister(id: string): Promise<void> {
        return axios.delete("/api/business-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllBusinessRegisters(filters: GetAllBusinessRegistersFiltersDTO): Promise<GetAllBusinessRegistersDTO> {
        return axios.get("/api/business-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId,
                headQuarter: filters.headQuarter,
                year: filters.year
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetBusinessRegisterById(id: string): Promise<BusinessRegisterDTO> {
        return axios.get("/api/business-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}