import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { SelectCustom } from "../../molecules/select";
import { sot } from "../../../utils";
import { setAuthorityOffices } from "../../../pages/clienti/slice";
import { TrashIcon } from "../../icons/trash";
import Button from "../../molecules/button";

export function AuthorityOffices() {
  const clientiState = useAppSelector((state) => state.clienti);
  const dispatch = useAppDispatch();
  let offices: ReactNode[] = [];

  useEffect(() => {
    if (clientiState.getCustomersByIdResponse !== undefined) {
      dispatch(setAuthorityOffices(clientiState.getCustomersByIdResponse.headQuarters));

    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  if (
    clientiState.clienteEditCreateRequest.headQuarters !== undefined &&
    clientiState.clienteEditCreateRequest.headQuarters.length > 0
  ) {
    clientiState.clienteEditCreateRequest.headQuarters.forEach((element, index) => {
      offices.push(
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 0.95, paddingRight: 5 }}>
            <SelectCustom
              error={clientiState.createCustomerErrors.headQuarters}
              errorLabel="Scegliere SOT"
              placeholder="Sede di competenza"
              options={sot}
              defaultValue={element}
              value={element}
              onChange={(value) => {
                const newOffice = [...clientiState.clienteEditCreateRequest.headQuarters];
                if (value !== undefined) {
                  newOffice[index] = value;
                  dispatch(setAuthorityOffices(newOffice));
                }
              }}
            />
          </div>
          <div
            style={{ flex: 0.05 }}
            onClick={() => {
              let newOffices = [...clientiState.clienteEditCreateRequest.headQuarters];
              newOffices = newOffices
                .slice(0, index)
                .concat(newOffices.slice(index + 1));
              dispatch(
                setAuthorityOffices(newOffices.length === 0 ? [""] : newOffices)
              );
            }}
          >
            <TrashIcon color="#8383AD" size={5} />
          </div>
        </div>
      );
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "6px",
        padding: "0px 24px",
        marginBottom: '50px'
      }}
    >
      <span className="input-label">Sede di competenza</span>
      {offices}

      <div className="mt-2">
        <Button
          onClick={() => {
            const newOffices = [...clientiState.clienteEditCreateRequest.headQuarters]
            newOffices.push('')
            dispatch(setAuthorityOffices(newOffices))
          }}
          label="Aggiungi sede"
          size="sm"
          iconPosition="off"
          variant="outline"
          color="dimgrey"
        />
      </div>
      {
        clientiState.createCustomerErrors.headQuarters && (
          <span className="input-supporting-text">Scegliere almeno una Sede</span>
        )
      }

    </div>
  )
}
