import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { ProtocolSelectByObject } from '../../../../ui/molecules/ProtocolSelectByObj'
import { CustomRadio } from '../../../../ui/molecules/radioButton'
import { SelectCustom } from '../../../../ui/molecules/select'
import '../styles.scss'
import { Calendar } from '../../../../ui/molecules/calendar'
import TextArea from '../../../../ui/molecules/textArea'
import Button from '../../../../ui/molecules/button'
import { ProtocolSelectFromDate } from '../../../../ui/molecules/ProtocolSelectFromDate'
import { BusinessRegisterRequestMotivationOptions, BusinessRegisterTypologyRequestOptions } from '../../../../utils'
import { BusinessRegisterUpdate, BusinessRegisterValidation, GetAllBusinessRegisters, resetBusinessRegisterValidationStatus, resetGetAllBusinessRegistersStatus, setBusinessRegisterCreationReleaseRegister, setBusinessRegisterUpdateNote, setBusinessRegisterUpdateReleaseDate, setBusinessRegisterUpdateReleaseProtocolId, setBusinessRegisterUpdateRequestProtocolId, setBusinessRegisterUpdateRequestReason, setBusinessRegisterUpdateRequestType, setLatestAvailableDate } from '../slice'
import { BusinessRegisterReleaseRegister } from '../dataService/dto'

export function EditRegistroForms() {
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.users)
    const sessionState = useAppSelector(state => state.session)
    const registerState = useAppSelector(state => state.register)
    const [closeFlag, setCloseFlag] = useState<string>('IDLE')

    useEffect(() => {
        dispatch(GetAllBusinessRegisters({itemsPerPage: 1, page: 0, order: false, headQuarter: userState.findMeResponse.headQuarter}))
        
        setCloseFlag(registerState.businessRegisterUpdateRequest.releaseRegister === BusinessRegisterReleaseRegister.PROTOCOLLO_RILASCIO ? 'CLOSE1' : 'CLOSE2')
    }, [])

    useEffect(() => {
        if(registerState.getAllBusinessRegistersStatus === 'successfully' &&
            registerState.getAllBusinessRegistersResponse !== undefined
        ) {
            dispatch(resetGetAllBusinessRegistersStatus())
            const latestDate: Date = new Date(registerState.getAllBusinessRegistersResponse.data[0].releaseDate)
            latestDate.setDate(latestDate.getDate() + 1)
            dispatch(setLatestAvailableDate(latestDate))
        }
    }, [registerState.getAllBusinessRegistersStatus])

    useEffect(() => {
        if(registerState.businessRegisterValidationStatus === 'successfully') {
            dispatch(resetBusinessRegisterValidationStatus())
            dispatch(BusinessRegisterUpdate({body: registerState.businessRegisterUpdateRequest, id: registerState.businessRegisterIdToUpdate}))
        }
    }, [registerState.businessRegisterValidationStatus])

    return (
        <div>

            {/* HEADER */}
            <div className="register-header">
                <span className='title'>Modifica registro aziendale</span>
                <Button
                    iconPosition='off'
                    variant='solid'
                    color='orange'
                    onClick={() => dispatch(BusinessRegisterValidation(registerState.businessRegisterUpdateRequest))}
                    size='sm'
                    label='Salva' 
                    />
            </div>

            {/* CONTENT */}
            <div className='register-content'>
                <div className='text-left mb-3'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Registro aziendale </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Dati richiesta RE002</span>
                </div>

                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Motivo richiesta</span>
                        </div>
                        <SelectCustom
                            error={registerState.businessRegisterErrors.requestReason}
                            errorLabel='Motivazione mancante'
                            placeholder={'Seleziona motivazione...'}
                            options={BusinessRegisterRequestMotivationOptions}
                            value={registerState.businessRegisterUpdateRequest.requestReason}
                            onChange={(value) => dispatch(setBusinessRegisterUpdateRequestReason(value))}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Protocollo Richiesta [RRA]</span>
                        </div>
                        <ProtocolSelectByObject
                            error={registerState.businessRegisterErrors.requestProtocolId}
                            errorLabel='Protocollo mancante' 
                            type='entry'
                            objectId='88'
                            customerId={sessionState.selected.customerId!}
                            value={registerState.businessRegisterUpdateRequest.requestProtocolId}
                            placeholder='Seleziona protocollo...'
                            onChange={(value) => dispatch(setBusinessRegisterUpdateRequestProtocolId(value))}
                            />
                    </div>
                </div>

                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Tipo di richiesta</span>
                        </div>
                        <SelectCustom
                            error={registerState.businessRegisterErrors.requestType}
                            errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            options={BusinessRegisterTypologyRequestOptions}
                            value={registerState.businessRegisterUpdateRequest.requestType}
                            onChange={(value) => dispatch(setBusinessRegisterUpdateRequestType(value))}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Notifica</span>
                        </div>
                        <SelectCustom
                            // error={}
                            // errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            options={[{value: '1', label: 'Tipo 1'}]}
                            onChange={(value) => {}}
                        />
                    </div>
                </div>

                <div className='text-left mt-3 mb-3'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Registro aziendale </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Rilascio registro</span>
                </div>

                <div className='pb-5' style={{borderBottomWidth: 1}}>
                    <div className='mb-5' style={{display: 'flex', gap: '8px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CustomRadio
                                options={[{ label: '', value: 'CLOSE1' }]}
                                selectedValue={closeFlag}
                                onChange={(value) => {
                                    setCloseFlag(value)
                                    dispatch(setBusinessRegisterCreationReleaseRegister(BusinessRegisterReleaseRegister.PROTOCOLLO_RILASCIO))
                                    dispatch(setBusinessRegisterUpdateReleaseDate(undefined))
                                }}
                                id={'close1'}
                                name={'close1'}
                            />
                        </div>
                        <div style={{flex: '1'}}>
                            <div className='text-left'>
                                <span className='tit-1-status'>Protocollo di rilascio</span>
                            </div>
                            <div>
                                <ProtocolSelectFromDate
                                    type='entry'
                                    disabled={closeFlag !== 'CLOSE1'}
                                    fromDate={registerState.latestAvailableDate} // DA CAMBIARE CON ULTIMA DATA + 1 NEL LISTING
                                    customerId={sessionState.selected.customerId!}
                                    placeholder='Scegli protocollo di rilascio...'
                                    value={registerState.businessRegisterUpdateRequest.releaseProtocolId}
                                    onChange={(value) => dispatch(setBusinessRegisterUpdateReleaseProtocolId(value))}
                                    />
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex', gap: '8px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <CustomRadio
                                options={[{ label: '', value: 'CLOSE2' }]}
                                selectedValue={closeFlag}
                                onChange={(value) => {
                                    setCloseFlag(value)
                                    dispatch(setBusinessRegisterCreationReleaseRegister(BusinessRegisterReleaseRegister.CONSEGNATI_DAL_TECNICO_ISPETTORE))
                                    dispatch(setBusinessRegisterUpdateReleaseProtocolId(undefined))
                                }}
                                id={'close2'}
                                name={'close2'}
                            />
                        </div>
                        <div style={{flex: '1'}}>
                            <div className='text-left'>
                                <span className='tit-1-status'>Consegnati dal tecnico ispettore nel corso della visita ispettiva </span>
                                <span className='tit-1-status'> / Data </span>
                            </div>
                            <div>
                                <Calendar
                                    selected={registerState.businessRegisterUpdateRequest.releaseDate}
                                    onChange={(date) => dispatch(setBusinessRegisterUpdateReleaseDate(date))}
                                    minDate={registerState.latestAvailableDate}
                                    disabled={closeFlag !== 'CLOSE2'}
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pt-5'>
                    <TextArea
                        value={registerState.businessRegisterUpdateRequest.note}
                        onChangeText={(text) => dispatch(setBusinessRegisterUpdateNote(text))}
                        label="Note"
                        />
                </div>

            </div>
        </div>
    )
}