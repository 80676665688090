import { useNavigate } from "react-router-dom";
import { GenericBox } from "../genericBox";
import './styles.scss'

export function GestioneAllegatiBox() {
    const navigate = useNavigate()

    return (
        <div>
            <GenericBox
                title="Gestione Allegati"
                description="Creazione e modifica degli allegati in Entrata e in Partenza"
                child={
                    <div className="attachments-container">
                        <div className="box" onClick={() => navigate('/gaEntrata')}>
                            <span className="label">
                                Allegati in Entrata
                            </span>
                        </div>
                        <div className="box" onClick={() => navigate('/gaPartenza')}>
                            <span className="label">
                                Allegati in Partenza
                            </span>
                        </div>
                    </div>
                }
                />
        </div>
    )
}