import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { EditUserRequestDTO, FindAllUsersDTO, FindAllUsersFilters, findMeResponseDTO, UserDTO, UserEditCreationDTO } from "./dto"
import { NewUsersService } from "./service"
import { PromiseStatuses } from "../../lib/utils"
import { format } from "date-fns";
import { addTechinicianUserDTO } from "../businessTechnician/dto";

interface UsersState {
    createUserErrors: {
        role: boolean,
        text: string,
        name: boolean,
        surname: boolean,
        username: boolean,
        pec: boolean,
        fiscalCode: boolean,
        email: boolean,
        sot: boolean,
        activationDate: boolean
        phone: boolean
    },
    userCreationStatus: PromiseStatuses
    findMeStatus: PromiseStatuses
    findMeResponse: findMeResponseDTO
    getUsersResponse?: FindAllUsersDTO
    getUsersStatus: PromiseStatuses
    deleteUserStatus: PromiseStatuses
    usersEditCreateRequest: UserEditCreationDTO
    filters: FindAllUsersFilters
    selectedUserId: string
    loggedUserId: string
    findUserByIdStatus: PromiseStatuses
    findUserByIdResponse?: UserDTO
    editUserRequest: UserEditCreationDTO
    editUserStatus: PromiseStatuses
    userActivationStatus: PromiseStatuses
    userValidationStatus: PromiseStatuses
    RemoveBusinessTechnicianStatus: PromiseStatuses
}

const initialState: UsersState = {
    createUserErrors: {
        role: false,
        text: '',
        name: false,
        surname: false,
        pec: false,
        username: false,
        fiscalCode: false,
        email: false,
        sot: false,
        activationDate: false,
        phone: false
    },
    findMeResponse:  {},
    userCreationStatus: 'idle',
    getUsersStatus: 'idle',
    editUserStatus: 'idle',
    findUserByIdStatus: 'idle',
    deleteUserStatus: 'idle',
    findMeStatus: 'idle',
    RemoveBusinessTechnicianStatus: 'idle',
    selectedUserId: '',
    loggedUserId: '',
    filters: {
        headquarter: undefined,
        active: undefined,
        order: true,
        sort: undefined,
        itemsPerPage: 10,
        page: 0
    },
    usersEditCreateRequest: {
        username: '',
        name: '',
        surname: '',
        email: '',
        role: '',
        fiscalCode: '',
        headQuarter: '',
        activationDate: new Date(),
        pec: '',
        active: true,
        phone: '',
        office: ''
    },
    editUserRequest: {
        username: '',
        name: '',
        surname: '',
        email: '',
        role: '',
        fiscalCode: '',
        headQuarter: '',
        activationDate: new Date(),
        deactivationDate: new Date(),
        pec: '',
        active: true,
        phone: '',
        office: ''
    },
    userActivationStatus: 'idle',
    userValidationStatus: 'idle'
}


export const UserCreation = createAsyncThunk(
    'users/UserCreation',
    async (request: UserEditCreationDTO, thunkApi): Promise<void> => {
        const usersService = NewUsersService()

        return usersService.createUser(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async (filters: FindAllUsersFilters, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()
        return UsersService.getUsers(filters)
    }
)

export const findUserById = createAsyncThunk(
    'users/findUserById',
    async (id: string, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.findUserById(id)
    }
)

export const editUser = createAsyncThunk(
    'users/editUser',
    async (request: { id: string, data: UserEditCreationDTO }, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.editUser(request.id, request.data)
    }
)

export const ActivateUser = createAsyncThunk(
    'users/activateUser',
    async (request: { id: string, data: UserEditCreationDTO }, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.activateUser(request.id, request.data)
    }
)

export const RemoveBusinessTechnician = createAsyncThunk(
    'users/RemoveBusinessTechnician',
    async (request: { id: string, techId: string }, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.RemoveBusinessTechnician(request.id, request.techId)
    }
)

export const UserValidation = createAsyncThunk(
    'users/validate',
    async (request: UserEditCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetUserError())

        if(request.role === '' || request.role === null) {
            thunkApi.dispatch(setValidateRole(true))
            isValid = false
        }

        if(request.name === '' || request.name === null) {
            thunkApi.dispatch(setValidateName(true))
            isValid = false
        }

        if(request.surname === '' || request.surname === null) {
            thunkApi.dispatch(setValidateSurname(true))
            isValid = false
        }

        if(request.pec === '' || request.pec === null) {
            thunkApi.dispatch(setValidatePec(true))
            isValid = false
        }

        if(request.username === '' || request.username === null) {
            thunkApi.dispatch(setValidateUsername(true))
            isValid = false
        }

        if(request.fiscalCode === '' || request.fiscalCode === null || request.fiscalCode.length !== 16) {
            thunkApi.dispatch(setValidateFiscalCode(true))
            isValid = false
        }

        if(request.email === '' || request.email === null) {
            thunkApi.dispatch(setValidateEmail(true))
            isValid = false
        }

        if(request.activationDate === undefined) {
            thunkApi.dispatch(setValidateActivationDate(true))
            isValid = false
        }

        if(request.headQuarter === '' || request.headQuarter === null) {
            thunkApi.dispatch(setValidateSot(true))
            isValid = false
        }

        if(request.phone !== '' && (request.phone.length < 7 || request.phone.length > 15)) {
            thunkApi.dispatch(setValidatePhone(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const RemoveUser = createAsyncThunk(
    'users/RemoveUser',
    async (id: string, thunkApi): Promise<any> => {
        const UsersService = NewUsersService()

        return UsersService.RemoveUser(id)
    }
)

export const linkBusinessTech = createAsyncThunk(
    'users/linkBusinessTech',
    async (data: addTechinicianUserDTO, thunkApi): Promise<number> => {
        const businessTechinicianService = NewUsersService()

        return businessTechinicianService.linkBusinessTech(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const FindMe = createAsyncThunk(
    'users/FindMe',
    async (thunkApi): Promise<any> => {
        const UsersService = NewUsersService()
        return UsersService.FindMe()
    },
)


const UsersSlice = createSlice({
    name: 'users/slice',
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.usersEditCreateRequest.username = action.payload
        },
        setName: (state, action) => {
            state.usersEditCreateRequest.name = action.payload
        },
        setSurname: (state, action) => {
            state.usersEditCreateRequest.surname = action.payload
        },
        setRole: (state, action) => {
            state.usersEditCreateRequest.role = action.payload
        },
        setEmail: (state, action) => {
            state.usersEditCreateRequest.email = action.payload
        },
        setPhone: (state, action) => {
            state.usersEditCreateRequest.phone = action.payload
        },
        setPec: (state, action) => {
            state.usersEditCreateRequest.pec = action.payload
        },
        setPMId: (state, action) => {
            state.usersEditCreateRequest.personalManagementId = action.payload
        },
        setOffice: (state, action) => {
            state.usersEditCreateRequest.office = action.payload
        },
        setOfficeProt: (state, action) => {
            state.usersEditCreateRequest.managementProtocolOffice = action.payload
        },
        setSotProt: (state, action) => {
            state.usersEditCreateRequest.managementProtocolHeadQuarter = action.payload
        },
        setFiscalCode: (state, action) => {
            state.usersEditCreateRequest.fiscalCode = action.payload
        },
        setHeadQuarter: (state, action) => {
            state.usersEditCreateRequest.headQuarter = action.payload
        },
        setHeadQuarterFilter: (state, action) => {
            state.filters.headquarter = action.payload
        },
        setStatusFilter: (state, action) => {
            if(action.payload === 'active') {
                state.filters.active= true
            } else {
                state.filters.active = false
            }
        },
        setActivationDate: (state, action) => {
            state.usersEditCreateRequest.activationDate = action.payload
        },
        setDeActivationDate: (state, action) => {
            state.usersEditCreateRequest.deactivationDate = action.payload
        },
        setFindAllUsersFiltersPage: (state, action) => {
            state.filters.page = action.payload
        },
        selectedUserId: (state, action) => {
            state.selectedUserId = action.payload
        },
        setLoggedUserId: (state, action) => {
            state.loggedUserId = action.payload
        },

        // VALIDATION
        resetUserError: (state) => {
            state.createUserErrors = {
                role: false,
                text: '',
                name: false,
                surname: false,
                pec: false,
                username: false,
                fiscalCode: false,
                email: false,
                sot: false,
                activationDate: false,
                phone: false
            }
        },
        setValidateRole: (state, action) => {
            state.createUserErrors.role = action.payload
        },
        setValidateText: (state, action) => {
            state.createUserErrors.text = action.payload
        },
        setValidateName: (state, action) => {
            state.createUserErrors.name = action.payload
        },
        setValidateSurname: (state, action) => {
            state.createUserErrors.surname = action.payload
        },
        setValidatePec: (state, action) => {
            state.createUserErrors.pec = action.payload
        },
        setValidateUsername: (state, action) => {
            state.createUserErrors.username = action.payload
        },
        setValidateFiscalCode: (state, action) => {
            state.createUserErrors.fiscalCode = action.payload
        },
        setValidateEmail: (state, action) => {
            state.createUserErrors.email = action.payload
        },
        setValidateSot: (state, action) => {
            state.createUserErrors.sot = action.payload
        },
        setValidateActivationDate: (state, action) => {
            state.createUserErrors.activationDate = action.payload
        },
        setValidatePhone: (state, action) => {
            state.createUserErrors.phone = action.payload
        },

        resetCreationStatusIdle: (state) => {
            state.userCreationStatus = 'idle'
        },
        resetEditUserStatusIdle: (state) => {
            state.editUserStatus = 'idle'
        },

        resetGetUserByIdStatusIdle: (state) => {
            state.findUserByIdStatus = 'idle'
        },

        resetfindMeStatusIdle: (state) => {
            state.findUserByIdStatus = 'idle'
        },
        resetUserActivationStatus: (state) => {
            state.userActivationStatus = 'idle'
        },
        resetUserValidationStatus: (state) => {
            state.userValidationStatus = 'idle'
        },
        resetRemoveBusTechFromUserStatus: (state) => {
            state.RemoveBusinessTechnicianStatus = 'idle'
        }


    },
    extraReducers(builder) {
        builder
            .addCase(UserCreation.pending, (state) => {
                state.userCreationStatus = 'loading'
            })
            .addCase(UserCreation.fulfilled, (state) => {
                state.userCreationStatus = 'successfully'
            })
            .addCase(UserCreation.rejected, (state, action) => {
                state.userCreationStatus = 'failed'
                //@ts-ignore
                if (action.payload.response.data.errors === 'Error creating user on keycloak') {
                    state.userCreationStatus = 'idle'
                    state.createUserErrors.text = 'Questa email è già stata utilizzata.'
                } else {
                    state.userCreationStatus = 'failed'
                }

            })

            .addCase(getUsers.pending, (state) => {
                state.getUsersStatus = 'loading'
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.getUsersStatus = 'successfully'
                state.getUsersResponse = action.payload
            })
            .addCase(getUsers.rejected, (state) => {
                state.getUsersStatus = 'failed'
            })

            .addCase(FindMe.pending, (state) => {
                state.findMeStatus = 'loading'
            })
            .addCase(FindMe.fulfilled, (state, action) => {
                state.findMeStatus = 'successfully'
                state.loggedUserId = action.payload.id 
                state.findMeResponse = action.payload
            })
            .addCase(FindMe.rejected, (state) => {
                state.findMeStatus = 'failed'
            })

            .addCase(findUserById.pending, (state) => {
                state.findUserByIdStatus = 'loading'
            })
            .addCase(findUserById.fulfilled, (state, action) => {
                state.findUserByIdStatus = 'successfully'
                state.findUserByIdResponse = action.payload
            })
            .addCase(findUserById.rejected, (state) => {
                state.findUserByIdStatus = 'failed'
            })

            .addCase(editUser.pending, (state) => {
                state.editUserStatus = 'loading'
            })
            .addCase(editUser.fulfilled, (state, action) => {
                state.editUserStatus = 'successfully'
            })
            .addCase(editUser.rejected, (state) => {
                state.editUserStatus = 'failed'
            })

            .addCase(ActivateUser.pending, (state) => {
                state.userActivationStatus = 'loading'
            })
            .addCase(ActivateUser.fulfilled, (state, action) => {
                state.userActivationStatus = 'successfully'
            })
            .addCase(ActivateUser.rejected, (state) => {
                state.userActivationStatus = 'failed'
            })

            .addCase(UserValidation.pending, (state) => {
                state.userValidationStatus = 'loading'
            })
            .addCase(UserValidation.fulfilled, (state, action) => {
                state.userValidationStatus = 'successfully'
            })
            .addCase(UserValidation.rejected, (state) => {
                state.userValidationStatus = 'failed'
            })

            .addCase(RemoveUser.pending, (state) => {
                state.editUserStatus = 'loading'
            })
            .addCase(RemoveUser.fulfilled, (state, action) => {
                state.editUserStatus = 'successfully'
            })
            .addCase(RemoveUser.rejected, (state) => {
                state.editUserStatus = 'failed'
            })

            .addCase(RemoveBusinessTechnician.pending, (state) => {
                state.RemoveBusinessTechnicianStatus = 'loading'
            })
            .addCase(RemoveBusinessTechnician.fulfilled, (state, action) => {
                state.RemoveBusinessTechnicianStatus = 'successfully'
            })
            .addCase(RemoveBusinessTechnician.rejected, (state) => {
                state.RemoveBusinessTechnicianStatus = 'failed'
            })

    },
})

export const {
    setUsername,
    setHeadQuarterFilter,
    setName,
    setEmail,
    setFiscalCode,
    setHeadQuarter,
    setRole,
    setPec,
    setSurname,
    setPhone,
    setActivationDate,
    setFindAllUsersFiltersPage,
    setStatusFilter,
    setDeActivationDate,
    selectedUserId,
    setLoggedUserId,
    setOffice,
    setOfficeProt,
    setSotProt,
    setPMId,
    setValidateEmail,
    setValidateFiscalCode,
    setValidateName,
    setValidatePec,
    setValidateRole,
    setValidateSot,
    setValidatePhone,
    setValidateSurname,
    setValidateActivationDate,
    setValidateText,
    setValidateUsername,
    resetUserError,
    resetEditUserStatusIdle,
    resetCreationStatusIdle,
    resetGetUserByIdStatusIdle,
    resetfindMeStatusIdle,
    resetUserActivationStatus,
    resetUserValidationStatus,
    resetRemoveBusTechFromUserStatus
} = UsersSlice.actions

export default UsersSlice.reducer