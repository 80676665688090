import { ReactElement } from "react"
import { useAppDispatch } from "../../../lib/redux/hooks"
import './style.scss'


interface Props {
    children: ReactElement
    content?: ReactElement
    rightBar?: boolean
    disabled?: boolean
}

export function RightBar(props: Props) {
    const { children, disabled } = props

    return (
        <div className={disabled ? 'disabled' : undefined} style={{height: '100%', overflowY: 'scroll'}}>

            {children}

        </div>

    )
}