import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { ListItemCA } from "./item/item"

export function CartellaAziendaleHistoryList() {
    const dispatch = useAppDispatch()
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)

    return (
        <div>
            {
                cartellaAziendaleState.cartelleAziendali.map(ca => (
                    <div className="pb-3">
                        <ListItemCA
                            historyView={true}
                            code={ca.code}
                            id={ca.id || ''}
                            title={ca.attachmentName}
                            numOfDetails={cartellaAziendaleState.historyCFDetailsUpdateRequest.filter(det => det.entryProtocolAttachmentId === ca.id).length}
                            protocolIds={cartellaAziendaleState.historyCFDetailsUpdateRequest.filter(det => det.entryProtocolAttachmentId === ca.id).map(d => d.id)}
                        />
                    </div>
                ))
            }
        </div>
        
    )
}