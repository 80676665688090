import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { ReportingUpdate, ReportingValidation, resetReportingUpdateStatus, resetReportingValidationStatus } from "../slice"
import { AddReportingForms } from "../addReporting/forms"
import { useEffect, useState } from "react"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { AddRightReporting } from "../addReporting/right"
import { EditReportingForms } from "./forms"
import { EditRightReporting } from "./right"

export function EditReporting() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const reportingState = useAppSelector(state => state.reporting)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(reportingState.reportingUpdateStatus === 'successfully') {
            dispatch(resetReportingUpdateStatus())
            setShowSuccess(true)
        }
    }, [reportingState.reportingUpdateStatus])

    useEffect(() => {
        if(reportingState.reportingValidationStatus === 'successfully') {
            dispatch(resetReportingValidationStatus())
            dispatch(ReportingUpdate({body: reportingState.reportingUpdateRequest, id: reportingState.reportingIdToUpdate}))
        }
    }, [reportingState.reportingValidationStatus])

    useEffect(() => {
        if(reportingState.reportingStateCreationStatus === 'successfully' ||
            reportingState.reportingStateDeleteStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [reportingState.reportingStateCreationStatus,
        reportingState.reportingStateDeleteStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'Modifica segnalazione']}
                noPaddingLat
                headerLabel="Segnalazioni"
                headerIcon={
                    <button style={{paddingTop: '12px'}} onClick={() => navigate('/segnalazioni')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => dispatch(ReportingValidation(reportingState.reportingUpdateRequest))}
                        />

                    </div>
                }
            >
                <div style={{display: 'flex', minHeight: window.innerHeight}}>
                    <div style={{padding: '24px', flex: '0.6'}}>

                        {
                            showSuccess &&
                            <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                                <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                    Dati salvati correttamente.
                                </span>
                            </div>
                        }

                        <div>
                            <EditReportingForms />
                        </div>
                    </div>
                    <div className="bg-brandPrimary-100" style={{flex: '0.4'}}>
                        <RightBar>
                            <EditRightReporting />
                        </RightBar>
                    </div>
                </div>
            </Layout>
    )
}