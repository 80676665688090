import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Calendar } from "../../../../ui/molecules/calendar"
import Input from "../../../../ui/molecules/input"
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch"
import { SelectCustom } from "../../../../ui/molecules/select"
import TextArea from "../../../../ui/molecules/textArea"
import { ReportingStatusOption, ReportingTypologyOption } from "../../../../utils"
import { setUpdateReportingCustomerOtherODC, setUpdateReportingDetails, setUpdateReportingEntryProtocolId, setUpdateReportingNote, setUpdateReportingReportingDate, setUpdateReportingReportingState, setUpdateReportingReportingType, setUpdateReportingSender } from "../slice"

export function EditReportingForms() {
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)

    return (
        <div>

            {/* HEADER */}
            <div className="reporting-header">
                <span className='title'>Modifica segnalazione</span>
            </div>

            {/* CONTENT */}
            <div className='reporting-content'>

                {/* FIRST ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data</span>
                            <Calendar
                                errorLabel={reportingState.reportingErrors.reportingDate ? 'Data mancante' : undefined}
                                selected={reportingState.reportingUpdateRequest.reportingDate}
                                onChange={(date) => dispatch(setUpdateReportingReportingDate(date))}
                            />
                        </div>
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mittente</span>
                        </div>
                        <Input
                            error={reportingState.reportingErrors.sender}
                            supportingText={reportingState.reportingErrors.sender ? 'Mittente mancante' : undefined}
                            value={reportingState.reportingUpdateRequest.sender}
                            placeholder="Specifica mittente..."
                            onChangeText={(text) => dispatch(setUpdateReportingSender(text))}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Cliente Altro ODC</span>
                        </div>
                        <Input
                            value={reportingState.reportingUpdateRequest.customerOtherODC}
                            placeholder="Specifica cliente..."
                            onChangeText={(text) => dispatch(setUpdateReportingCustomerOtherODC(text))}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Tipologia</span>
                        </div>
                        <SelectCustom
                            error={reportingState.reportingErrors.reportingType}
                            errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            value={reportingState.reportingUpdateRequest.reportingType}
                            options={ReportingTypologyOption}
                            onChange={(value) => dispatch(setUpdateReportingReportingType(value))}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Stato</span>
                        </div>
                        <SelectCustom
                            error={reportingState.reportingErrors.reportingState}
                            errorLabel='Stato mancante'
                            placeholder={'Seleziona stato...'}
                            value={reportingState.reportingUpdateRequest.reportingState}
                            options={ReportingStatusOption}
                            onChange={(value) => dispatch(setUpdateReportingReportingState(value))}
                        />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className='pb-5 pt-5'>
                    <ProtocolSearch
                        errorLabel={reportingState.reportingErrors.entryProtocolId ? 'Protocollo mancante' : undefined}
                        type='entry'
                        defaultValue={reportingState.reportingUpdateRequest.entryProtocolId}
                        onSelect={(value) => dispatch(setUpdateReportingEntryProtocolId(value))} 
                        />
                </div>

                {/* FORTH ROW */}
                <div className='text-left'>
                    <span className='input-label'>Dettagli della segnalazione </span>
                </div>
                <div className='pb-5'>
                    <TextArea
                        error={reportingState.reportingErrors.details}
                        supportingText={reportingState.reportingErrors.details ? 'Dettagli mancanti' : undefined}
                        value={reportingState.reportingUpdateRequest.details}
                        placeholder="Specificare i dettagli della segnalazione..."
                        onChangeText={(text) => dispatch(setUpdateReportingDetails(text))}
                    />
                </div>

                {/* FIFTH ROW */}
                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        value={reportingState.reportingUpdateRequest.note}
                        onChangeText={(text) => dispatch(setUpdateReportingNote(text))}
                        />
                </div>
                
            </div>
        </div>
    )
}