import { useNavigate } from "react-router-dom"
import { EditIcon } from "../../../ui/icons/edit"
import { formatterDate, optionsFormatter, ReportingTypologyOption } from "../../../utils"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { GetAllRID, GetRIDBYId, resetGetAllRIDStatus, resetRIDDeleteStatus, RIDDelete, setRIDIdToUpdate, setUpdateRIDAttachmentIds, setUpdateRIDCertificateRequestProtocolId, setUpdateRIDClosedNote, setUpdateRIDClosingProtocolIds, setUpdateRIDDeadlineRequestedDocument, setUpdateRIDDepartureProtocolId, setUpdateRIDDocumentalControlProtocolId, setUpdateRIDDocumentNotes, setUpdateRIDNote, setUpdateRIDObject, setUpdateRIDOther, setUpdateRIDReportingId } from "./slice"
import { RequestStateDTO, RIDEntityDTO } from "./dataService/dto"
import { TrashIcon } from "../../../ui/icons/trash"
import { Alert } from "../../../ui/organisms/alert"
import Button from "../../../ui/molecules/button"

export function RIDList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const ridState = useAppSelector(state => state.rid)
    const sessionState = useAppSelector(state => state.session)
    const userState = useAppSelector(state => state.users)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [ridToDelete, setRidToDelete] = useState<string | null>(null)

    useEffect(() => {
        dispatch(GetAllRID({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
    }, [])

    useEffect(() => {
        if(ridState.getAllRidStatus === 'successfully' &&
            ridState.getAllRidResponse !== undefined
        ) {
            dispatch(resetGetAllRIDStatus())
        }
    }, [ridState.getAllRidStatus])

    useEffect(() => {
        if(ridState.ridDeleteStatus === 'successfully') {
            dispatch(resetRIDDeleteStatus())
            dispatch(GetAllRID({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
        }
    }, [ridState.ridDeleteStatus])

    const handleOnEdit = (rid: RIDEntityDTO) => {
        dispatch(setRIDIdToUpdate(rid.id))
        dispatch(setUpdateRIDDepartureProtocolId(rid.departureProtocolId))
        dispatch(setUpdateRIDObject(rid.object))
        dispatch(setUpdateRIDNote(rid.note))
        dispatch(setUpdateRIDDeadlineRequestedDocument(rid.deadlineRequestedDocument))
        dispatch(setUpdateRIDDocumentalControlProtocolId(rid.documentalControlProtocolId))
        dispatch(setUpdateRIDCertificateRequestProtocolId(rid.certificateRequestProtocolId))
        dispatch(setUpdateRIDOther(rid.other))
        dispatch(setUpdateRIDReportingId(rid.reportingId))
        dispatch(setUpdateRIDAttachmentIds(rid.attachmentIds))
        dispatch(setUpdateRIDClosingProtocolIds(rid.closingProtocolIds))
        dispatch(setUpdateRIDClosedNote(rid.closedNote))
        dispatch(setUpdateRIDDocumentNotes(rid.documentNotes))

        dispatch(GetRIDBYId(rid.id))

        navigate('/editRID')
    }

    const showMotivations = (rid: RIDEntityDTO) => {
        const motivations: string[] = []
        if(rid.documentalControlProtocolInfo !== null)
            motivations.push(`Controllo documentale: ${rid.documentalControlProtocolInfo}`)
        if(rid.certificateRequestProtocolInfo !== null)
            motivations.push(`Richiesta certificato: ${rid.certificateRequestProtocolInfo}`)
        if(rid.reportingInfo !== null)
            motivations.push(`Segnalazione: ${optionsFormatter(rid.reportingInfo, ReportingTypologyOption)}`)
        if(rid.other !== null)
            motivations.push(`Altro: ${rid.other}`)

        return (
            <ul style={{listStyleType: 'disc'}}>{motivations.map(m => (<li>{m}</li>))}</ul>
        )
    }

    return (
        <div>
        <table id="users-table" className="w-[100%]">
            <thead className="h-[52px] uppercase">
                <tr>
                    <th style={{ borderTopLeftRadius: '8px' }}>Data</th>
                    <th>Protocollo partenza</th>
                    <th>Motivazione</th>
                    <th>Stato</th>
                    <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    ridState.getAllRidResponse?.data.map((rid, index) =>
                        <tr
                            key={index}
                            style={{
                                color: '#4D797F',
                                background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                            }}
                        >
                            <td>{formatterDate(rid.date)}</td>
                            <td>{rid.departureProtocolInfo}</td>
                            <td className="w-[30%]">{showMotivations(rid)}</td>
                            <td className="w-[30%]" style={{color: rid.requestState === 'APERTA' ? '#FF941A' : '#26AB45'}}>{rid.requestState}</td>
                            <td>
                                <div style={{display: 'flex', gap: '6px'}}>
                                    <button onClick={() => handleOnEdit(rid)}>
                                        <EditIcon color="orange" size={20} />
                                    </button>
                                    {
                                        userState.findMeResponse.role === 'admin' ? (
                                            <button onClick={() => {
                                                setRidToDelete(rid.id)
                                                setShowAlert(true)
                                            }}>
                                                <TrashIcon color="red" size={20} />
                                            </button>
                                        ) : rid.requestState === RequestStateDTO.APERTA ? (
                                            <button onClick={() => {
                                                setRidToDelete(rid.id)
                                                setShowAlert(true)
                                            }}>
                                                <TrashIcon color="red" size={20} />
                                            </button>
                                        ) : <></>
                                    }
                                </div>
                            </td>
                        </tr>
                    )}
            </tbody>
        </table>
        {
            (showAlert && ridToDelete) && (
                <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="pb-3">
                            <div>
                                Sei sicuro di voler eliminare il R.I.D.?
                            </div>
                        </div>
                        <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                variant="outline"
                                color="dimgrey"
                                iconPosition="off"
                                label="Annulla"
                                size="md"
                                onClick={() => setShowAlert(false)}
                            />
                            <div className="pl-8">
                                <Button
                                    variant="solid"
                                    color="orange"
                                    iconPosition="off"
                                    label="Elimina"
                                    size="md"
                                    onClick={() => {
                                        dispatch(RIDDelete(ridToDelete))
                                        setShowAlert(false)
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </Alert>
            )
        }
        </div>
    )
}