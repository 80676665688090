import { MenuItems } from "../../ui/organisms/navbar/dto";
import { Layout } from "../../ui/layout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { GetContactsById, GetCustomersById, resetCustomerByIdStatus, resetIdsCorporateCenterResponse } from "../clienti/slice";
import { Banner } from "../../ui/organisms/banner";
import { contactsByIdResponseDTO, getCustomersByIdResponseDTO } from "../clienti/dto";
import { ProfileBox } from "./components/profileBox";
import { CertificationBox } from "./components/certificationBox";
import { ContactsBox } from "./components/contactsBox";
import { findUserById, resetGetUserByIdStatusIdle } from "../users/slice";

export function DashboardView() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const customerState = useAppSelector(state => state.clienti)
    const [customer, setCustomer] = useState<getCustomersByIdResponseDTO | null>(null)
    const [contacts, setContacts] = useState<contactsByIdResponseDTO | null>(null)
    const usersState = useAppSelector(state => state.users)

    useEffect(() => {
        dispatch(GetCustomersById(sessionState.selected.customerId!))
    }, [])

    useEffect(() => {
        if (customerState.getCustomersByIdResponseStatus === 'successfully' &&
            customerState.getCustomersByIdResponse !== undefined
        ) {
            dispatch(resetCustomerByIdStatus('idle'))
            setCustomer(customerState.getCustomersByIdResponse)
            if (customerState.getCustomersByIdResponse) {
                dispatch(findUserById(sessionState.selected.customerUserId!))
            }
        }
    }, [customerState.getCustomersByIdResponseStatus])

    useEffect(() => {
        if (usersState.findUserByIdStatus === 'successfully') {
            dispatch(resetIdsCorporateCenterResponse())
            if (usersState.findUserByIdResponse !== undefined) {
                if (usersState.findUserByIdResponse.contactId !== undefined) {
                    dispatch(GetContactsById(usersState.findUserByIdResponse.contactId))
                }
            }
            dispatch(resetGetUserByIdStatusIdle())
        }
    }, [usersState.findUserByIdStatus])

    useEffect(() => {
        if (customerState.GetContactsByIdResponseStatus === 'successfully') {
            if (customerState.GetContactsByIdResponse !== undefined) {
               setContacts(customerState.GetContactsByIdResponse)
            }
        }
    }, [customerState.GetContactsByIdResponseStatus]);

    if (!sessionState.selected.customerId || !customer) {
        return (
            <Layout menuItem={MenuItems.DASHBOARD} breadcrumbItems={['Dashboard']} headerLabel="Dashboard" noPaddingLat>
                <Banner
                    type="error"
                    visible={!sessionState.selected.customerId}
                    label={"Devi prima selezionare un cliente per accedere all'area"}
                    closeAction={function (): void {
                        alert("Devi prima selezionare un cliente per accedere all'area")
                    }}
                />
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.DASHBOARD} breadcrumbItems={['Dashboard']} headerLabel={customer.companyName}>
            <div style={{ padding: '24px 0px' }}>
                <div style={{ display: 'flex', gap: '12px' }}>
                    <div style={{ flex: '0.3' }}>
                        <ProfileBox
                            companyName={customer.companyName}
                            CUAA={customer.cuaa}
                            region={customer.countryRegion}
                            pIva={customer.vat}
                            formaGiuridica={customer.legalForm || 'Non definita'}
                            sedeLegale={`${customer.address} - ${customer.zipCode} - ${customer.city}(${customer.province})`}
                            recapitoPostale={`${customer.address} - ${customer.zipCode} - ${customer.city}(${customer.province})`}
                        />
                    </div>
                    <div style={{ flex: '0.7' }}>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div style={{ flex: '0.33' }}>
                                <CertificationBox
                                    customer={customer}
                                />
                            </div>
                            {/* <div style={{flex: '0.33'}}>
                                <CertificationBox />
                            </div>
                            <div style={{flex: '0.33'}}>
                                <CertificationBox />
                            </div> */}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '12px' }} className="mt-6">
                    <div style={{ flex: '0.3' }}>
                        <ContactsBox
                            contacts={contacts}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}