import { Layout } from "../../ui/layout";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { RightBar } from "../../ui/organisms/rightBar";
import { TypeRegisterList } from "./list";
import { TypeRegisterRight } from "./right";

export function GestioneTipologiaRegistri() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', "Gestione Tipologia Registri"]}
            noPaddingLat
            headerLabel="Gestione Tipologia Registri"
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <TypeRegisterList />
                
                <div className="bg-brandPrimary-100" style={{ flex: 0.3, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <TypeRegisterRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}