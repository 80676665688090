import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { GetAllReportingStates, ReportingStateCreation, ReportingStateDelete, ReportingStateValidation, resetGetAllReportingStateResponse, resetReportingStateCreationRequest, resetReportingStateCreationStatus, resetReportingStateDeleteStatus, resetReportingStateErrors, resetReportingStateValidationStatus, setReportingStateDepartureProtocolId, setReportingStateEntryProtocolId, setReportingStateNote, setReportingStateReportingOuterState } from "../slice"
import { SelectCustom } from "../../../../ui/molecules/select"
import { optionsFormatter, ReportingStateStatusOption } from "../../../../utils"
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch"
import TextArea from "../../../../ui/molecules/textArea"
import Button from "../../../../ui/molecules/button"
import { TrashIcon } from "../../../../ui/icons/trash"
import { Alert } from "../../../../ui/organisms/alert"

export function EditRightReporting() {
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [repStateToDelete, setRepStateToDelete] = useState<string | null>(null)

    useEffect(() => {
        if(reportingState.reportingStateCreationStatus === 'successfully') {
            dispatch(resetReportingStateCreationStatus())
            dispatch(resetReportingStateCreationRequest())
            dispatch(resetReportingStateErrors())
            dispatch(GetAllReportingStates({itemsPerPage: 0, page: 0, reportingId: reportingState.reportingIdToUpdate}))            
        }
    }, [reportingState.reportingStateCreationStatus])

    useEffect(() => {
        if(reportingState.reportingStateDeleteStatus === 'successfully') {
            dispatch(resetReportingStateDeleteStatus())
            dispatch(GetAllReportingStates({itemsPerPage: 0, page: 0, reportingId: reportingState.reportingIdToUpdate})) 
        }
    }, [reportingState.reportingStateDeleteStatus])

    useEffect(() => {
        if(reportingState.reportingStateValidationStatus === 'successfully') {
            dispatch(resetReportingStateValidationStatus())
            dispatch(ReportingStateCreation(reportingState.reportingStateCreationRequest))
        }
    }, [reportingState.reportingStateValidationStatus])

    useEffect(() => {
        dispatch(resetGetAllReportingStateResponse())
        dispatch(GetAllReportingStates({itemsPerPage: 0, page: 0, reportingId: reportingState.reportingIdToUpdate})) 
    }, [])

    return (
        <div>

            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Stato </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Creazione</span>
            </div>

            <div style={{padding: '0px 24px 12px 24px', borderBottomWidth: 1}}>
                <div className="pb-3">
                    <SelectCustom
                        error={reportingState.reportingStateErrors.reportingOuterState}
                        errorLabel="Stato mancante"
                        key={reportingState.reportingStateCreationRequest.reportingOuterState || 'default'}
                        placeholder={'Seleziona stato...'}
                        options={ReportingStateStatusOption}
                        onChange={(value) => dispatch(setReportingStateReportingOuterState(value))}
                        value={reportingState.reportingStateCreationRequest.reportingOuterState}
                    />
                </div>

                <div className='pb-3'>
                    <ProtocolSearch
                        errorLabel={reportingState.reportingStateErrors.entryProtocolId ? 'Protocollo mancante' : undefined}
                        value={reportingState.reportingStateCreationRequest.entryProtocolId}
                        type='entry'
                        defaultValue=""
                        onSelect={(value) => dispatch(setReportingStateEntryProtocolId(value))} 
                        />
                </div>

                <div className='pb-3'>
                    <ProtocolSearch
                        errorLabel={reportingState.reportingStateErrors.departureProtocolId ? 'Protocollo mancante' : undefined}
                        value={reportingState.reportingStateCreationRequest.departureProtocolId}
                        type='departure'
                        onSelect={(value) => dispatch(setReportingStateDepartureProtocolId(value))} 
                        />
                </div>

                <div className='pb-3'>
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        value={reportingState.reportingStateCreationRequest.note}
                        onChangeText={(text) => dispatch(setReportingStateNote(text))}
                        />
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        iconPosition="off"
                        variant="solid"
                        color="orange"
                        label="Salva"
                        size="sm"
                        onClick={() => dispatch(ReportingStateValidation(reportingState.reportingStateCreationRequest))}
                        />
                </div>
            </div>

            <div className='text-left' style={{ padding: '12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Stato </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Riepilogo</span>
            </div>

            {/* REPLACE WITH LIST */}
            {
                reportingState.getAllReportingStatesResponse?.data.map(rs => (
                    <div style={{padding: '12px 24px'}}>
                        <div className="state-box" style={{display: 'flex'}}>
                            <div style={{flex: '1'}}>
                                <div className="text-left">
                                    <span className="state-main">{optionsFormatter(rs.reportingOuterState, ReportingStateStatusOption)}</span>
                                </div>
                                <div className="text-left">
                                    <span className="state-sub">{`${rs.entryProtocolInfo} - ${rs.departureProtocolInfo}`}</span>
                                </div>
                                <div className="text-left">
                                    <span className="state-note">{rs.note}</span>
                                </div>
                            </div>
                            <div style={{flex: '0.05', display: 'flex', alignItems: 'center'}}>
                                <button style={{padding: '12px', border: '1px solid #f2f2f2', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                 onClick={() => {
                                    setRepStateToDelete(rs.id)
                                    setShowAlert(true)
                                 }}>
                                    <TrashIcon size={20} color="white" />
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }

            {
                (showAlert && repStateToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare la segnalazione?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(ReportingStateDelete(repStateToDelete))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }

        </div>
    )
}