import { createSlice } from "@reduxjs/toolkit"
import { EntryProtocolDTO, GetAllEntryProtocolsDTO } from "../protocol/entry/dataService/dto"
import { DepartureProtocolDTO } from "../protocol/departure/dataService/dto"

interface DocumentManagementState {
    selectedTab: number,
    entryResponseSelected?: EntryProtocolDTO
    departureResponseSelected?: DepartureProtocolDTO
}

const initialState: DocumentManagementState = {
    selectedTab: 0
}

const DocumentManagementSlice = createSlice({
    name: 'documentManagement/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setEntryResponseSelected: (state, action) => {
            state.entryResponseSelected = action.payload
        },
        setDepartureResponseSelected: (state, action) => {
            state.departureResponseSelected = action.payload
        }
    }
})

export const {
    setSelectedTab,
    setEntryResponseSelected,
    setDepartureResponseSelected
} = DocumentManagementSlice.actions

export default DocumentManagementSlice.reducer