import { ReactNode } from "react"
import { ReportingInnerState } from "./dataService/dto"
import { optionsFormatter, ReportingStatusOption } from "../../../utils"

export const handleState = (state: ReportingInnerState): ReactNode => {
    const formattedState = optionsFormatter(state, ReportingStatusOption)
    return state === ReportingInnerState.APERTA ?
        <div className="innerstate-box" style={{backgroundColor: '#3AD45E'}}>
            <span className="text">{formattedState}</span>
        </div> :
        state === ReportingInnerState.IN_GESTIONE ?
        <div className="innerstate-box bg-warning-500">
            <span className="text">{formattedState}</span>
        </div> :
        <div className="innerstate-box" style={{backgroundColor: '#FF443D'}}>
            <span className="text">{formattedState}</span>
        </div> 
}