import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { PromiseStatuses } from "../../../lib/utils"
import { AnagraphicByIdDTO, AnagraphicCreationDTO } from "./dto";
import { NewAnagraphicService } from "./service";

interface re10AnagraficaState {
    selectedTab: number,
    createAnagraphicRequest: AnagraphicCreationDTO,
    getByIdAnagraphicRequest: AnagraphicByIdDTO,
    getByIdPMinstance: AnagraphicByIdDTO[]
    anagraphicErrors: {
        numScheda: boolean,
        identifierCode: boolean,
        name: boolean,
        surname: boolean,
        vat: boolean,
        sex: boolean,
        pec: boolean,
        firstEmail: boolean,
        firstPhone: boolean,
        fiscalCode: boolean,
        fiscalRegime: boolean,
        starting: boolean,
        birth: boolean,
        birthRegion: boolean,
        birthProvince: boolean,
        birthCity: boolean,
        errorLabels: string[]
    }
    AnagraphicCreationStatus: PromiseStatuses
    AnagraphicEditStatus: PromiseStatuses
    GetByIdAnagraphicStatus: PromiseStatuses
    validateAnagraphicCreationStatus: PromiseStatuses
}

const initialState: re10AnagraficaState = {
    selectedTab: 0,

    // REQUESTS
    getByIdPMinstance: [],
    getByIdAnagraphicRequest: {
        id: "",
        numScheda: "",
        identifierCode: "",
        surname: "",
        name: "",
        sex: "",
        birth: undefined,
        birthRegion: "",
        birthProvince: "",
        birthCity: "",
        externalNation: "",
        externalProvince: "",
        externalCity: "",
        address: "",
        region: "",
        province: "",
        city: "",
        cap: "",
        firstEmail: "",
        secondEmail: "",
        pec: "",
        firstHouse: "",
        secondHouse: "",
        firstOffice: "",
        secondOffice: "",
        firstFax: "",
        secondFax: "",
        firstPhone: "",
        secondPhone: "",
        fiscalCode: "",
        vat: "",
        sdi: "",
        fiscalRegime: 0,
        note: "",
        starting: new Date(),
        professionalRegister: "",
        cityRegister: "",
        numRegister: "",
        personalManagementId: '',
        active: true
    },
    createAnagraphicRequest: {
        numScheda: "",
        surname: "",
        name: "",
        sex: "",
        birth: undefined,
        birthRegion: "",
        birthProvince: "",
        birthCity: "",
        firstEmail: "",
        pec: "",
        firstPhone: "",
        fiscalCode: "",
        fiscalRegime: '',
        starting: new Date(),
        personalManagementId: '',
        active: true
    },

    // ERRORS
    anagraphicErrors: {
        numScheda: false,
        identifierCode: false,
        name: false,
        surname: false,
        vat: false,
        sex: false,
        pec: false,
        firstEmail: false,
        fiscalRegime: false,
        starting: false,
        fiscalCode: false,
        birth: false,
        birthRegion: false,
        birthProvince: false,
        birthCity: false,
        firstPhone: false,
        errorLabels: []
    },

    // STATUSES
    validateAnagraphicCreationStatus: 'idle',
    AnagraphicEditStatus: 'idle',
    GetByIdAnagraphicStatus: 'idle',
    AnagraphicCreationStatus: 'idle'

}

export const AnagraphicCreate = createAsyncThunk(
    'PM/Anagraphic/AnagraphicCreate',
    async (request: AnagraphicCreationDTO, thunkApi): Promise<void> => {
        const anagraphicService = NewAnagraphicService()

        return anagraphicService.CreateAnagraphic(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AnagraphicEdit = createAsyncThunk(
    'PM/Anagraphic/AnagraphicEdit',
    async (request: { data: AnagraphicCreationDTO, id: string }, thunkApi): Promise<void> => {
        const anagraphicService = NewAnagraphicService()

        return anagraphicService.AnagraphicEdit(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetByIdAnagraphic = createAsyncThunk(
    'PM/GetByIdAnagraphic',
    async (id: string, thunkApi): Promise<AnagraphicByIdDTO> => {
        const anagraphicService = NewAnagraphicService()

        return anagraphicService.GetByIdAnagraphic(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const validateAnagraphicCreation = createAsyncThunk(
    'PM/validateAnagraphicCreation',
    async (request: AnagraphicCreationDTO, thunkApi): Promise<void> => {
        let isValid = true;
        thunkApi.dispatch(resetAnagraphicErrors())

        if (request.name === '') {
            thunkApi.dispatch(setValidateName(true))
            thunkApi.dispatch(addErrorLabel('Nome*'))
            isValid = false
        }
        if (request.surname === '') {
            thunkApi.dispatch(setValidateSurname(true))
            thunkApi.dispatch(addErrorLabel('Cognome*'))
            isValid = false
        }
        if (request.birthCity === '') {
            thunkApi.dispatch(setValidateBirthCity(true))
            thunkApi.dispatch(addErrorLabel('Città di nascita*'))
            isValid = false
        }
        if (request.birthProvince === '') {
            thunkApi.dispatch(setValidateBirthProvince(true))
            thunkApi.dispatch(addErrorLabel('Provincia di nascita*'))
            isValid = false
        }
        if (request.birthRegion === '') {
            thunkApi.dispatch(setValidateBirthRegion(true))
            thunkApi.dispatch(addErrorLabel('Regione di nascita*'))
            isValid = false
        }
        if (request.numScheda === '') {
            thunkApi.dispatch(setValidateNumScheda(true))
            thunkApi.dispatch(addErrorLabel('Numero scheda*'))
            isValid = false
        }

        if (request.firstEmail === '') {
            thunkApi.dispatch(setValidateFirstEmail(true))
            thunkApi.dispatch(addErrorLabel('Email 1*'))
            isValid = false
        }

        if (request.firstPhone === '') {
            thunkApi.dispatch(setValidateFirstPhone(true))
            thunkApi.dispatch(addErrorLabel('N. di cellulare 1*'))
            isValid = false
        }

        if (request.fiscalCode === '') {
            thunkApi.dispatch(setValidateFiscalCode(true))
            thunkApi.dispatch(addErrorLabel('Codice fiscale*'))
            isValid = false
        }

        if (request.fiscalRegime === '') {
            thunkApi.dispatch(setValidateFiscalRegime(true))
            thunkApi.dispatch(addErrorLabel('Regime fiscale*'))
            isValid = false
        }

        if (request.pec === '') {
            thunkApi.dispatch(setValidatePec(true))
            thunkApi.dispatch(addErrorLabel('Pec*'))
            isValid = false
        }

        if (request.sex === '') {
            thunkApi.dispatch(setValidateSex(true))
            thunkApi.dispatch(addErrorLabel('Sesso*'))
            isValid = false
        }

        if (!request.birth) {
            thunkApi.dispatch(setValidateBirth(true))
            thunkApi.dispatch(addErrorLabel('Data di nascita*'))
            isValid = false
        }

        if (!request.starting) {
            thunkApi.dispatch(setValidateStarting(true))
            thunkApi.dispatch(addErrorLabel('Data ingresso*'))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
    }
)

const Re10AnagraficaSlice = createSlice({
    name: 're10Anagrafica/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },

        // CREATE ANAGRAPHIC
        setNumScheda: (state, action) => {
            state.createAnagraphicRequest.numScheda = action.payload
        },
        setIdCode: (state, action) => {
            state.createAnagraphicRequest.identifierCode = action.payload
        },
        setSurname: (state, action) => {
            state.createAnagraphicRequest.surname = action.payload
        },
        setName: (state, action) => {
            state.createAnagraphicRequest.name = action.payload
        },
        setSex: (state, action) => {
            state.createAnagraphicRequest.sex = action.payload
        },
        setBirthDate: (state, action) => {
            state.createAnagraphicRequest.birth = action.payload
        },
        setBirthRegion: (state, action) => {
            state.createAnagraphicRequest.birthRegion = action.payload
        },
        setBirthProvince: (state, action) => {
            state.createAnagraphicRequest.birthProvince = action.payload
        },
        setBirthCity: (state, action) => {
            state.createAnagraphicRequest.birthCity = action.payload
        },
        setExternalNation: (state, action) => {
            state.createAnagraphicRequest.externalNation = action.payload
        },
        setExternalProvince: (state, action) => {
            state.createAnagraphicRequest.externalProvince = action.payload
        },
        setExternalCity: (state, action) => {
            state.createAnagraphicRequest.externalCity = action.payload
        },
        setAddress: (state, action) => {
            state.createAnagraphicRequest.address = action.payload
        },
        setRegion: (state, action) => {
            state.createAnagraphicRequest.region = action.payload
        },
        setProvince: (state, action) => {
            state.createAnagraphicRequest.province = action.payload
        },
        setCity: (state, action) => {
            state.createAnagraphicRequest.city = action.payload
        },
        setCap: (state, action) => {
            state.createAnagraphicRequest.cap = action.payload
        },
        setFirstEmail: (state, action) => {
            state.createAnagraphicRequest.firstEmail = action.payload
        },
        setSecondEmail: (state, action) => {
            state.createAnagraphicRequest.secondEmail = action.payload
        },
        setPec: (state, action) => {
            state.createAnagraphicRequest.pec = action.payload
        },
        setFirstHouse: (state, action) => {
            state.createAnagraphicRequest.firstHouse = action.payload
        },
        setSecondHouse: (state, action) => {
            state.createAnagraphicRequest.secondHouse = action.payload
        },
        setFirstOffice: (state, action) => {
            state.createAnagraphicRequest.firstOffice = action.payload
        },
        setSecondOffice: (state, action) => {
            state.createAnagraphicRequest.secondOffice = action.payload
        },
        setFirstFax: (state, action) => {
            state.createAnagraphicRequest.firstFax = action.payload
        },
        setSecondFax: (state, action) => {
            state.createAnagraphicRequest.secondFax = action.payload
        },
        setFirstPhone: (state, action) => {
            state.createAnagraphicRequest.firstPhone = action.payload
        },
        setSecondPhone: (state, action) => {
            state.createAnagraphicRequest.secondPhone = action.payload
        },
        setFiscalCode: (state, action) => {
            state.createAnagraphicRequest.fiscalCode = action.payload
        },
        setVat: (state, action) => {
            state.createAnagraphicRequest.vat = action.payload
        },
        setSdi: (state, action) => {
            state.createAnagraphicRequest.sdi = action.payload
        },
        setFiscalRegime: (state, action) => {
            state.createAnagraphicRequest.fiscalRegime = action.payload
        },
        setStarting: (state, action) => {
            state.createAnagraphicRequest.starting = action.payload
        },
        setEnding: (state, action) => {
            state.createAnagraphicRequest.ending = action.payload
        },
        setNote: (state, action) => {
            state.createAnagraphicRequest.note = action.payload
        },
        setProfessionalRegister: (state, action) => {
            state.createAnagraphicRequest.professionalRegister = action.payload
        },
        setCityRegister: (state, action) => {
            state.createAnagraphicRequest.cityRegister = action.payload
        },
        setNumRegister: (state, action) => {
            state.createAnagraphicRequest.numRegister = action.payload
        },
        setActive: (state, action) => {
            state.createAnagraphicRequest.active = action.payload
        },
        setPMId: (state, action) => {
            state.createAnagraphicRequest.personalManagementId = action.payload
        },

        // ERRORS
        setValidateBirthRegion: (state, action) => {
            state.anagraphicErrors.birthRegion = action.payload
        },
        setValidateBirthProvince: (state, action) => {
            state.anagraphicErrors.birthProvince = action.payload
        },
        setValidateBirthCity: (state, action) => {
            state.anagraphicErrors.birthCity = action.payload
        },
        setValidateName: (state, action) => {
            state.anagraphicErrors.name = action.payload
        },
        setValidateSurname: (state, action) => {
            state.anagraphicErrors.surname = action.payload
        },
        setValidateNumScheda: (state, action) => {
            state.anagraphicErrors.numScheda = action.payload
        },
        setValidateFiscalCode: (state, action) => {
            state.anagraphicErrors.fiscalCode = action.payload
        },
        setValidateFirstEmail: (state, action) => {
            state.anagraphicErrors.firstEmail = action.payload
        },
        setValidateFiscalRegime: (state, action) => {
            state.anagraphicErrors.fiscalRegime = action.payload
        },
        setValidateStarting: (state, action) => {
            state.anagraphicErrors.starting = action.payload
        },
        setValidateSex: (state, action) => {
            state.anagraphicErrors.sex = action.payload
        },
        setValidatePec: (state, action) => {
            state.anagraphicErrors.pec = action.payload
        },
        setValidateFirstPhone: (state, action) => {
            state.anagraphicErrors.firstPhone = action.payload
        },
        addErrorLabel: (state, action) => {
            state.anagraphicErrors.errorLabels.push(action.payload)
        },
        setValidateBirth: (state, action) => {
            state.anagraphicErrors.birth = action.payload
        },
        resetAnagraphicErrors: (state) => {
            state.anagraphicErrors = {
                numScheda: false,
                identifierCode: false,
                name: false,
                surname: false,
                vat: false,
                sex: false,
                pec: false,
                firstEmail: false,
                firstPhone: false,
                fiscalRegime: false,
                starting: false,
                fiscalCode: false,
                birth: false,
                birthRegion: false,
                birthProvince: false,
                birthCity: false,
                errorLabels: []
            }
        },


        // STATUSES
        setValidateAnagraphicCreationStatus: (state, action) => {
            state.validateAnagraphicCreationStatus = action.payload
        },
        resetGetByIdStatus: (state, action) => {
            state.GetByIdAnagraphicStatus = action.payload
        },

        resetEditStatus: (state, action) => {
            state.AnagraphicEditStatus = action.payload
        },

        addPersonalManagementInstance: (state, action) => {
            state.getByIdPMinstance.push(action.payload)
        },
        resetPersonalManagementInstance: (state) => {
            state.getByIdPMinstance = []
        },
        AnagraphicCreationStatus: (state) => {
            state.AnagraphicCreationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder
            .addCase(AnagraphicCreate.pending, (state) => {
                state.AnagraphicCreationStatus = 'loading'
            })
            .addCase(AnagraphicCreate.fulfilled, (state, action) => {
                state.AnagraphicCreationStatus = 'successfully'
            })
            .addCase(AnagraphicCreate.rejected, (state) => {
                state.AnagraphicCreationStatus = 'failed'
            })

            .addCase(AnagraphicEdit.pending, (state) => {
                state.AnagraphicEditStatus = 'loading'
            })
            .addCase(AnagraphicEdit.fulfilled, (state, action) => {
                state.AnagraphicEditStatus = 'successfully'
            })
            .addCase(AnagraphicEdit.rejected, (state) => {
                state.AnagraphicEditStatus = 'failed'
            })

            .addCase(validateAnagraphicCreation.pending, (state) => {
                state.validateAnagraphicCreationStatus = 'loading'
            })
            .addCase(validateAnagraphicCreation.fulfilled, (state, action) => {
                state.validateAnagraphicCreationStatus = 'successfully'
            })
            .addCase(validateAnagraphicCreation.rejected, (state) => {
                state.validateAnagraphicCreationStatus = 'failed'
            })

            .addCase(GetByIdAnagraphic.pending, (state) => {
                state.GetByIdAnagraphicStatus = 'loading'
            })
            .addCase(GetByIdAnagraphic.fulfilled, (state, action) => {
                state.GetByIdAnagraphicStatus = 'successfully'
                state.getByIdAnagraphicRequest = action.payload
            })
            .addCase(GetByIdAnagraphic.rejected, (state) => {
                state.GetByIdAnagraphicStatus = 'failed'
            })

    },
})

export const {
    setSelectedTab,

    // CREATE ANAGRAPHIC
    setNumScheda,
    setIdCode,
    setSurname,
    setName,
    setSex,
    setBirthDate,
    setBirthRegion,
    setBirthProvince,
    setBirthCity,
    setExternalNation,
    setExternalProvince,
    setExternalCity,
    setAddress,
    setRegion,
    setProvince,
    setCity,
    setCap,
    setFirstEmail,
    setSecondEmail,
    setPec,
    setFirstHouse,
    setSecondHouse,
    setFirstOffice,
    setSecondOffice,
    setActive,
    setCityRegister,
    setEnding,
    setFirstFax,
    setFirstPhone,
    setFiscalCode,
    setFiscalRegime,
    setNote,
    setNumRegister,
    setProfessionalRegister,
    setSdi,
    setSecondFax,
    setSecondPhone,
    setStarting,
    setVat,
    setPMId,

    // ERRORS
    setValidateBirthCity,
    setValidateBirthProvince,
    setValidateBirthRegion,
    setValidateName,
    setValidateSurname,
    setValidateNumScheda,
    setValidateFirstEmail,
    setValidateFirstPhone,
    setValidateFiscalCode,
    setValidateFiscalRegime,
    setValidatePec,
    setValidateSex,
    setValidateBirth,
    setValidateStarting,
    addErrorLabel,
    resetAnagraphicErrors,

    addPersonalManagementInstance,
    resetPersonalManagementInstance,

    // STATUSES
    setValidateAnagraphicCreationStatus,
    resetGetByIdStatus,
    resetEditStatus,
    AnagraphicCreationStatus
} = Re10AnagraficaSlice.actions

export default Re10AnagraficaSlice.reducer