import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { TrashIcon } from "../../../../ui/icons/trash";
import { WordDocumentIcon } from "../../../../ui/icons/WordDocumentIcon";
import Button from "../../../../ui/molecules/button";
import { SelectCustom } from "../../../../ui/molecules/select";
import { OptionType } from "../../../../utils";
import { AddedRegisterCreation, AddedRegisterDelete, GetAllAddedRegisters, GetAllTypeRegisters, resetAddedRegisterCreationStatus, resetAddedRegisterDeleteStatus, setAddedRegisterCreationPageNumber, setAddedRegisterCreationTypeRegisterId } from "../slice";
import { Alert } from "../../../../ui/organisms/alert";

export function EditRegistroRight() {
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)
    const [typeRegisterOptions, setTypeRegisterOptions] = useState<OptionType[]>([])
    const [regToDelete, setRegToDelete] = useState<string | null>(null)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [addedRegisterTypeError, setAddedRegisterTypeError] = useState<boolean>(false)

    useEffect(() => {
        dispatch(GetAllTypeRegisters({ page: 0, itemsPerPage: 0 }))
        dispatch(GetAllAddedRegisters({ itemsPerPage: 0, page: 0, businessRegisterId: registerState.getAllAddedRegistersFilters.businessRegisterId }))
    }, [])

    useEffect(() => {
        if (registerState.getAllTypeRegistersStatus === 'successfully' &&
            registerState.getAllTypeRegistersResponse !== undefined
        ) {
            const data = registerState.getAllTypeRegistersResponse.data.map(tr => {
                return {
                    value: tr.id,
                    label: `${tr.name} [${tr.code}]`
                } as OptionType
            })
            setTypeRegisterOptions(data)
        }
    }, [registerState.getAllTypeRegistersStatus])

    useEffect(() => {
        if (registerState.addedRegisterCreationStatus === 'successfully') {
            dispatch(resetAddedRegisterCreationStatus())
            dispatch(setAddedRegisterCreationTypeRegisterId(''))
            dispatch(setAddedRegisterCreationPageNumber(10))
            dispatch(GetAllAddedRegisters({ itemsPerPage: 0, page: 0, businessRegisterId: registerState.getAllAddedRegistersFilters.businessRegisterId }))
        }
    }, [registerState.addedRegisterCreationStatus])

    useEffect(() => {
        if (registerState.addedRegisterDeleteStatus === 'successfully') {
            dispatch(resetAddedRegisterDeleteStatus())
            dispatch(GetAllAddedRegisters({ itemsPerPage: 0, page: 0, businessRegisterId: registerState.getAllAddedRegistersFilters.businessRegisterId }))
        }
    }, [registerState.addedRegisterDeleteStatus])

    return (
        <div>
            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Registro </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Creazione</span>
            </div>

            <div style={{ padding: '0px 24px 12px 24px', borderBottomWidth: 1 }}>
                <div className="pb-3">
                    <div className="text-left">
                        <span className="input-label">Tipo di registro</span>
                    </div>
                    <SelectCustom
                        error={addedRegisterTypeError}
                        errorLabel="Tipologia mancante"
                        key={registerState.addedRegisterCreationRequest.typeRegisterId || 'default'}
                        placeholder={'Seleziona tipologia...'}
                        options={typeRegisterOptions}
                        onChange={(value) => dispatch(setAddedRegisterCreationTypeRegisterId(value))}
                        value={registerState.addedRegisterCreationRequest.typeRegisterId}
                    />
                </div>

                <div className="pb-3">
                    <div className="text-left">
                        <span className="input-label">Numero di pagine</span>
                    </div>
                    <SelectCustom
                        key={registerState.addedRegisterCreationRequest.pageNumber || 'default'}
                        placeholder={'Seleziona numero di pagine...'}
                        options={Array.from({ length: 50 }, (_, index) => {
                            return { value: String(index + 1), label: String(index + 1) } as OptionType
                        })}
                        onChange={(value) => dispatch(setAddedRegisterCreationPageNumber(Number(value)))}
                        value={String(registerState.addedRegisterCreationRequest.pageNumber)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        iconPosition="off"
                        variant="solid"
                        color="orange"
                        label="Salva"
                        size="sm"
                        onClick={() => {
                            if(registerState.addedRegisterCreationRequest.typeRegisterId === "") {
                                setAddedRegisterTypeError(true)
                                return
                            }
                            setAddedRegisterTypeError(false)
                            dispatch(AddedRegisterCreation(registerState.addedRegisterCreationRequest))
                        }}
                    />
                </div>
            </div>

            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Registro </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Riepilogo</span>
            </div>

            <div style={{ padding: '12px 24px' }}>
            {
                    registerState.getAllAddedRegistersResponse?.data.map(ar => (
                        <div className="border-register-box mb-3">
                            <div className="register-box" style={{ display: 'flex' }}>
                                <div style={{ flex: '1' }}>
                                    <div className="text-left">
                                        <span className="state-main">{ar.progressive}</span>
                                    </div>
                                    <div className="text-left">
                                        <span className="state-sub">{`${ar.typeRegisterDTO.name} [${ar.typeRegisterDTO.code}] - ${ar.pageNumber} pagine`}</span>
                                    </div>
                                </div>
                                <div style={{ flex: '0.05', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <button style={{ padding: '6px', border: '1px solid #f2f2f2', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2' }}
                                        onClick={() => { }}>
                                        <WordDocumentIcon />
                                    </button>
                                    <button style={{ padding: '8px', border: '1px solid #f2f2f2', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => {
                                            setRegToDelete(ar.id)
                                            setShowAlert(true)
                                        }}>
                                        <TrashIcon size={20} color="white" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                (showAlert && regToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare il registro?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(AddedRegisterDelete(regToDelete))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}