import { GetAllReportingsDTO, GetAllReportingsFiltersDTO, GetAllReportingStateFIltersDTO, GetAllReportingStatesDTO, ReportingCreationDTO, ReportingDTO, ReportingStateCreationUpdateDTO, ReportingStateDTO, ReportingUpdateDTO } from "./dto";
import { ReportingServiceImpl } from "./serviceImpl";

export interface ReportingService {
    CreateReporting(data: ReportingCreationDTO): Promise<string>
    UpdateReporting(data: ReportingUpdateDTO, id: string): Promise<void>
    DeleteReporting(id: string): Promise<void>
    GetAllReportings(filters: GetAllReportingsFiltersDTO): Promise<GetAllReportingsDTO>
    GetReportingById(id: string): Promise<ReportingDTO>

    CreateReportingState(data: ReportingStateCreationUpdateDTO): Promise<void>
    UpdateReportingState(data: ReportingStateCreationUpdateDTO, id: string): Promise<void>
    DeleteReportingState(id: string): Promise<void>
    GetAllReportingStates(filters: GetAllReportingStateFIltersDTO): Promise<GetAllReportingStatesDTO>
    GetReportingStateById(id: string): Promise<ReportingStateDTO>
}

export function NewReportingService(): ReportingService {
    return new ReportingServiceImpl()
}