import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { ClientBanner } from "../../../ui/molecules/clientBanner";
import { Banner } from "../../../ui/organisms/banner";
import { attivita_bio, formatterDate, normativa, optionsFormatter, ReportingStatusOption, ReportingTypologyOption } from "../../../utils";
import { GetAllReportings, ReportingDelete, resetReportingDeleteStatus, setReportingIdToUpdate, setReportingReportingDate, setReportingStateReportingId, setUpdateReportingCustomerOtherODC, setUpdateReportingDetails, setUpdateReportingEntryProtocolId, setUpdateReportingNote, setUpdateReportingReportingDate, setUpdateReportingReportingState, setUpdateReportingReportingType, setUpdateReportingSender } from "./slice";
import { ReportingDTO, ReportingInnerState } from "./dataService/dto";
import { useNavigate } from "react-router-dom";
import { handleState } from "./utils";
import { Alert } from "../../../ui/organisms/alert";
import Button from "../../../ui/molecules/button";

export function CustomerList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const reportingState = useAppSelector(state => state.reporting)
    const sessionState = useAppSelector(state => state.session)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [repToDelete, setRepToDelete] = useState<string | null>(null)

    const handleOnEdit = (data: ReportingDTO) => {
        dispatch(setReportingIdToUpdate(data.id))
        dispatch(setReportingStateReportingId(data.id))
        dispatch(setUpdateReportingReportingDate(data.reportingDate))
        dispatch(setUpdateReportingSender(data.sender))
        dispatch(setUpdateReportingCustomerOtherODC(data.customerOtherODC))
        dispatch(setUpdateReportingEntryProtocolId(data.entryProtocolId))
        dispatch(setUpdateReportingReportingState(data.reportingState))
        dispatch(setUpdateReportingReportingType(data.reportingType))
        dispatch(setUpdateReportingDetails(data.details))
        dispatch(setUpdateReportingNote(data.note))

        navigate('/editSegnalazione')
    }

    useEffect(() => {
        dispatch(GetAllReportings({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
    }, [])

    useEffect(() => {
        if(reportingState.reportingDeleteStatus === 'successfully') {
            dispatch(resetReportingDeleteStatus())
            dispatch(GetAllReportings({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
        }
    }, [reportingState.reportingDeleteStatus])

    if(sessionState.selected.customerId) {
        return (
            <div style={{padding: '24px'}}>
                <div className="pb-5">
                    <ClientBanner
                        region={sessionState.selected.customerRegion!}
                        client={sessionState.selected.customerName!}
                        code={sessionState.selected.customerCode!}
                        activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                        norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                    />
                </div>
    
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}></th>
                            <th>Data segnalazione</th>
                            <th>Mittente</th>
                            <th>Cliente altro ODC</th>
                            <th>Protocollo entrata</th>
                            <th style={{textAlign: 'center'}}>Stato</th>
                            <th>Funzionario</th>
                            <th>Data</th>
                            <th>Tipo</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportingState.getAllReportingsResponse?.data.map((rep, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>
                                        <div style={{display: 'flex', gap: '6px'}}>
                                            <button onClick={() => handleOnEdit(rep)}>
                                                <EditIcon color="orange" size={20} />
                                            </button>
                                            <button onClick={() => {
                                                setRepToDelete(rep.id)
                                                setShowAlert(true)
                                            }}>
                                                <TrashIcon color="orange" size={20} />
                                            </button>
                                        </div>
                                    </td>
                                    <td>{formatterDate(rep.reportingDate)}</td>
                                    <td>{rep.sender}</td>
                                    <td>{rep.customerOtherODC}</td>
                                    <td>{rep.entryProtocolInfo}</td>
                                    <td style={{display: 'flex', justifyContent: 'center'}}>{handleState(rep.reportingState)}</td>
                                    <td>{rep.officerInfo}</td>
                                    <td>{formatterDate(rep.reportingDate)}</td>
                                    <td>{optionsFormatter(rep.reportingType, ReportingTypologyOption)}</td>
                                    <td></td>
                                </tr>
                            )}
                    </tbody>
                </table>
                {
                    (showAlert && repToDelete) && (
                        <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                <div className="pb-3">
                                    <div>
                                        Sei sicuro di voler eliminare la segnalazione?
                                    </div>
                                </div>
                                <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        variant="outline"
                                        color="dimgrey"
                                        iconPosition="off"
                                        label="Annulla"
                                        size="md"
                                        onClick={() => setShowAlert(false)}
                                    />
                                    <div className="pl-8">
                                        <Button
                                            variant="solid"
                                            color="orange"
                                            iconPosition="off"
                                            label="Elimina"
                                            size="md"
                                            onClick={() => {
                                                dispatch(ReportingDelete(repToDelete))
                                                setShowAlert(false)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Alert>
                    )
                }
            </div>
        )
    }
    
    return (
        <Banner
            type="error"
            visible={!sessionState.selected.customerId}
            label={"Devi prima selezionare un cliente per accedere all'area"}
            closeAction={function (): void {
                alert("Devi prima selezionare un cliente per accedere all'area")
            }}
        />
    )
}