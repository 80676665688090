import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { formatterDate, OptionType } from "../../../utils";
import { GetFileById, GetFileByIdToZip, resetGetFileByIdStatus, resetGetFileByIdToZipResponses, resetGetFileByIdToZipStatus, setGetFileByIdToZipRequest } from "../../../objectStorage/slice";
import { MagnifyingIcon } from "../../../ui/icons/magnifying";
import '../style.scss'
import Button from "../../../ui/molecules/button";

interface Props {
    type?: 'entry' | 'departure'
    close: () => void;
}

export function DetailsPopup(props: Props) {
    const navigate = useNavigate()
    const documentState = useAppSelector(state => state.documentManagement)
    const dispatch = useAppDispatch()

    return (
        <>
            <div className="mainBox">
                {props.type === 'entry' ?
                    (
                        <>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data:</span> {formatterDate(documentState.entryResponseSelected?.protocolDate)}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Anno:</span>{documentState.entryResponseSelected?.year}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Numero:</span> {documentState.entryResponseSelected?.protocolNumber}
                                </div>
                            </div>

                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Mittente:</span> {documentState.entryResponseSelected?.sender}
                                </div>
                            </div>

                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Cliente:</span>
                                    {documentState.entryResponseSelected?.customersInfo.map((customer, index, customers) =>
                                        <span>{customer.companyName}{index < customers.length - 1 && ', '}</span>
                                    )}
                                </div>
                            </div>

                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Nuovo Cliente:</span> {documentState.entryResponseSelected?.customersNotRegistered}
                                </div>
                            </div>

                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Oggetto:</span> {documentState.entryResponseSelected?.entryObject.description}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Nazione:</span> {documentState.entryResponseSelected?.nation}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Regione:</span> {documentState.entryResponseSelected?.region}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Pagine:</span> {documentState.entryResponseSelected?.totalPage}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Mezzo Entrata:</span> {documentState.entryResponseSelected?.entryChannel}
                                </div>
                            </div>

                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data Spedizione/Invio:</span> {formatterDate(documentState.entryResponseSelected?.send)}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Funzionario:</span> {documentState.entryResponseSelected?.user.name} {documentState.entryResponseSelected?.user.surname}
                                </div>
                            </div>

                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Uffici di destinazione:</span>
                                    {
                                        documentState.entryResponseSelected?.destinationOffice &&
                                        documentState.entryResponseSelected?.destinationOffice?.map((office, index, offices) =>
                                            <span>{office} {index < offices.length - 1 && ', '}</span>
                                        )}
                                </div>
                            </div>
                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Allegati:</span>
                                    {
                                        documentState.entryResponseSelected?.entryAttachments &&
                                        documentState.entryResponseSelected?.entryAttachments?.map((attachment, index, attachments) =>
                                            <span>{attachment.description} ({attachment.coding}) {index < attachments.length - 1 && ', '}</span>
                                        )}
                                </div>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data:</span> {formatterDate(documentState.departureResponseSelected?.protocolDate)}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Anno:</span>{documentState.departureResponseSelected?.year}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Numero:</span> {documentState.departureResponseSelected?.protocolNumber}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Destinatario:</span> {documentState.departureResponseSelected?.receiver}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Cliente:</span>
                                    {documentState.departureResponseSelected?.customersInfo.map((customer, index, customers) =>
                                        <span>{customer.companyName}{index < customers.length - 1 && ', '}</span>
                                    )}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Nuovo Cliente:</span> {documentState.departureResponseSelected?.customersNotRegistered}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Oggetto:</span> {documentState.departureResponseSelected?.departureObject.description}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Nazione:</span> {documentState.departureResponseSelected?.nation}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Regione:</span> {documentState.departureResponseSelected?.region}
                                </div>
                            </div>

                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Pagine:</span> {documentState.departureResponseSelected?.totalPage}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Mezzo Partenza:</span> {documentState.departureResponseSelected?.departureChannel}
                                </div>
                            </div>

                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Funzionario:</span> {documentState.departureResponseSelected?.user.name} {documentState.departureResponseSelected?.user.surname}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Numero Raccomandata:</span>{documentState.departureResponseSelected?.numeroRaccomandata}
                                </div>
                            </div>
                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Ricevuta a mezzo:</span> 
                                </div>
                            </div>
                            <div className="rowProtDetails separator pl-5 pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data Bolgetta:</span> {formatterDate(documentState.departureResponseSelected?.dataBolgetta)}
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data Ricezione Raccomandata:</span> {formatterDate(documentState.departureResponseSelected?.receive)}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Data Arrivo ricevuta:</span> 
                                </div>
                            </div>
                            <div className="rowProtDetails pl-5 separator pb-4 pt-4">
                                <div className="details">
                                    <span className="titleDetails">Data Ritorno:</span> {formatterDate(documentState.departureResponseSelected?.dataRicezioneCompiutaGiacenza)}
                                </div>
                                <div className="details">
                                    <span className="titleDetails">Numro Nuova Raccomandata:</span> {documentState.departureResponseSelected?.numeroRaccomandataErratoIndirizzo}
                                </div>
                            </div>


                        </>
                    )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Chiudi"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />

            </div>
        </>

    )
}