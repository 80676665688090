import axios from "axios";
import { GetAllRIDEntitiesDTO, GetAllRIDFiltersDTO, RIDCreationDTO, RIDEntityDTO, RIDUpdateDTO, RIDUpdateStateDTO } from "./dto";
import { RIDService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class RIDServiceImpl implements RIDService {

    public CreateRID(data: RIDCreationDTO): Promise<string> {
        return axios.post("/api/document-integration-request",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRID(data: RIDUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/document-integration-request/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRIDState(data: RIDUpdateStateDTO, id: string): Promise<void> {
        return axios.put("/api/document-integration-request/update-state/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteRID(id: string): Promise<void> {
        return axios.delete("/api/document-integration-request/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllRID(filters: GetAllRIDFiltersDTO): Promise<GetAllRIDEntitiesDTO> {
        return axios.get("/api/document-integration-request", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetRIDById(id: string): Promise<RIDEntityDTO> {
        return axios.get("/api/document-integration-request/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}