import JSZip from "jszip";
import { GetFileByIdDTO } from "./dataService/dto";
import { NewObjectStorageService } from "./dataService/service";

export const getMimeTypeFromBase64 = (file: string): string | undefined => {
    const removeData = file.split('data:')[1]
    return removeData.split(';')[0]
};

export const downloadZip = async (files: GetFileByIdDTO[], name?: string) => {
    const zip = new JSZip();
    const objectStorageService = NewObjectStorageService()

    for(let i=0; i<files.length; i++) {
        const fileName = files[i].name.replaceAll('/','_')
        const fileFromBase64 = objectStorageService.Base64ToFile(files[i].base64, files[i].data)
        zip.file(fileName+'.pdf', fileFromBase64)
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
        objectStorageService.Download(name ? name : 'archive', content)
    });
};