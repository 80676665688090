import axios from "axios";
import { WaiverFromCodexService } from "./service";
import { keycloak } from "../../../../../lib/keycloak";
import { GetAllWaiverToResponse } from "../../liberatorieToCodex/dataService/dto";
import { GetAllWaiverFromFiltersDTO, createWaiverFromDTO, createUpdateWaiverFromDTO, GetAllWaiverFromResponse } from "./dto";


export class WaiverFromCodexServiceImpl implements WaiverFromCodexService {

    public GetAllWaiverFromCodex(filters: GetAllWaiverFromFiltersDTO): Promise<GetAllWaiverFromResponse> {
        return axios.get("/api/waiver-from-codex", {
            params: {
                breakoutType: filters.status !== '' ? filters.status : undefined,
                itemsPerPage: filters.itemsPerPage,
                order: filters.order,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                year: filters.year !== '' ? filters.year : undefined
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET", 
            }
        }).then((response) => response.data)
    }

    public GetWaiverFromExcel(filters: GetAllWaiverFromFiltersDTO): Promise<any> {
        return axios.get("/api/download/waiver-from-codex-excel", {
            params: {
                breakoutType: filters.status !== '' ? filters.status : undefined,
                year: filters.year !== '' ? filters.year : undefined
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => {
            const base64String = response.data;

            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'liberatoria_da_codex.xls');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    // CREATE
    public CreateWaiverFromCodex(data: createUpdateWaiverFromDTO): Promise<void> {
        return axios.post("/api/waiver-from-codex",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateWaiverFromCodex(body: createUpdateWaiverFromDTO, id: string): Promise<void> {
        return axios.put("/api/waiver-from-codex/" + id,
            body,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}