import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import Input from "../../ui/molecules/input/Input";
import { MinusIcon } from "../../ui/icons/minusIcon";
import { PlusIconAccordion } from "../../ui/icons/plusIcon";
import React from "react";
import { SelectCustom } from "../../ui/molecules/select";
import { Province, Region, genreSelect, regioniProvinceMap, regions } from "../../utils";
import DatePicker, { registerLocale } from "react-datepicker";
import { CalendarIcon } from "../../ui/icons/calendar";
import { format } from "date-fns";
import { provinceComuniMap } from "../../utils/listaComuni";
import { BusinessTechnicianValidation, CreateTechnicianCreation, EditBusinessTechnician, resetBusinessTechnicianValidationStatus, resetCreateStatus, resetEditStatus, resetGetTechByIdStatus, setActivationDate, setAddress, setCity, setCompanyName, setCountryRegion, setDeactivationDate, setEmail, setFiscalCode, setMobilePhone, setPec, setPhone, setProvince, setSelectedTech, setVat, setZipCode } from "./slice";
import { Banner } from "../../ui/organisms/banner";
import { SpinnerComponent } from "../../lib/spinner";
import { Calendar } from "../../ui/molecules/calendar";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";

interface Props {
    children: ReactNode
}

type OptionType = {
    value: string;
    label: string;
};

export function EditTech() {
    const navigate = useNavigate()
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const businessTechinician = useAppSelector(state => state.businessTechinician)
    const dispatch = useAppDispatch()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);

    //rp
    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    useEffect(() => {
        if(businessTechinician.businessTechnicianValidationStatus === 'successfully') {
            dispatch(resetBusinessTechnicianValidationStatus())
            dispatch(EditBusinessTechnician({id: businessTechinician.selectedId, data: businessTechinician.createBusinessTechnician}))
        }
    }, [businessTechinician.businessTechnicianValidationStatus])

    useEffect(() => {
        if (businessTechinician.businessTechByIdStatus === 'successfully') {
            dispatch(setSelectedTech(businessTechinician.businessTechById?.id))
            dispatch(setCompanyName(businessTechinician.businessTechById?.companyName))
            dispatch(setFiscalCode(businessTechinician.businessTechById?.fiscalCode))
            dispatch(setVat(businessTechinician.businessTechById?.vat))
            dispatch(setAddress(businessTechinician.businessTechById?.address))
            dispatch(setCountryRegion(businessTechinician.businessTechById?.countryRegion))
            dispatch(setProvince(businessTechinician.businessTechById?.province))
            dispatch(setCity(businessTechinician.businessTechById?.city))
            dispatch(setZipCode(businessTechinician.businessTechById?.zipCode))
            dispatch(setPhone(businessTechinician.businessTechById?.phone))
            dispatch(setMobilePhone(businessTechinician.businessTechById?.mobilePhone))
            dispatch(setPec(businessTechinician.businessTechById?.pec))
            dispatch(setEmail(businessTechinician.businessTechById?.email))
            if (businessTechinician.businessTechById?.countryRegion) {
                const foundRegion = regions.find((region: Region) => region.name === businessTechinician.businessTechById?.countryRegion.toString());
                if (foundRegion) {

                    const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                        value: province.code,
                        label: province.name
                    }));

                    setProvinceOptions(newProvinceOptions);
                } else {
                    setProvinceOptions([]);
                }
            } else {
                setProvinceOptions([]);
            }

            if (businessTechinician.businessTechById?.province) {
                const comuni = provinceComuniMap.get(businessTechinician.businessTechById?.province.toString()) || [];
                const newComuneOptions = comuni.map((comune: string) => ({
                    value: comune,
                    label: comune
                }));
                setComuneOptions(newComuneOptions);
            } else {
                setComuneOptions([]);
            }

            dispatch(resetGetTechByIdStatus('idle'))
        }
    }, [businessTechinician.businessTechByIdStatus]);

    useEffect(() => {
       if(businessTechinician.businessTechinicianEditStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetEditStatus('idle'))
       }
    }, [businessTechinician.businessTechinicianEditStatus]);

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [showSuccess]);


    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };

    if (businessTechinician.businessTechByIdStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Tecnico Aziendale']}
            headerLabel="Tecnico Aziendale"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => {
                    navigate('/tecnico-aziendale')
                    }}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiorna" color={"orange"}
                        onClick={() => {
                            dispatch(BusinessTechnicianValidation(businessTechinician.createBusinessTechnician))
                        }}
                    />
                </div>
            }
        >
            {
                (showSuccess) &&

                <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            {
                showError &&

                <Banner
                    type="error"
                    visible={businessTechinician.technicianCreationStatus === 'failed'}
                    label={"Si è verificato un errore durante la creazione dell'utente"}
                    closeAction={function (): void {
                        dispatch(resetCreateStatus('idle'))
                    }}
                />

            }
            <div style={{ padding: '24px' }} id="boxAnagrafica">
                <div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')} defaultExpanded>
                        <AccordionSummary
                            expandIcon={expanded === 'panel1' ? <MinusIcon /> : <PlusIconAccordion />}
                            aria-controls="panel1-content"
                            className="rounded-xl headingAccordion"
                            id="panel1-header"
                        >
                            <div><span className="text-uppercase text-white label-input">Anagrafica</span></div>
                        </AccordionSummary>

                        <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                            <div className="bg-brandPrimary-100">
                                <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                    <div style={{ flex: '1 1 auto' }}>
                                        <Input
                                            value={businessTechinician.createBusinessTechnician.companyName}
                                            defaultValue={businessTechinician.createBusinessTechnician.companyName}
                                            label={'Ragione Sociale'}
                                            placeholder="Ragione Sociale"
                                            onChangeText={(text) => dispatch(setCompanyName(text))}
                                        />
                                    </div>
                                </div>
                                <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                    <div style={{ flex: '0.5' }}>
                                        <Input
                                            error={businessTechinician.technicianErrors.fiscalCode}
                                            supportingText={businessTechinician.technicianErrors.fiscalCode ? 'Deve essere di 16 caratteri!' : undefined}
                                            label={'Codice Fiscale'}
                                            value={businessTechinician.createBusinessTechnician.fiscalCode}
                                            defaultValue={businessTechinician.createBusinessTechnician.fiscalCode}
                                            placeholder="Codice Fiscale"
                                            onChangeText={(text) => dispatch(setFiscalCode(text))}
                                        />
                                    </div>
                                    <div style={{ flex: '0.5' }}>
                                        <Input
                                            error={businessTechinician.technicianErrors.vat}
                                            supportingText={businessTechinician.technicianErrors.vat ? 'Deve essere compreso tra 11 e 16 caratteri!' : undefined}
                                            label={'Partita Iva'}
                                            value={businessTechinician.createBusinessTechnician.vat}
                                            defaultValue={businessTechinician.createBusinessTechnician.vat}
                                            placeholder="Partita Iva"
                                            onChangeText={(text) => dispatch(setVat(text))}
                                        />
                                    </div>
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="mt-6">
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel2' ? <MinusIcon /> : <PlusIconAccordion />}
                            aria-controls="panel2-content"
                            className="rounded-xl headingAccordion"
                            id="panel2-header"
                        >
                            <div>
                                <span className="text-uppercase text-white label-input">
                                    Recapiti
                                </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                            <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '1 1 auto' }}>
                                    <Input
                                        label={'Indirizzo'}
                                        value={businessTechinician.createBusinessTechnician.address}
                                        defaultValue={businessTechinician.createBusinessTechnician.address}
                                        placeholder="Indirizzo"
                                        onChangeText={(text) => dispatch(setAddress(text))}
                                    />
                                </div>

                            </div>

                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Regione</span>
                                    </div>
                                    <SelectCustom
                                        error={businessTechinician.technicianErrors.countryRegion}
                                        errorLabel={'Regione mancante'}
                                        value={businessTechinician.createBusinessTechnician.countryRegion}
                                        defaultValue={businessTechinician.createBusinessTechnician.countryRegion}
                                        placeholder={'Regione'}
                                        options={regionOptions}
                                        onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setCountryRegion(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Provincia</span>
                                    </div>
                                    <SelectCustom
                                        error={businessTechinician.technicianErrors.province}
                                        errorLabel={'Provincia mancante'}
                                        placeholder={'Provincia'}
                                        options={provinceOptions}
                                        value={businessTechinician.createBusinessTechnician.province}
                                        defaultValue={businessTechinician.createBusinessTechnician.province}
                                        onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvince(value)) }}
                                    />
                                </div>

                            </div>
                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Comune</span>
                                    </div>
                                    <SelectCustom
                                        error={businessTechinician.technicianErrors.city}
                                        errorLabel={'Città mancante'}
                                        value={businessTechinician.createBusinessTechnician.city}
                                        defaultValue={businessTechinician.createBusinessTechnician.city}
                                        placeholder={'Comune'}
                                        options={comuneOptions}
                                        onChange={(text) => { dispatch(setCity(text)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        error={businessTechinician.technicianErrors.zipCode}
                                        supportingText={businessTechinician.technicianErrors.zipCode ? 'Deve essere di 5 caratteri!' : undefined}
                                        label={'CAP'}
                                        value={businessTechinician.createBusinessTechnician.zipCode}
                                        defaultValue={businessTechinician.createBusinessTechnician.zipCode}
                                        placeholder="CAP"
                                        onChangeText={(text) => dispatch(setZipCode(text))}
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="mt-6">
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel3' ? <MinusIcon /> : <PlusIconAccordion />}
                            aria-controls="panel3-content"
                            className="rounded-xl headingAccordion"
                            id="panel3-header"
                        >
                            <div>
                                <span className="text-uppercase text-white label-input">
                                    Contatti
                                </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>

                            <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        error={businessTechinician.technicianErrors.phone}
                                        supportingText={businessTechinician.technicianErrors.phone ? 'Deve essere compreso tra 7 e 15 caratteri!' : undefined}
                                        label={'Telefono'}
                                        placeholder="Telefono"
                                        value={businessTechinician.createBusinessTechnician.phone}
                                        defaultValue={businessTechinician.createBusinessTechnician.phone}
                                        onChangeText={(text) => dispatch(setPhone(text))}
                                    />
                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        error={businessTechinician.technicianErrors.mobilephone}
                                        supportingText={businessTechinician.technicianErrors.mobilephone ? 'Deve essere compreso tra 9 e 13 caratteri!' : undefined}
                                        label={'Cellulare'}
                                        placeholder="Cellulare"
                                        value={businessTechinician.createBusinessTechnician.mobilePhone}
                                        defaultValue={businessTechinician.createBusinessTechnician.mobilePhone}
                                        onChangeText={(text) => dispatch(setMobilePhone(text))}
                                    />
                                </div>

                            </div>

                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Email'}
                                        value={businessTechinician.createBusinessTechnician.email}
                                        defaultValue={businessTechinician.createBusinessTechnician.email}
                                        placeholder="Email"
                                        onChangeText={(text) => dispatch(setEmail(text))}
                                    />
                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'PEC'}
                                        value={businessTechinician.createBusinessTechnician.pec}
                                        defaultValue={businessTechinician.createBusinessTechnician.pec}
                                        placeholder="PEC"
                                        onChangeText={(text) => dispatch(setPec(text))}
                                    />
                                </div>

                            </div>

                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="mt-6">
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordion('panel5')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel5' ? <MinusIcon /> : <PlusIconAccordion />}
                            aria-controls="panel5-content"
                            className="rounded-xl headingAccordion"
                            id="panel5-header"
                        >
                            <div>
                                <span className="text-uppercase text-white label-input">
                                    Data ingresso / Uscita
                                </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                            <div className="formInOut" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span>Data Ingresso</span>
                                    </div>
                                    <Calendar
                                        selected={businessTechinician.createBusinessTechnician.activationDate}
                                        onChange={(date) => {
                                            dispatch(setActivationDate(date))
                                        }}
                                    />

                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span>Data Uscita</span>
                                    </div>
                                    <Calendar
                                        onChange={(date) => {
                                            dispatch(setDeactivationDate(date))
                                        }}
                                    />
                                </div>

                            </div>

                        </AccordionDetails>
                    </Accordion>
                </div>
            </div >
        </Layout>
    )
}