import { formaGiuridica, optionsFormatter } from '../../../../utils';
import { ProfileBoxProps } from './profile.types';
import './styles.scss';

export const ProfileBox: React.FC<ProfileBoxProps> = ({
    ...props
}) => {
    return (
        <div className="pre-box">
            <div className="profile-box">
                <div className="title">Dati Generali</div>
                
                <div className="profile-content">
                    <div className="profile-data">
                        <div className="content-bold">Regione</div>
                        <div className="content">{props.region}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">CUAA</div>
                        <div className="content">{props.CUAA}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">P. IVA</div>
                        <div className="content">{props.pIva}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Sede legale</div>
                        <div className="content">{props.sedeLegale}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Recapito postale</div>
                        <div className="content">{props.recapitoPostale}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
