import DatePicker from "react-datepicker"
import { CalendarIcon } from "../../icons/calendar"
import { InputDatePickerStyle } from "../input/utils"
import { RoundedCloseIcon } from "../../icons/CloseIcon"

interface Props {
    selected?: Date | null
    onChange?: (date: Date) => void
    onClear?: (date: undefined) => void
    disabled?: boolean
    errorLabel?: string
    label?: string
    minDate?: Date
}

export function Calendar(props: Props) {
    const dayClassName = (date: Date) => {
        if (date.getDay() === 0) { // 0 rappresenta la domenica
            return "sunday"; // Restituisce la classe "sunday" per le domeniche
        }
        return "";
    }
    return (
        <div className="text-left" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                {props.label && <span className="input-label">{props.label}</span>}
            </div>
            <div style={{ display: 'flex', width: '100%' }}>

                <DatePicker locale="it" dateFormat="d/MM/yyyy"
                    selected={props.selected}
                    showIcon
                    disabled={props.disabled}
                    icon={
                        <div style={{ marginTop: '6px' }}>
                            <CalendarIcon size={20} color="black" />
                        </div>
                    }
                    dayClassName={dayClassName}
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    minDate={props.minDate}
                    customInput={<input style={{ ...InputDatePickerStyle, backgroundColor: !props.disabled ? 'white' : '#f2f2f2', width: '100%' }} />}
                    onChange={
                        (date) => {
                            if (date !== null) {
                                props.onChange && props.onChange(date)
                            }
                        }
                    }
                />
                {
                    props.onClear && (
                        <button className="pl-3" onClick={() => props.onClear && props.onClear(undefined)}>
                            <RoundedCloseIcon />
                        </button>
                    )
                }
            </div>
            {
                props.errorLabel && (
                    <span className="input-supporting-text pt-2" style={{ color: '#fd665b', justifyContent: 'left', display: 'flex' }}>{props.errorLabel}</span>
                )
            }
        </div>
    )
}