import { format } from "date-fns"
import { registerLocale } from "react-datepicker"
import { it } from 'date-fns/locale/it';
import { ReportingInnerState, ReportingOuterState, ReportingType } from "../pages/gestioneControllo/segnalazioni/dataService/dto";
import { BusinessRegisterRequestReason, BusinessRegisterRequestType } from "../pages/gestioneClienti/registriAziendali/dataService/dto";
export type PromiseStatuses = 'idle' | 'loading' | 'successfully' | 'failed'
export type OnButtonClick = React.MouseEventHandler<HTMLButtonElement> | undefined
export type OnMenuItemClick = React.MouseEventHandler<HTMLLIElement> | undefined
export type OnChange = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnFocus = React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnKeyDown = React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type Operation = 'save' | 'edit'
export const BACKEND_HOST = 'http://localhost:8080'
export type QuarkusError = { message: string }[]

registerLocale('it', it)

export function truncate(str: string) {
    return str.length > 20 ? str.substring(0, 17) + "..." : str;
}

export async function toBase64(file: File): Promise<string> {
    const bytes = new Uint8Array(await file.arrayBuffer());

    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {

        binary += String.fromCharCode(bytes[i]);

    }
    return btoa(binary);
}

export const breadcrumbsLabel = new Map<string, string>([
    ['dashboard', 'Dashboard'],
    ['users', 'Utenti'],
])

export const formatterDate = (date: string | Date | undefined): string => {
    return date ? format(new Date(date), 'dd/MM/yyyy', { locale: it }) : '';
};

export const optionsFormatter = (str: string, options: OptionType[]) => {
    return options.find(o => o.value === str)?.label
}

export function findClosestDate(dates: Date[], today: Date): Date {
    return dates.reduce((closest, currentDate) => {
        const diffClosest = Math.abs(today.getTime() - closest.getTime());
        const diffCurrent = Math.abs(today.getTime() - currentDate.getTime());

        return diffCurrent < diffClosest ? currentDate : closest;
    });
}

export type OptionType = {
    value: string;
    label: string;
};

export const attivita_bio = [
    { value: 'A', label: 'Produzione' },
    { value: 'B', label: 'Preparazione' },
    { value: 'C', label: 'Distribuzione/Immissione sul mercato' },
    { value: 'D', label: 'Magazzinaggio' },
    { value: 'E', label: 'Importazione' },
    { value: 'F', label: 'Esportazione' }
]

export const getLabelACtivity = (value: string, map: string) => {
    if (map === 'attivita_bio') {
        const activity = attivita_bio.find(activity => activity.value === value);
        return activity ? activity.label : undefined;
    }
    else if (map === 'categorie_prodotti') {
        const category = categorie_prodotti.find(category => category.value === value);
        return category ? category.label : undefined;
    }
    return undefined
};

export const categorie_prodotti = [
    { value: 'A', label: 'Vegetali e prodotti vegetali non trasformati, compresi sementi e altro materiale riproduttivo vegetale' },
    { value: 'B', label: 'Animali e prodotti animali non trasformati' },
    { value: 'C', label: 'Alghe e prodotti di acquacoltura non trasformati' },
    { value: 'D', label: 'Prodotti agricoli trasformati, inclusi prodotti di acquacoltura, destinati ad essere utilizzati come alimenti' },
    { value: 'E', label: 'Mangimi' },
    { value: 'F', label: 'Vino' },
    { value: 'G', label: 'Altri prodotti elencati nell’allegato I del presente regolamento o non ricompresi nelle precedenti categorie' },
]

export const categorie_prodotti_A = [
    { value: 'A', label: 'Vegetali e prodotti vegetali non trasformati, compresi sementi e altro materiale riproduttivo vegetale' },
    { value: 'B', label: 'Animali e prodotti animali non trasformati' },
    { value: 'C', label: 'Alghe e prodotti di acquacoltura non trasformati' },
    { value: 'G', label: 'Altri prodotti elencati nell’allegato I del presente regolamento o non ricompresi nelle precedenti categorie' },
]

export const prodotti = [
    { value: 'A', label: 'Lieviti utilizzati come alimenti o come mangimi ' },
    { value: 'B', label: 'Mate, granturco dolce, foglie di vite, cuori di palma, germogli di luppolo e altre parti commestibili simili di vegetali e prodotti da esse ottenuti' },
    { value: 'C', label: 'Sale marino e altri sali per alimenti e mangimi' },
    { value: 'D', label: 'Bozzoli di bachi da seta atti alla trattura' },
    { value: 'E', label: 'Gomme e resine naturali' },
    { value: 'F', label: 'Cera d’api' },
    { value: 'G', label: 'Oli essenziali' },
    { value: 'H', label: 'Turaccioli di sughero naturale, non agglomerati, e senza leganti' },
    { value: 'I', label: 'Cotone, non cardato né pettinato' },
    { value: 'M', label: 'Lana, non cardata né pettinata' },
    { value: 'L', label: 'Pelli gregge e non trattate' },
    { value: 'N', label: 'Preparati erboristici tradizionali a base vegetale' },
]


export interface RowActivityMultiSelect {
    bioActivity?: string;
    bioCategories?: { label: string; value: string }[];
    ggStandard?: string,
    ggIfaOption?: string
    startingActivity: string;
    endingActivity: string;
}

export const role = [
    { value: 'Amm', label: 'Amm' },
    { value: 'DG', label: 'DG' },
    { value: 'RSCo', label: 'RSCo' },
    { value: 'FUCO', label: 'FUCO' },
    { value: 'RSCe', label: 'RSCe' },
    { value: 'FUCE', label: 'FUCE' },
    { value: 'RST', label: 'RST' },
    { value: 'FST', label: 'FST' },
    { value: 'UCA', label: 'UCA' },
    { value: 'VAL', label: 'VAL' },
    { value: 'OCD', label: 'OCD' },
    { value: 'CSI', label: 'CSI' },
    { value: 'SM', label: 'SM' },
    { value: 'CSM', label: 'CSM' },
    { value: 'RD', label: 'RD' },
    { value: 'RSOT', label: 'RSOT' },
    { value: 'CSOT', label: 'CSOT' },
    { value: 'PISP', label: 'PISP' },
    { value: 'OP', label: 'OP' },

]

export const regioniProvinceMap: Map<string, string[]> = new Map([
    ["Abruzzo", ["Chieti", "L'Aquila", "Pescara", "Teramo"]],
    ["Basilicata", ["Matera", "Potenza"]],
    ["Calabria", ["Catanzaro", "Cosenza", "Crotone", "Reggio Calabria", "Vibo Valentia"]],
    ["Campania", ["Avellino", "Benevento", "Caserta", "Napoli", "Salerno"]],
    ["Emilia-Romagna", ["Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini"]],
    ["Friuli Venezia Giulia", ["Gorizia", "Pordenone", "Trieste", "Udine"]],
    ["Lazio", ["Frosinone", "Latina", "Rieti", "Roma", "Viterbo"]],
    ["Liguria", ["Genova", "Imperia", "La Spezia", "Savona"]],
    ["Lombardia", ["Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Milano", "Monza e della Brianza", "Pavia", "Sondrio", "Varese"]],
    ["Marche", ["Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino"]],
    ["Molise", ["Campobasso", "Isernia"]],
    ["Piemonte", ["Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Torino", "Verbano-Cusio-Ossola", "Vercelli"]],
    ["Puglia", ["Bari", "Barletta-Andria-Trani", "Brindisi", "Foggia", "Lecce", "Taranto"]],
    ["Sardegna", ["Cagliari", "Nuoro", "Oristano", "Sassari", "Sud Sardegna"]],
    ["Sicilia", ["Agrigento", "Caltanissetta", "Catania", "Enna", "Messina", "Palermo", "Ragusa", "Siracusa", "Trapani"]],
    ["Toscana", ["Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa-Carrara", "Pisa", "Pistoia", "Prato", "Siena"]],
    ["Trentino-Alto Adige", ["Bolzano", "Trento"]],
    ["Umbria", ["Perugia", "Terni"]],
    ["Valle d'Aosta", ["Aosta"]],
    ["Veneto", ["Belluno", "Padova", "Rovigo", "Treviso", "Venezia", "Verona", "Vicenza"]]
]);

export interface Province {
    name: string;
    code: string;
}

export interface Region {
    name: string;
    provinces: Province[];
}

export enum CustomOptions {
    YES = 'YES',
    NO = 'NO',
}

export enum CustomFormazioneOptions {
    WEBINAR = 'WEBINAR',
    IN_SEDE = 'IN_SEDE',
}

export const regions = [
    {
        name: "Abruzzo",
        provinces: [
            { name: "L'Aquila", code: "AQ" },
            { name: "Teramo", code: "TE" },
            { name: "Pescara", code: "PE" },
            { name: "Chieti", code: "CH" }
        ]
    },
    {
        name: "Basilicata",
        provinces: [
            { name: "Potenza", code: "PZ" },
            { name: "Matera", code: "MT" }
        ]
    },
    {
        name: "Calabria",
        provinces: [
            { name: "Catanzaro", code: "CZ" },
            { name: "Cosenza", code: "CS" },
            { name: "Crotone", code: "KR" },
            { name: "Reggio Calabria", code: "RC" },
            { name: "Vibo Valentia", code: "VV" }
        ]
    },
    {
        name: "Campania",
        provinces: [
            { name: "Avellino", code: "AV" },
            { name: "Benevento", code: "BN" },
            { name: "Caserta", code: "CE" },
            { name: "Napoli", code: "NA" },
            { name: "Salerno", code: "SA" }
        ]
    },
    {
        name: "Emilia-Romagna",
        provinces: [
            { name: "Bologna", code: "BO" },
            { name: "Ferrara", code: "FE" },
            { name: "Forlì-Cesena", code: "FC" },
            { name: "Modena", code: "MO" },
            { name: "Parma", code: "PR" },
            { name: "Piacenza", code: "PC" },
            { name: "Ravenna", code: "RA" },
            { name: "Reggio Emilia", code: "RE" },
            { name: "Rimini", code: "RN" }
        ]
    },
    {
        name: "Friuli-Venezia Giulia",
        provinces: [
            { name: "Gorizia", code: "GO" },
            { name: "Pordenone", code: "PN" },
            { name: "Trieste", code: "TS" },
            { name: "Udine", code: "UD" }
        ]
    },
    {
        name: "Lazio",
        provinces: [
            { name: "Frosinone", code: "FR" },
            { name: "Latina", code: "LT" },
            { name: "Rieti", code: "RI" },
            { name: "Roma", code: "RM" },
            { name: "Viterbo", code: "VT" }
        ]
    },
    {
        name: "Liguria",
        provinces: [
            { name: "Genova", code: "GE" },
            { name: "Imperia", code: "IM" },
            { name: "La Spezia", code: "SP" },
            { name: "Savona", code: "SV" }
        ]
    },
    {
        name: "Lombardia",
        provinces: [
            { name: "Bergamo", code: "BG" },
            { name: "Brescia", code: "BS" },
            { name: "Como", code: "CO" },
            { name: "Cremona", code: "CR" },
            { name: "Lecco", code: "LC" },
            { name: "Lodi", code: "LO" },
            { name: "Mantova", code: "MN" },
            { name: "Milano", code: "MI" },
            { name: "Monza e Brianza", code: "MB" },
            { name: "Pavia", code: "PV" },
            { name: "Sondrio", code: "SO" },
            { name: "Varese", code: "VA" }
        ]
    },
    {
        name: "Marche",
        provinces: [
            { name: "Ancona", code: "AN" },
            { name: "Ascoli Piceno", code: "AP" },
            { name: "Fermo", code: "FM" },
            { name: "Macerata", code: "MC" },
            { name: "Pesaro e Urbino", code: "PU" }
        ]
    },
    {
        name: "Molise",
        provinces: [
            { name: "Campobasso", code: "CB" },
            { name: "Isernia", code: "IS" }
        ]
    },
    {
        name: "Piemonte",
        provinces: [
            { name: "Alessandria", code: "AL" },
            { name: "Asti", code: "AT" },
            { name: "Biella", code: "BI" },
            { name: "Cuneo", code: "CN" },
            { name: "Novara", code: "NO" },
            { name: "Torino", code: "TO" },
            { name: "Verbano-Cusio-Ossola", code: "VB" },
            { name: "Vercelli", code: "VC" }
        ]
    },
    {
        name: "Puglia",
        provinces: [
            { name: "Bari", code: "BA" },
            { name: "Barletta-Andria-Trani", code: "BT" },
            { name: "Brindisi", code: "BR" },
            { name: "Foggia", code: "FG" },
            { name: "Lecce", code: "LE" },
            { name: "Taranto", code: "TA" }
        ]
    },
    {
        name: "Sardegna",
        provinces: [
            { name: "Cagliari", code: "CA" },
            { name: "Nuoro", code: "NU" },
            { name: "Oristano", code: "OR" },
            { name: "Sassari", code: "SS" },
            { name: "Sud Sardegna", code: "SU" }
        ]
    },
    {
        name: "Sicilia",
        provinces: [
            { name: "Agrigento", code: "AG" },
            { name: "Caltanissetta", code: "CL" },
            { name: "Catania", code: "CT" },
            { name: "Enna", code: "EN" },
            { name: "Messina", code: "ME" },
            { name: "Palermo", code: "PA" },
            { name: "Ragusa", code: "RG" },
            { name: "Siracusa", code: "SR" },
            { name: "Trapani", code: "TP" }
        ]
    },
    {
        name: "Toscana",
        provinces: [
            { name: "Arezzo", code: "AR" },
            { name: "Firenze", code: "FI" },
            { name: "Grosseto", code: "GR" },
            { name: "Livorno", code: "LI" },
            { name: "Lucca", code: "LU" },
            { name: "Massa-Carrara", code: "MS" },
            { name: "Pisa", code: "PI" },
            { name: "Pistoia", code: "PT" },
            { name: "Prato", code: "PO" },
            { name: "Siena", code: "SI" }
        ]
    },
    {
        name: "Trentino-Alto Adige",
        provinces: [
            { name: "Bolzano", code: "BZ" },
            { name: "Trento", code: "TN" }
        ]
    },
    {
        name: "Umbria",
        provinces: [
            { name: "Perugia", code: "PG" },
            { name: "Terni", code: "TR" }
        ]
    },
    {
        name: "Valle d'Aosta",
        provinces: [
            { name: "Aosta", code: "AO" }
        ]
    },
    {
        name: "Veneto",
        provinces: [
            { name: "Belluno", code: "BL" },
            { name: "Padova", code: "PD" },
            { name: "Rovigo", code: "RO" },
            { name: "Treviso", code: "TV" },
            { name: "Venezia", code: "VE" },
            { name: "Verona", code: "VR" },
            { name: "Vicenza", code: "VI" }
        ]
    }
];

export const regioniListOperativeAreas = [
    { label: "Abruzzo", value: "ABRUZZO" },
    { label: "Basilicata", value: "BASILICATA" },
    { label: "Calabria", value: "CALABRIA" },
    { label: "Campania", value: "CAMPANIA" },
    { label: "Emilia-Romagna", value: "EMILIA_ROMAGNA" },
    { label: "Friuli Venezia Giulia", value: "FRIULI_VENEZIA_GIULIA" },
    { label: "Lazio", value: "LAZIO" },
    { label: "Liguria", value: "LIGURIA" },
    { label: "Lombardia", value: "LOMBARDIA" },
    { label: "Marche", value: "MARCHE" },
    { label: "Molise", value: "MOLISE" },
    { label: "Piemonte", value: "PIEMONTE" },
    { label: "Puglia", value: "PUGLIA" },
    { label: "Sardegna", value: "SARDEGNA" },
    { label: "Sicilia", value: "SICILIA" },
    { label: "Toscana", value: "TOSCANA" },
    { label: "Trentino-Alto Adige", value: "TRENTINO_ALTO_ADIGE" },
    { label: "Umbria", value: "UMBRIA" },
    { label: "Valle d'Aosta", value: "VALLE_D_AOSTA" },
    { label: "Veneto", value: "VENETO" }
];

export const regioniList = [
    { label: "Non Definita", value: "ND" },
    { label: "Abruzzo", value: "Abruzzo" },
    { label: "Basilicata", value: "Basilicata" },
    { label: "Calabria", value: "Calabria" },
    { label: "Campania", value: "Campania" },
    { label: "Emilia-Romagna", value: "Emilia-Romagna" },
    { label: "Friuli Venezia Giulia", value: "Friuli Venezia Giulia" },
    { label: "Lazio", value: "Lazio" },
    { label: "Liguria", value: "Liguira" },
    { label: "Lombardia", value: "Lombardia" },
    { label: "Marche", value: "Marche" },
    { label: "Molise", value: "Molise" },
    { label: "Piemonte", value: "Piemonte" },
    { label: "Puglia", value: "Puglia" },
    { label: "Sardegna", value: "Sardegna" },
    { label: "Sicilia", value: "Sicilia" },
    { label: "Toscana", value: "Toscana" },
    { label: "Trentino-Alto Adige", value: "Trentino-Alto Adige" },
    { label: "Umbria", value: "UMBRIA" },
    { label: "Valle d'Aosta", value: "Valle d'Aosta" },
    { label: "Veneto", value: "Veneto" }
];



export const odc = [
    { value: 'IT_BIO_001_BZ_BIO_GARANTIE_GMBH', label: 'Bio Garantie GmbH' },
    { value: 'IT_BIO_002_BZ_IMO_GMBH', label: 'IMO Gmbh' },
    { value: 'IT_BIO_002_CODEX_S_R_L', label: 'Codex S.r.l.' },
    { value: 'IT_BIO_003_BZ_Q_C_AND_I_GESELLSCHAFT_FUR_KONTROLLE_UND_ZERTIFI', label: 'Q.C. & I. - Gesellschaft für kontrolle und zertifizierung von Qualitätssicherung' },
    { value: 'IT_BIO_003_IMC_ISTITUTO_MEDITERRANEO_DI_CERTIFICAZIONE_S_R', label: 'IMC - Istituto Mediterraneo di Certificazione S.r.l.' },
    { value: 'IT_BIO_004_SUOLO_E_SALUTE_SRL', label: 'Suolo e Salute Srl' },
    { value: 'IT_BIO_005_BIOS_S_R_L', label: 'Bios S.r.l.' },
    { value: 'IT_BIO_006_ICEA_ISTITUTO_PER_LA_CERTIFICAZIONE_ETICA_E_AMBI', label: 'ICEA - Istituto per la Certificazione Etica e Ambientale' },
    { value: 'IT_BIO_007_BIOAGRICERT_S_R_L', label: 'IT BIO 007 BIOAGRICERT SRL' },
    { value: 'IT_BIO_008_ECOGRUPPO_ITALIA_S_R_L', label: 'Ecogruppo Italia S.r.l.' },
    { value: 'IT_BIO_009_CCPB_S_R_L', label: 'CCPB S.r.l.' },
    { value: 'IT_BIO_012_SIDEL_SPA', label: 'Sidel S.p.a.' },
    { value: 'IT_BIO_013_ABCERT_SRL', label: 'ABCERT Srl' },
    { value: 'IT_BIO_010_BIOZOO_S_R_L', label: 'BIOZOO S.R.L.' },
    { value: 'IT_BIO_014_QCERTIFICAZIONI_S_R_L', label: 'QCertificazioni S.r.l.' },
    { value: 'IT_BIO_015_VALORITALIA_SRL', label: 'Valoritalia Srl' },
    { value: 'IT_BIO_016_SIQURIA_SPA', label: 'Siquria S.p.a.' },
    { value: 'IT_BIO_017_CEVIQ_S_R_L', label: 'Ceviq S.r.l.' },
    { value: 'IT_BIO_018_AGROQUALITA_SPA', label: 'Agroqualità S.p.a.' },
    { value: 'IT_BIO_019_ISTITUTO_NORD_OVEST_QUALITA_SOC_COOP', label: 'Istituto Nord Ovest Qualità Soc. Coop.' },
    { value: 'IT_BIO_020_CSQA_CERTIFICAZIONI_SRL_DIPARTIMENTO_DI_QUALITA_AGROALIMENTARE_SRL', label: 'Dipartimento di Qualità Agroalimentare Srl' },
    { value: 'IT_BIO_021_CSQA_CERTIFICAZIONI_SRL', label: 'CSQA Cerrtificazioni srl' },
    { value: 'IT_BIO_022_A_S_TER_AMBIENTE_SOSTENIBILITA_E_TERRITORIO_S_R_L', label: 'A.S.TER. - Ambiente Sostenibilità e Territorio S.r.l.' },
    { value: 'IT_BIO_023_QUALITALY_SRL', label: 'QUALITALY S.r.l.' },
];

export const sot = [
    { value: 'SC', label: 'Sede Centrale' },
    { value: 'CL', label: 'SOT Calabria' },
    { value: 'IS', label: 'SOT Italia Settentrionale' },
    { value: 'PU', label: 'SOT Puglia' },
    { value: 'BA', label: 'SOT Basilicata' },
    { value: 'IM', label: 'SOT Italia Meridionale' },
    { value: 'TV', label: 'SOT Triveneto' }
]

export const status = [
    { value: 'active', label: 'Attivo' },
    { value: 'inactive', label: 'Non Attivo' },
]

export const genreSelect = [
    { value: 'M', label: 'Maschio' },
    { value: 'F', label: 'Femmina' },
]

export const normativa = [
    { value: 'NORMA_BIO', label: 'Biologico' },
    { value: 'PRODUZIONE_INTEGRATA', label: 'Produzione Integrata' },
    { value: 'DETERGENZA', label: 'Detergenza' },
    { value: 'GLOBAL_GAP', label: 'GlobalGAP' },
    { value: 'QUALITA_SICURA', label: 'Qualità Sicura' },
    { value: 'RESIDUO_ZERO', label: 'Residuo Zero' },
]

export const normativaView = [
    { value: 'NORMA_BIO', label: 'Bio' },
    { value: 'PRODUZIONE_INTEGRATA', label: 'SQNPI' },
    { value: 'DETERGENZA', label: 'Detergenza' },
    { value: 'GLOBAL_GAP', label: 'GlobalG.A.P.' },
    { value: 'QUALITA_SICURA', label: 'QS' },
    { value: 'RESIDUO_ZERO', label: 'RZ' },
]

export const normativaCategories: OptionType[] = [
    { value: 'AB', label: 'Agricoltura Biologica' },
    { value: 'GAP', label: 'GLOBALG.A.P.' },
    { value: 'SQNPI', label: 'SQNPI' },
    { value: 'Gen', label: 'Generica' },
]

export const formaGiuridica = [
    { value: 'NON_DEFINITO', label: 'Non Definito' },
    { value: 'DITTA_INDIVIDUALE', label: 'Ditta Individuale' },
    { value: 'SOCIETA', label: 'Società' },
    { value: 'COOPERATIVA', label: 'Cooperativa' },
    { value: 'ASSOCIAZIONE', label: 'Associazione' },
    { value: 'SOCIETA_COOPERATIVA', label: 'Società Cooperativa' },
]

export const mapRole = [
    { role: 'Amm', label: 'Amministrazione', sigla: 'Amm', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'DG', label: 'Direzione', sigla: 'DG', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'RSCo', label: 'Responsabile Ufficio Controllo', sigla: 'RSCo', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'FUCO', label: 'Funzionari Ufficio Controllo', sigla: 'FUCO', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'RSCe', label: 'Responsabile Ufficio Certificazione', sigla: 'RSCe', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'FUCE', label: 'Funzionari Ufficio Certificazione', sigla: 'FUCE', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'RST', label: 'Responsabile Ufficio Segreteria Tecnica', sigla: 'RST', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'FST', label: 'Funzionari Ufficio Segreteria Tecnica', sigla: 'FST', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'UCA', label: 'Funzionari Amministrazione', sigla: 'UCA', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'VAL', label: 'Valutatore processo di Controllo', sigla: 'VAL', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'SM', label: 'Scheme Manager', sigla: 'SM', normativa: 'GlobalGap', regioni: 'Tutte' },
    { role: 'OCD', label: 'Organo Collegiale di Delibera)', sigla: 'OCD', normativa: 'Tutte', regioni: 'Tutte' },
    { role: 'OP', label: 'Operatore', sigla: 'OP', normativa: 'Tutte', regioni: 'Tutte' },
]

export const residuo_zero_type = [
    { value: 'RESIDUO_ZERO', label: 'Residuo zero' },
    { value: 'RESIDUO_CONTROLLATO', label: 'Residuo controllato' }
]

export const tipoSoggetto = [
    { value: 'SINGOLO', label: 'Singolo' },
    { value: 'ASSOCIATO', label: 'Associato' },
]

export const requisiti_certificazione = [
    { value: 'PACKAGING_COMPOSTABILE', label: 'Packaging compostabile' },
    { value: 'PACKAGING_PLASTIC_FREE', label: 'packaging “plastic free”' },
    { value: 'NON_UTILIZZA_PRINCIPI_ATTIVI', label: 'Non Utilizza principio/i attivo/i' }
]

export const tipologia_residuo_zero = [
    { value: 'PRODUTTORE_AGRICOLO', label: 'Produttore Agricolo' },
    { value: 'IMPRESA_DI_TRASFORMAZIONE_CONDIZIONAMENTO_STOCCAGGIO_COMMERCIALIZZAZIONE_ECC', label: 'Impresa di Trasformazione, Condizionamento, Stoccaggio, Commercializzazione, ecc.' },
    { value: 'PRODUTTORE_AGRICOLO_ED_IMPRESA_DI_TRASFORMAIONE_CONDIZIONAMENTO_STOCCAGGIO_COMMERCIALIZZAIZONE_ECC', label: 'Produttore Agricolo ed Impresa di Trasformazione, Condizionamento, Stoccaggio, Commercializzazione, ecc.' }
]

/*
** GLOBAL GAP
*/

export const global_standards = [
    { value: 'IFA', label: 'IFA (Integrated Farm Assurance)' },
    { value: 'CoC', label: 'CoC Catena di Custodia (Chain of Custody)' }
]

export const global_standards_options_functions = [
    { label: 'Opzione1', value: 'OPZIONE_1' },
    { label: 'Opzione2', value: 'OPZIONE_2' }
]

export const edizioni = [
    { value: 'SMART', label: 'SMART' },
    { value: 'GFS', label: 'GFS' }
]

export const campo_di_applicazione = [
    { value: 'PIANTE', label: 'Piante' },
    { value: 'AQUACULTURE', label: 'Aquaculture' }
]

export const categorie_piante = [
    { value: 'FRUTTA_E_VERDURA', label: 'Frutta e verdura' },
    { value: 'FIORI_E_PIANTE_ORNAMENTALI', label: 'Fiori e Piante Ornamentali' },
    { value: 'CULTURE_COMBINABILI_COLTIVAZIONI_ALL_APERTO', label: `Culture Combinabili (coltivazioni all'aperto)` },
    { value: 'MATERIALE_DI_MOLTIPLICAZIONE_DELLE_PIANTE', label: 'Materiale di moltiplicazione delle piante' },
    { value: 'TE', label: 'Tè' },
    { value: 'LUPPOLO', label: 'Luppolo' }
]

export const opzioni_certificazione_IFA = [
    { value: 'OPZIONE_1_PRODUTTORE_CON_SITO_SINGOLO', label: 'Opzione 1 - Produttore con sito singolo' },
    { value: 'OPZIONE_1_PRODUTTORE_MULTISITO_SENZA_SGQ', label: 'Opzione 1 - Produttore multisito senza SGQ' },
    { value: 'OPZIONE_1_PRODUTTORE_MULTISITO_CON_SGQ', label: 'Opzione 1 - Produttore multisito con SGQ' },
    { value: 'OPZIONE_2_CERTIFICAZIONE_DI_GRUPPO', label: 'Opzione 2 - Certificazione di gruppo' },
]

export const manipolazione = [
    { value: 'NULL', label: 'Nessuna' },
    { value: 'CAMPO', label: 'Campo' },
    { value: 'MAGAZZINO', label: 'Magazzino' },
    { value: 'CAMPO_E_MAGAZZINO', label: 'Campo e Magazzino' }
]

export const boolean_values = [
    { value: 'SI', label: 'SI' },
    { value: 'NO', label: 'NO' }
]

export const opzioni_certificazione_CoC = [
    { value: 'OPZIONE_1_CERTIFICAZIONE_INDIVIDUALE', label: 'Opzione 1 - Certificazione individuale' },
    { value: 'OPZIONE_1_SITI_SINGOLI', label: 'Opzione 1 - Siti singoli ' },
    { value: 'OPZIONE_1_MULTISITO', label: 'Opzione 1 - Multisito' },
    { value: 'OPZIONE_1_MULTISITO_PER_NEGOZI_AL_DETTAGLIO_E_CATENE_DI_RISTORANTI_IN_FRANCHISING', label: 'Opzione 1 - Multisito per negozi al dettaglio e catene di ristoranti in franchising' }
]

export const addonSelect = [
    { value: 'GRASP', label: 'GRASP' },
    { value: 'TRASPARENZA_SUI_PESTICIDI_DI_COOP_ITALIA', label: 'TRASPARENZA SUI PESTICIDI DI COOP ITALIA ' },
    { value: 'SPRING', label: 'SPRING ' }
]

export const skillLevels = [
    { value: 'SUFFICIENTE', label: 'Sufficiente' },
    { value: 'DISCRETA', label: 'Discreta' },
    { value: 'BUONA', label: 'Buona' },
    { value: 'OTTIMA', label: 'Ottima' },
]

// countries.js

export const countries = [
    { label: "Afghanistan", value: "AF" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Brazil", value: "BR" },
    { label: "Brunei", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cabo Verde", value: "CV" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo, Democratic Republic of the", value: "CD" },
    { label: "Congo, Republic of the", value: "CG" },
    { label: "Costa Rica", value: "CR" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Eswatini", value: "SZ" },
    { label: "Ethiopia", value: "ET" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Greece", value: "GR" },
    { label: "Grenada", value: "GD" },
    { label: "Guatemala", value: "GT" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Honduras", value: "HN" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, North", value: "KP" },
    { label: "Korea, South", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Laos", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia", value: "FM" },
    { label: "Moldova", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "North Macedonia", value: "MK" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Qatar", value: "QA" },
    { label: "Romania", value: "RO" },
    { label: "Russia", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syria", value: "SY" },
    { label: "Taiwan", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Vatican City", value: "VA" },
    { label: "Venezuela", value: "VE" },
    { label: "Vietnam", value: "VN" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" }
];


export const headquartersOptions: OptionType[] = [
    { value: 'SC', label: 'SC' },
    { value: 'CL', label: 'CL' },
    { value: 'IS', label: 'IS' },
    { value: 'TV', label: 'TV' },
    { value: 'PU', label: 'PU' },
    { value: 'BA', label: 'BA' },
    { value: 'DG', label: 'DG' },
]

export const statusLiberatoria: OptionType[] = [
    { value: 'EVASA', label: 'Evasa' },
    { value: 'NON_EVASA', label: 'Non Evasa' }
]

export const yearOptions = (() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2015;
    return Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
        const year = currentYear - index;
        return { value: year.toString(), label: year.toString() };
    });
})();

export const typologyOptions: OptionType[] = [
    { value: 'CDX_DIPENDENTE', label: 'CDX Dipendente' },
    { value: 'CDX_C002', label: 'CDX C002' },
    { value: 'CDX_C008', label: 'CDX C008' },
    { value: 'CDX_C009', label: 'CDX C009' },
    { value: 'CDX_C010', label: 'CDX C010' },
    { value: 'CDX_CSI', label: 'CDX CSI' },
    { value: 'CDX_CDX_GG_017', label: 'CDX CDX_GG_017' },
]

export const officeOptions: OptionType[] = [
    { value: 'Amm', label: 'Amm - Amministratore' },
    { value: 'DG', label: 'DG - Direzione' },
    { value: 'UCO', label: 'UCO - Ufficio Controllo' },
    { value: 'UCE', label: 'UCE - Ufficio Certificazione' },
    { value: 'UST', label: 'UST - Segreteria Tecnica' },
    { value: 'UCA', label: 'UCA - Amministrazione' },
    { value: 'CL', label: 'Sede Calabria' },
    { value: 'IS', label: 'Sede Italia Settentrionale' }
]

export const revisioneOptions: OptionType[] = [
    { value: 'REVISIONE_0', label: 'Rev. 1' },
    { value: 'REVISIONE_1', label: 'Rev. 2' },
    { value: 'REVISIONE_2', label: 'Rev. 3' },
    { value: 'REVISIONE_3', label: 'Rev. 4' },
]

export const fiscalRegimeOptions: OptionType[] = [
    { value: 'DIPENDENTE', label: 'Dipendente' },
    { value: 'FORFETTARIO_CON_CASSA', label: 'Forfettario con cassa' },
    { value: 'FORFETTARIO_CON_CASSA_4_PERCENTO', label: 'Forfettario con cassa 4%' },
    { value: 'FORFETTARIO_CON_RIVALSA_INPS', label: 'Forfettario con rivalsa INPS' },
    { value: 'FORFETTARIO_SENZA_CASSA_E_RIVALSA_INPS', label: 'orfettario senza cassa e rivalsa INPS' },
    { value: 'MINIMO_CON_CASSA', label: 'Minimo con cassa' },
    { value: 'MINIMO_CON_CASSA_AL_4_PERCENTO', label: 'Minimo con cassa al 4%' },
    { value: 'MINIMO_CON_RIVALSA_INPS', label: 'Minimo con rivalsa INPS' },
    { value: 'PROFESSIONISTA_OCCASIONALE_ECCEDENTE_A_5000_EURO', label: 'Professionista occasionale eccedente a 5000 euro' },
    { value: 'PROFESSIONISTA_OCCASIONALE_INFERIORE_A_5000_EURO', label: 'Professionista occasionale inferiore a 5000 euro' },
    { value: 'PROFESSIONISTI_CON_REGIME_IVA_ORDINARIO', label: 'Professionisti con regime IVA ordinario' },
    { value: 'PROFESSIONISTI_CON_REGIME_IVA_ORDINARIO_CON_CASSA_AL_4_PERCENTO', label: 'Professionisti con regime IVA ordinario con cassa al 4%' },
    { value: 'PROFESSIONISTI_CON_REGIME_IVA_ORDINARIO_CON_RIVALSA_INPS', label: 'Professionisti con regime IVA ordinario con rivalsa INPS' },
]

export const resultOptions: OptionType[] = [
    { value: 'POSITIVO', label: 'Positivo' },
    { value: 'NEGATIVO', label: 'Negativo' }
]

export const activityOptions: OptionType[] = [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'AMMINISTRATORE', label: 'Amministratore' },
    { value: 'DIRETTORE_GENERALE', label: 'Direttore generale' },
    { value: 'RESPONSABILE_ASSICURAZIONE_QUALITA', label: 'Responsabile assicurazione qualità' },
    { value: 'RESPONSABILE_UFFICIO_CONTROLLO', label: 'Responsabile ufficio controllo' },
    { value: 'FUNZIONARI_UFFICIO_CONTROLLO', label: 'Funzionari ufficio controllo' },
    { value: 'RESPONSABILE_UFFICIO_CERTIFICAZIONE', label: 'Responsabile ufficio certificazione' },
    { value: 'FUNZIONARI_UFFICIO_CERTIFICAZIONE', label: 'Funzionari ufficio certificazione' },
    { value: 'RESPONSABILE_UFFICIO_SEGRETERIA_TECNICA', label: 'Responsabile ufficio segreteria tecnica' },
    { value: 'FUNZIONARI_UFFICIO_SEGRETERIA_TECNICA', label: 'Funzionari ufficio segreteria tecnica' },
    { value: 'FUNZIONARI_AMMINISTRAZIONE', label: 'Funzionari amministrazione' },
    { value: "MEMBRO_SUPPLENTE_DELL_ORGANO_COLLEGIALE_DI_DELIBERA", label: "Membro supplente dell'organo collegiale di delibera" },
    { value: 'VALUTARE_PROCESSO_DI_CONTROLLO', label: 'Valutare processo di controllo' },
    { value: 'RIESAME_PROCESSO_DI_CONTROLLO', label: 'Riesame processo di controllo' },
    { value: 'ORGANO_COLLEGIALE_DI_DELIBERA', label: 'Orango collegiale di delibera' },
    { value: 'COMITATO_SALVAGUARDIA_IMPARZIALITA', label: 'Comitato salvaguardia imparzialità' },
    { value: 'FORMATORE_INTRAZIENDALE_GLOBAL_GAP', label: 'Formatore intraziendale globalGAP' },
    { value: 'SCHEME_MANAGER', label: 'Scheme manager' },
    { value: 'COLLABORATORE_SCHEME_MANAGER', label: 'Collaboratore scheme manager' },
    { value: 'ATTIVITA_DI_RIESAME_DECISIONE', label: 'Attività di riesame decisione' },
    { value: 'RESPONSABILE_SEDE_TERRITORIALE', label: 'Responsabile sede territoriale' },
    { value: 'COLLABORATORE_SEDE_TERRITORIALE', label: 'Collaboratore sede territoriale' },
    { value: 'REFERENTE_REGIONALE', label: 'Referente regionale' },
    { value: 'GESTIONE_DATI_INFORMATICI', label: 'Gestione dati informatici' },
    { value: 'VERIFICATORE_VERIFICHE_ISPETTIVE_INTERNE', label: 'Verificatore verifiche ispettive interne' },
    { value: 'ORGANO_DI_VIGILANZA_d_legislativo_n_231_2001', label: 'Organo di vigilanza d legislativo n. 231 2001' },
    { value: 'PERSONALE_ISPETTIVO', label: 'Personale ispettivo' },
    { value: 'CLIENTE', label: 'Cliente' },
]

export const rolesOptions: OptionType[] = [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'AMMINISTRATORE', label: 'Amministratore' },
    { value: 'DIRETTORE_GENERALE', label: 'Direttore generale' },
    { value: 'RESPONSABILE_ASSICURAZIONE_QUALITA', label: 'Responsabile assicurazione qualità' },
    { value: 'RESPONSABILE_UFFICIO_CONTROLLO', label: 'Responsabile ufficio controllo' },
    { value: 'FUNZIONARI_UFFICIO_CONTROLLO', label: 'Funzionari ufficio controllo' },
    { value: 'RESPONSABILE_UFFICIO_CERTIFICAZIONE', label: 'Responsabile ufficio certificazione' },
    { value: 'FUNZIONARI_UFFICIO_CERTIFICAZIONE', label: 'Funzionari ufficio certificazione' },
    { value: 'RESPONSABILE_UFFICIO_SEGRETERIA_TECNICA', label: 'Responsabile ufficio segreteria tecnica' },
    { value: 'FUNZIONARI_UFFICIO_SEGRETERIA_TECNICA', label: 'Funzionari ufficio segreteria tecnica' },
    { value: 'FUNZIONARI_AMMINISTRAZIONE', label: 'Funzionari amministrazione' },
    { value: "MEMBRO_SUPPLENTE_DELL_ORGANO_COLLEGIALE_DI_DELIBERA", label: "Membro supplente dell'organo collegiale di delibera" },
    { value: 'VALUTARE_PROCESSO_DI_CONTROLLO', label: 'Valutare processo di controllo' },
    { value: 'RIESAME_PROCESSO_DI_CONTROLLO', label: 'Riesame processo di controllo' },
    { value: 'ORGANO_COLLEGIALE_DI_DELIBERA', label: 'Orango collegiale di delibera' },
    { value: 'COMITATO_SALVAGUARDIA_IMPARZIALITA', label: 'Comitato salvaguardia imparzialità' },
    { value: 'FORMATORE_INTRAZIENDALE_GLOBAL_GAP', label: 'Formatore intraziendale globalGAP' },
    { value: 'SCHEME_MANAGER', label: 'Scheme manager' },
    { value: 'COLLABORATORE_SCHEME_MANAGER', label: 'Collaboratore scheme manager' },
    { value: 'ATTIVITA_DI_RIESAME_DECISIONE', label: 'Attività di riesame decisione' },
    { value: 'RESPONSABILE_SEDE_TERRITORIALE', label: 'Responsabile sede territoriale' },
    { value: 'COLLABORATORE_SEDE_TERRITORIALE', label: 'Collaboratore sede territoriale' },
    { value: 'REFERENTE_REGIONALE', label: 'Referente regionale' },
    { value: 'GESTIONE_DATI_INFORMATICI', label: 'Gestione dati informatici' },
    { value: 'VERIFICATORE_VERIFICHE_ISPETTIVE_INTERNE', label: 'Verificatore verifiche ispettive interne' },
    { value: 'ORGANO_DI_VIGILANZA_d_legislativo_n_231_2001', label: 'Organo di vigilanza d legislativo n. 231 2001' },
    { value: 'PERSONALE_ISPETTIVO', label: 'Personale ispettivo' },
    { value: 'CLIENTE', label: 'Cliente' },
]

export const valutatoreOptions: OptionType[] = [
    { value: 'Fasano Antonino', label: 'Fasano Antonino' },
    { value: `D'Agosta Francesco`, label: `D'Agosta Francesco` }
]

export const trainingActivityOptions: OptionType[] = [
    { value: 'AUDIT', label: 'Audit' },
    { value: 'CORSO_FORMATIVO_E_DI_ADDESTRAMENTO', label: 'Corso formativo e di addestramento' },
    { value: 'ATTIVITA_FORMATIVA_TEORICA', label: 'Attività formativa teorica' },
    { value: 'ATTIVITA_FORMATIVA_TEORICA_INTERNA', label: 'Attività formativa teorica interna' },
    { value: 'ATTIVITA_FORMATIVA_TEORICA_E_PRATICA', label: 'Attività formativa teorica e pratica' },
    { value: 'FORMAZIONE_E_ADDESTRAMENTO_SUL_PROGRAMMA_CODINFO', label: 'Formazione e addestramento sul programma Codinfo' },
    { value: 'RAFFORZAMENTO_DELL_ATTIVITA_FORMATIVA_TEORICA', label: `Rafforzamento dell'attività formativa teorica` },
    { value: 'SEMINARIO_DI_AGGIORNAMENTO_E_ADDESTRAMENTO_PER_I_RESPONSABILI_REGIONALI', label: 'Seminario di aggiornamento e addestramento per i responsabili regionali' },
    { value: 'CORSO_INTERNO_PER_IL_CORPO_ISPETTIVO', label: 'Corso interno per il corpo ispettivo' },
    { value: 'ADDESTRAMENTO_IN_CAMPO_CON_TECNICO_ISPETTORE', label: 'Addestramento in campo con tecnico ispettore' },
    { value: 'AUDIT_E_ADDESTRAMENTO_INTERNO', label: 'Audit e addestramento interno' },
    { value: 'ATTIVITA_FORMATIVA_TEORICA_MIRATA_ALLE_CRITICITA_SPECIFICHE', label: 'Attività formativa teorica mirata alle criticità specifiche' },
    { value: 'VISITE_ISPETTIVE_PRESSO_OPERATORI_CODEX_AUDITING', label: 'Visite ispettive presso operatori Codex auditing' },
    { value: 'VISITA_ISPETTIVA_IN_AFFIANCAMENTO', label: 'Visita ispettiva in affiancamento' },
    { value: 'AGGIORNAMENTO_AL_CORPO_ISPETTIVO', label: 'Aggiornamento al corpo ispettivo' },
    { value: 'AGGIORNAMENTO_AL_PERSONALE_DELLA_SEGRETERIA_TECNICA', label: 'Aggiornamento al personale della segreteria tecnica' },
    { value: 'AGGIORNAMENTO_AL_PERSONALE_DELL_UFFICIO_CONTROLLO', label: `Aggiornamento al personale dell'ufficio controllo` },
    { value: 'AGGIORNAMENTO_AL_PERSONALE_DELL_UFFICIO_CERTIFICAZIONE', label: `Aggiornamento al personale dell'ufficio certificazione` },
    { value: 'ATTIVITA_FORMATIVA_TEORICA_E_PRATICA_INDIVIDUALE', label: 'Attività formativa teorica e pratica individuale' },
    { value: 'ATTIVITA_FORMATIVA_TEORICA_E_PRATICA_PERSONALE_CODEX', label: 'Attività formativa teorica e pratica personale Codex' },
]

export enum WebinarOptions {
    WEBINAR = 'WEBINAR',
    SEDE = 'SEDE',
}

export const webinarOptionsValue: OptionType[] = [
    { value: WebinarOptions.WEBINAR, label: 'Webinar' },
    { value: WebinarOptions.SEDE, label: 'In Presenza' }
]

export const entryMeansOptions: OptionType[] = [
    { value: 'POSTA_PRIORITARIA', label: 'Posta Prioritaria' },
    { value: 'RACCOMANDATA', label: 'Raccomandata' },
    { value: 'RACCOMANDATA_A_MANO', label: 'Raccomandata a mano' },
    { value: 'RACCOMANDATA_1', label: 'Raccomandata 1' },
    { value: 'CORRIERE', label: 'Corriere' },
    { value: 'POSTA_ELETTRONICA', label: 'Posta Elettronica' },
    { value: 'POSTA_ELETTRONICA_CERTIFICATA', label: 'Posta Elettronica Certificata' },
    { value: 'FAX', label: 'Fax' },
    { value: 'WEB', label: 'Web' },
]

export const destinationOffice: OptionType[] = [
    { value: 'DR', label: 'DR' },
    { value: 'MPC', label: 'MPC' },
    { value: 'Amm', label: 'Amm' },
    { value: 'SM', label: 'SM' },
    { value: 'UCE', label: 'UCE' },
    { value: 'UCO', label: 'UCO' },
    { value: 'UST', label: 'UST' },
    { value: 'UCA', label: 'UCA' },
    { value: 'IS', label: 'IS' },
    { value: 'PU', label: 'PU' },
    { value: 'BA', label: 'BA' },
    { value: 'IS', label: 'IS' },
    { value: 'CL', label: 'CL' },
    { value: 'SC', label: 'Sc' },
]

export enum DestinationOfficesType {
    DIREZIONE = 'DR',
    MON_PEC_DIREZIONE = 'MPC',
    AMM_UNICO = 'Amm',
    SCHEME_MANAGER_GG = 'SM',
    UFFICIO_CERT = 'UCE',
    UFFICIO_CONTR = 'UCO',
    UFFICIO_SEGR_TEC = 'UST',
    UFFICIO_CONT_AMM = 'UCA',
    SOT_IS = 'IS',
    SOT_PU = 'PU',
    SOT_BA = 'BA',
    SOT_CL = 'CL'
}

export const departureMeansOptions: OptionType[] = [
    { value: 'POSTA_PRIORITARIA', label: 'Posta Prioritaria' },
    { value: 'RACCOMANDATA', label: 'Raccomandata' },
    { value: 'RACCOMANDATA_A_MANO', label: 'Raccomandata a mano' },
    { value: 'RACCOMANDATA_1', label: 'Raccomandata 1' },
    { value: 'CORRIERE', label: 'Corriere' },
    { value: 'POSTA_ELETTRONICA', label: 'Posta Elettronica' },
    { value: 'POSTA_ELETTRONICA_CERTIFICATA', label: 'Posta Elettronica Certificata' },
    { value: 'FAX', label: 'Fax' },
]

export enum DeliveryManagementType {
    PEC = 'PEC',
    RACC_AR = 'RACC_AR',
    CORRIERE = 'CORRIERE',
    RACC_M = 'RACC_M',
    POST_E = 'PE'
}

export const EPAttachmentGroupOptions: OptionType[] = [
    { value: '1-3-12', label: 'Group #1' },
    { value: '2-5', label: 'Group #2' },
    { value: '14-28', label: 'Group #3' },
    { value: '3-15-24', label: 'Group #4' },
    { value: '1-2-3', label: 'Group #5' },
]

export const PPAttachmentGroupOptions: OptionType[] = [
    { value: '1-3-12', label: 'Group #1' },
    { value: '2-5', label: 'Group #2' },
    { value: '14-28', label: 'Group #3' },
    { value: '3-15-24', label: 'Group #4' },
    { value: '1-2-3', label: 'Group #5' },
]

export interface ProtocolDataBanner {
    protocol: string
    date: Date
}

export enum TrainingTypes {
    MANUALE = 'FORMAZIONE_TEORICA_SUL_MANUALE_CODEX',
    NORMATIVA = 'FORMAZIONE_TEORICA_SULLA_NORMATIVA',
    PRATICA = 'FORMAZIONE_PRATICA'
}

export const ReportingTypologyOption: OptionType[] = [
    { value: ReportingType.OFIS, label: 'OFIS' },
    { value: ReportingType.PRODOTTO_NON_CONFORME, label: 'Prodotto non conforme' },
    { value: ReportingType.ANALISI_CON_ESITO_NON_CONFORME, label: 'Analisi con esito non conforme' },
    { value: ReportingType.ANALISI_CON_ESITO_CONFORME_CON_PRESENZA_DI_PRINCIPI_ATTIVI_NON_AMMESSI, label: 'Analisi con esito conforme con presenza di principi attivi non ammessi' },
    { value: ReportingType.VERIFICA_AUTORITA_DI_CONTROLLO, label: 'Verifica Autorità di Controllo' },
    { value: ReportingType.VERIFICA_ORGANISMO_DI_CONTROLLO, label: 'Verifica Organismo di Controllo' },
    { value: ReportingType.ALTRO, label: 'Altro' }
]

export const ReportingStatusOption: OptionType[] = [
    { value: ReportingInnerState.APERTA, label: 'Aperta' },
    { value: ReportingInnerState.IN_GESTIONE, label: 'In gestione' },
    { value: ReportingInnerState.CHIUSA, label: 'Chiusa' }
]

export const ReportingStateStatusOption: OptionType[] = [
    { value: ReportingOuterState.PRESA_IN_CARICO_DELLA_RICHIESTA, label: 'Presa in carico della richiesta' },
    { value: ReportingOuterState.PRESA_IN_CARICO_DELLA_RICHIESTA_E_COMUNICAZIONE_AL_MITTENTE, label: 'Presa in carico della richiesta e comunicazione al mittente' },
    { value: ReportingOuterState.COMUNICAZIONE_AL_MITTENTE, label: 'Comunicazione al mittente' },
    { value: ReportingOuterState.RICHIESTA_INTEGRAZIONE_DOCUMENTI, label: 'Richiesta Integrazione Documenti' },
    { value: ReportingOuterState.SOPPRESSIONE_CAUTELATIVA, label: 'Soppressione cautelativa' },
    { value: ReportingOuterState.PROVVEDIMENTO_DEFINITIVO, label: 'Provvedimento definitivo' },
    { value: ReportingOuterState.VISITA_ISPETTIVA, label: 'Visita Ispettiva' },
    { value: ReportingOuterState.CHIUSURA_SEGNALAZIONE_E_RISPOSTA_AL_MITTENTE, label: 'Chiusura Segnalazione e Risposta al Mittente' },
]

// BUSINESS REGISTER OPTIONS
export const BusinessRegisterTypologyRequestOptions: OptionType[] = [
    { value: BusinessRegisterRequestType.CARTACEI, label: 'Cartacei' },
    { value: BusinessRegisterRequestType.INFORMATIZZATI, label: 'Informatizzati' },
    { value: BusinessRegisterRequestType.ACCESSO_CODINFO_CLIENTI, label: 'Accesso Codinfo Clienti' },
]

export const BusinessRegisterRequestMotivationOptions: OptionType[] = [
    { value: BusinessRegisterRequestReason.EMISSIONE_PER_CLIENTE_IN_FASE_DI_AVVIO, label: 'Emissione per Cliente in Fase di avvio' },
    { value: BusinessRegisterRequestReason.EMISSIONE_A_SEGUITO_NOTIFICA_VARIAZIONE, label: 'Emissione a seguito Notifica Variazione' },
    { value: BusinessRegisterRequestReason.RICHIESTA_PER_REGISTRI_ESAURITI, label: 'Richiesta per Registri Esauriti' },
    { value: BusinessRegisterRequestReason.RICHIESTA_PER_REGISTRI_SMARRITI, label: 'Richiesta per Registri Smarriti' },
    { value: BusinessRegisterRequestReason.RICHIESTA_REGISTRI_INFORMATIZZATI, label: 'Emissione Regitri Informatizzati' },
]
