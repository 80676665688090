import axios from "axios";
import { GetAllReportingsDTO, GetAllReportingsFiltersDTO, GetAllReportingStateFIltersDTO, GetAllReportingStatesDTO, ReportingCreationDTO, ReportingDTO, ReportingStateCreationUpdateDTO, ReportingStateDTO, ReportingUpdateDTO } from "./dto";
import { ReportingService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class ReportingServiceImpl implements ReportingService {
    
    /* REPORTING */

    public CreateReporting(data: ReportingCreationDTO): Promise<string> {
        return axios.post("/api/reporting",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateReporting(data: ReportingUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/reporting/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteReporting(id: string): Promise<void> {
        return axios.delete("/api/reporting/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllReportings(filters: GetAllReportingsFiltersDTO): Promise<GetAllReportingsDTO> {
        return axios.get("/api/reporting", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId,
                headQuarter: filters.headquarter,
                reportingState: filters.reportingState,
                year: filters.year
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetReportingById(id: string): Promise<ReportingDTO> {
        return axios.get("/api/reporting/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* REPORTING STATE */

    public CreateReportingState(data: ReportingStateCreationUpdateDTO): Promise<void> {
        return axios.post("/api/reporting-state",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateReportingState(data: ReportingStateCreationUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/reporting-state/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteReportingState(id: string): Promise<void> {
        return axios.delete("/api/reporting-state/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllReportingStates(filters: GetAllReportingStateFIltersDTO): Promise<GetAllReportingStatesDTO> {
        return axios.get("/api/reporting-state", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                reportingId: filters.reportingId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetReportingStateById(id: string): Promise<ReportingStateDTO> {
        return axios.get("/api/reporting-state/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}