
import { createWaiverToDTO, GetAllWaiverToFiltersDTO, GetAllWaiverToResponse, updateWaiverToDTO } from "./dto"
import { WaiverToCodexServiceImpl } from "./serviceImpl"

export interface WaiverToCodexService {
    GetAllWaiverToCodex(filters: GetAllWaiverToFiltersDTO): Promise<GetAllWaiverToResponse>
    GetWaiverToExcel(filters: GetAllWaiverToFiltersDTO): Promise<any>
    CreateWaiverToCodex(data: createWaiverToDTO): Promise<void>
    UpdateWaiverToCodex(data: updateWaiverToDTO, id: string): Promise<void>

}

export function NewWaiverToCodexService(): WaiverToCodexService {
    return new WaiverToCodexServiceImpl()
}