import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { Banner } from "../../../../ui/organisms/banner"
import Button from "../../../../ui/molecules/button"
import { AddRegistroForms } from "./forms"
import { ClientBanner } from "../../../../ui/molecules/clientBanner"
import { attivita_bio, normativa, optionsFormatter } from "../../../../utils"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { AddRegistroRight } from "./right"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { useEffect, useState } from "react"
import { resetAddedRegisterCreationRequest, resetBusinessRegisterCreationRequest, resetBusinessRegisterCreationStatus, resetBusinessRegisterDeleteStatus, resetBusinessRegisterErrors, resetBusinessRegisterUpdateStatus, resetGetAllAddedRegistersResponse, setBusinessRegisterCreationOfficerId, setBusinessRegisterCreationUserId, setFromCreationToUpdate } from "../slice"

export function AddRegistroAziendale() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.users)
    const registerState = useAppSelector(state => state.register)
    const sessionState = useAppSelector(state => state.session)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        dispatch(resetBusinessRegisterErrors())
        dispatch(resetAddedRegisterCreationRequest())
        dispatch(resetGetAllAddedRegistersResponse())
        dispatch(resetBusinessRegisterCreationRequest())
        dispatch(resetBusinessRegisterCreationStatus())
        dispatch(setFromCreationToUpdate(false))
        dispatch(setBusinessRegisterCreationUserId(sessionState.selected.customerUserId))
        dispatch(setBusinessRegisterCreationOfficerId(userState.findMeResponse.id))
    }, [])

    useEffect(() => {
        if(registerState.businessRegisterCreationStatus === 'successfully' ||
            registerState.businessRegisterUpdateStatus === 'successfully'
        ) {
            dispatch(resetBusinessRegisterUpdateStatus())
            setShowSuccess(true)
        }
    }, [registerState.businessRegisterCreationStatus,
        registerState.businessRegisterUpdateStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    if (sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Clienti', 'Registri Aziendali']}
                noPaddingLat
                headerLabel="Registri Aziendali"
                headerIcon={
                    <button style={{paddingTop: '12px'}} onClick={() => navigate('/registriAziendali')}>
                        <ArrowLeftIcon />
                    </button>
                }
            >
                <div style={{display: 'flex', gap: '12px', minHeight: window.innerHeight}}>
                    <div style={{flex: '0.6', padding: '24px'}}>
                        <div className="pb-5">
                            <ClientBanner
                                region={sessionState.selected.customerRegion!}
                                client={sessionState.selected.customerName!}
                                code={sessionState.selected.customerCode!}
                                activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                                norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                            />
                        </div>
                        {
                            showSuccess &&
                            <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                                <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                    Dati salvati correttamente.
                                </span>
                            </div>
                        }
                        <AddRegistroForms />
                    </div>
                    <div className="bg-brandPrimary-100" style={{flex: '0.4'}}>
                        <RightBar disabled={!registerState.fromCreationToUpdate}>
                            <AddRegistroRight />
                        </RightBar>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
            noPaddingLat
            headerLabel={"Registri Aziendali"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}