import { formaGiuridica, optionsFormatter } from '../../../../utils';
import { ContactsBoxProps } from './contacts.types';
import './styles.scss';

export const ContactsBox: React.FC<ContactsBoxProps> = ({
    ...props
}) => {
    return (
        <div className="pre-box">
            <div className="profile-box">
                <div className="title">Rubrica</div>

                <div className="profile-content">
                    <div className="profile-data">
                        <div className="content-bold">Telefono</div>
                        <div className="content">{props.contacts?.phone}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Telefono Ufficio</div>
                        <div className="content">{props.contacts?.officePhone}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Cellulare</div>
                        <div className="content">{props.contacts?.mobilePhone}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Email</div>
                        <div className="content">{props.contacts?.email}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Email Personale</div>
                        <div className="content">{props.contacts?.personalEmail}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">PEC</div>
                        <div className="content">{props.contacts?.pec}</div>
                    </div>

                    <div className="line"></div>

                    <div className="profile-data">
                        <div className="content-bold">Note</div>
                        <div className="content">{props.contacts?.note}</div>
                    </div>

                </div>
            </div>
        </div>
    );
}
