import { useNavigate } from "react-router-dom"
import Button from "../../../../ui/molecules/button"
import { useEffect, useState } from "react"
import { EditIcon } from "../../../../ui/icons/edit"
import { PopupForm } from "../../../../ui/organisms/popupForm"
import { EditLiberatoria } from "../edit"
import { AddLiberatoria } from "../add"
import { SelectCustom } from "../../../../ui/molecules/select"
import { formatterDate, statusLiberatoria, yearOptions } from "../../../../utils"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { GetAllWaiverFromCodex, resetWaiverFromFilters, resetWaiverFromCreateStatus, resetWaiverFromRequest, resetWaiverFromUpdateStatus, setFromAllowedEntryProtocolId, setFromBreakoutDate, setFromCompletePassing, setFromDeniedEntryProtocolId, setFromFieldsPassing, setFromNote, setFromOdc, setFromWaiverDepartureProtocol, setFromCustomerId, setFromSendingDossier, setFromSenderOrReceiver, setFromIdWaiver, setFromProtocolDate, setFromExpirationDate, setYearFilter, setStatusFilter, setFromSubmissionNotificationOdcDate, setFromNotificationSubmissionProtocolId, GetWaiverFromExcel } from "./dataService/slice"
import clsx from "clsx"
import { setSenderOrReceiver } from "../liberatorieToCodex/dataService/slice"
import { SpinnerComponent } from "../../../../lib/spinner"


export function LiberatorieFromCodex() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const waiverFromState = useAppSelector(state => state.waiverFromCodex)

    useEffect(() => {
        const currentYear = new Date().getFullYear().toString()
        dispatch(GetAllWaiverFromCodex({
            page: 0,
            itemsPerPage: 0,
            year: currentYear
        },))
    }, [waiverFromState.getAllWaiverFromFilters])

    useEffect(() => {
        if (waiverFromState.CreateWaiverFromCodexStatus === 'successfully') {
            dispatch(GetAllWaiverFromCodex(waiverFromState.getAllWaiverFromFilters))
            dispatch(resetWaiverFromUpdateStatus())
            setShowPopupAdd(false)
            dispatch(resetWaiverFromRequest())
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [waiverFromState.CreateWaiverFromCodexStatus])

    useEffect(() => {
        if (waiverFromState.UpdateWaiverFromCodexStatus === 'successfully') {
            dispatch(GetAllWaiverFromCodex(waiverFromState.getAllWaiverFromFilters))
            dispatch(resetWaiverFromCreateStatus())
            setShowPopup(false)
            dispatch(resetWaiverFromRequest())
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [waiverFromState.UpdateWaiverFromCodexStatus])

    const handleOnEditFrom = (w: any) => {
        dispatch(setFromCustomerId(w.customerId))
        dispatch(setFromBreakoutDate(w.breakoutDate))
        dispatch(setFromCompletePassing(w.completePassing))
        dispatch(setFromDeniedEntryProtocolId(w.deniedEntryProtocolId))
        dispatch(setFromAllowedEntryProtocolId(w.allowedEntryProtocolId))
        dispatch(setFromSenderOrReceiver(w.waiverInnerInfo.senderOrReceiver))
        dispatch(setFromFieldsPassing(w.fieldsPassing))
        dispatch(setFromNote(w.note))
        dispatch(setFromOdc(w.odcReference))
        dispatch(setFromWaiverDepartureProtocol(w.departureProtocolId))
        dispatch(setFromSendingDossier(w.sendingDossier))
        dispatch(setFromProtocolDate(w.waiverInnerInfo.protocolDate))
        dispatch(setFromExpirationDate(w.expirationDate))
        dispatch(setFromSubmissionNotificationOdcDate(w.submissionNotificationDate))
        dispatch(setFromNotificationSubmissionProtocolId(w.notificationSubmissionProtocolId))
        dispatch(setFromIdWaiver(w.id))
        setShowPopup(true)
    }

    if (waiverFromState.GetAllWaiverFromCodexStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    if (waiverFromState.downloadExcelStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <div style={{ padding: '24px' }}>
            {
                (showSuccess) &&

                <div className="gap-[8px] text-left bg-green-300" style={{ margin: '18px', borderRadius: '8px' }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.2' }} className="text-left">
                        <SelectCustom
                            label="Anno"
                            key={waiverFromState.getAllWaiverFromFilters.year || 'default'}
                            placeholder={'Seleziona Anno'}
                            value={waiverFromState.getAllWaiverFromFilters.year}
                            options={yearOptions}
                            onChange={(value) => dispatch(setYearFilter(value))}
                        />
                    </div>
                    <div style={{ flex: '0.2' }} className="text-left">
                        <SelectCustom
                            label="Stato"
                            key={waiverFromState.getAllWaiverFromFilters.status || 'default'}
                            placeholder={'Seleziona Stato'}
                            value={waiverFromState.getAllWaiverFromFilters.status}
                            options={statusLiberatoria}
                            onChange={(value) => dispatch(setStatusFilter(value))}
                        />
                    </div>

                    <div style={{ alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <span>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={() => dispatch(resetWaiverFromFilters())}
                            />
                        </span>
                    </div>

                    <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <Button
                            size={"lg"}
                            iconPosition={"off"}
                            variant={"outline"}
                            label="Cerca"
                            color={"blue"}
                            onClick={() => dispatch(GetAllWaiverFromCodex(waiverFromState.getAllWaiverFromFilters))}
                        />
                    </div>

                    <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <Button
                            size={"lg"}
                            iconPosition={"off"}
                            variant={"outline"}
                            label="Scarica Excel"
                            color={"blue"}
                            onClick={() => dispatch(GetWaiverFromExcel(waiverFromState.getAllWaiverFromFilters))}
                        />
                    </div>

                    <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                        <Button
                            size="md"
                            iconPosition="off"
                            variant="outline"
                            color="orange"
                            label="Aggiungi Richiesta"
                            onClick={() => setShowPopupAdd(true)}
                        />
                    </div>
                </div>

                <table id="list-table" className="w-[100%] mt-6">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Sede</th>
                            <th>Data Protocollo</th>
                            <th>Numero Protocollo</th>
                            <th>Destinatario</th>
                            <th>Regione</th>
                            <th>Provincia</th>
                            <th>Cliente</th>
                            <th>Cod. Cliente</th>
                            <th>Recesso</th>
                            <th>Scadenza</th>
                            <th>Evaso</th>
                            <th>Tipologia Liberatoria</th>
                            <th>Protocollo Liberatoria Negata/Concessa</th>
                            <th>Note</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            waiverFromState.getAllWaiverToResponse?.data.map((w) =>
                                <tr
                                    className=" h-[52px]  border-b-[1px] border-b-neutral-200"

                                >
                                    <td className="text-blue-800 robotoBold text-left">
                                        {w.waiverInnerInfo.headQuarter}
                                    </td>
                                    <td className="text-neutral-700" style={{ fontFamily: 'Manrope', fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>
                                        {formatterDate(w.waiverInnerInfo.protocolDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.protocolNumber}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.senderOrReceiver}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.region}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.province}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.companyName}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.clientCode}
                                    </td>
                                    <td className="text-neutral-700">
                                        {formatterDate(w.waiverInnerInfo.endingDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {formatterDate(w.expirationDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {
                                            statusLiberatoria.filter(({ value }) => value === w.breakoutType).map(el =>
                                                <span key={el.value} className={clsx("labelStatus", { "nonEvasa": w.breakoutType === 'NON_EVASA', "evasa": w.breakoutType === 'EVASA' })}> {el.label}</span>
                                            )
                                        }
                                    </td>
                                    <td>
                                        Liberatoria per cambio OdC
                                    </td>
                                    <td>
                                        {
                                            w.allowedEntryProtocolId && (
                                                <span>{w.allowedEntryProtocolInfo}</span>
                                            )
                                        }
                                        {
                                            w.deniedEntryProtocolId && (
                                                <span>{w.deniedEntryProtocolInfo}</span>
                                            )
                                        }
                                    </td>
                                    <td>
                                        {w.note}
                                    </td>
                                    <td className="w-[20px] cursor-pointer">
                                        <div className="flex justify-content-between align-items-center">
                                            <button
                                                onClick={() => {
                                                    handleOnEditFrom(w)

                                                }}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>

                                        </div>

                                    </td>
                                </tr>
                            )
                        }
                    </tbody>

                </table>
                {
                    showPopup && (
                        <PopupForm title="Modifica Liberatoria" close={() => { setShowPopup(false); dispatch(resetWaiverFromRequest()) }}>
                            <EditLiberatoria close={() => { setShowPopup(false); dispatch(resetWaiverFromRequest()) }} type="from" />
                        </PopupForm>
                    )
                }
                {
                    showPopupAdd && (
                        <PopupForm title="Crea liberatoria richiestada Codex" close={() => { setShowPopupAdd(false); dispatch(resetWaiverFromRequest()) }}>
                            <AddLiberatoria close={() => { setShowPopupAdd(false); dispatch(resetWaiverFromRequest()) }} type="from" />
                        </PopupForm>
                    )
                }

            </div>
        </div>
    )
}