import { IconProps } from "../dto";
import './styles.scss'

interface Props {
    animation?: boolean
}

export function LogoIcon(props: Props) {
    return (
        <div className={props.animation === true ? "logo-container-animation" : undefined}>
            <svg width="110" height="30" viewBox="0 0 110 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_688_10459)">
                    <path d="M20.5039 25.6288C20.5354 26.1948 20.2838 26.4464 19.7806 26.5722C19.1517 26.7295 17.4849 26.981 15.6924 26.981C11.0696 26.981 9.18274 25.2514 9.18274 20.4399V16.1945C9.18274 11.4144 11.0696 9.65332 16.0383 9.65332C17.6422 9.65332 19.3404 9.84201 19.9693 10.0307C20.3781 10.1565 20.6297 10.2508 20.6297 10.9741C20.5668 11.7603 20.4096 13.0182 19.8121 13.0182C19.4033 12.9553 17.7994 12.6723 16.3843 12.6723C13.7427 12.6723 12.7363 13.7415 12.7363 16.2573V20.3456C12.7363 22.8299 13.7112 23.9306 16.4157 23.9306C17.4221 23.9306 18.9001 23.7734 19.8435 23.6476C20.4096 23.6476 20.4725 24.6225 20.5039 25.6288Z" fill="#F26F21" />
                    <path d="M22.0973 15.534C22.0973 11.0056 24.0471 9.65332 28.8271 9.65332H28.9843C33.8273 9.65332 35.7771 11.0056 35.7771 15.534V21.1003C35.7771 25.6288 33.8273 26.981 28.9843 26.981H28.8271C24.0156 26.981 22.0973 25.6288 22.0973 21.1003V15.534ZM29.0472 23.8363C31.5002 23.8363 32.2235 23.0501 32.2235 21.1318V15.534C32.2235 13.5214 31.5002 12.7981 29.0472 12.7981H28.7957C26.3742 12.7981 25.6194 13.5214 25.6194 15.534V21.1318C25.6194 23.113 26.3427 23.8363 28.7642 23.8363H29.0472Z" fill="#F26F21" />
                    <path d="M51.1132 24.6851C51.1132 25.314 51.0503 25.5656 50.4842 25.8801C49.4464 26.4147 46.4903 26.9808 44.1946 26.9808C38.5655 26.9808 38.0309 24.2448 38.0309 21.0686V15.7539C38.0309 12.7978 38.7856 9.65305 43.1569 9.65305C44.6664 9.65305 46.3645 10.0619 47.5596 10.5021V3.64653C47.5596 3.08047 49.5408 3.01758 50.1697 3.01758C50.7358 3.01758 51.1132 3.26916 51.1132 4.05535V24.6851ZM47.5596 13.584C47.0878 13.3324 45.5783 12.8293 44.2261 12.8293C41.8361 12.8293 41.5845 14.3073 41.5845 16.0684V20.7856C41.5845 22.6724 41.8361 24.0247 44.4148 24.0247C45.5469 24.0247 47.1507 23.7731 47.5596 23.5844V13.584Z" fill="#F26F21" />
                    <path d="M55.9498 26.9811C53.5283 26.9811 53.8701 25.849 53.8701 22.547V10.2509C53.8701 9.71633 55.8827 9.65344 56.4802 9.65344C56.9834 9.65344 57.4237 9.90502 57.4237 10.4082V25.3291C57.4237 26.4927 56.7675 26.9791 55.9498 26.9791V26.9811ZM53.9015 3.77271C53.9015 3.2381 55.7884 3.1123 56.4802 3.1123C57.0148 3.1123 57.4551 3.39533 57.4551 3.96139V6.57156C57.4551 7.13762 57.0148 7.42065 56.4802 7.42065C55.7884 7.42065 53.9015 7.29486 53.9015 6.76024V3.77271Z" fill="#71BE44" />
                    <path d="M69.8182 15.1252C69.8182 13.5528 69.4723 12.9239 67.7741 12.9239C66.2017 12.9239 63.7488 13.6472 63.7488 13.9931V26.1948C63.7488 26.7609 63.2771 26.981 62.7739 26.981C62.1135 26.981 60.1952 26.8867 60.1952 26.3521V12.3893C60.1952 11.2257 64.3148 9.65332 68.4031 9.65332C72.4913 9.65332 73.3718 11.4773 73.3718 15.251V26.1948C73.3718 26.7609 72.8372 26.981 72.3655 26.981C71.6736 26.981 69.8182 26.8867 69.8182 26.3521V15.1252Z" fill="#71BE44" />
                    <path d="M81.3281 9.99898H84.693C85.039 9.99898 85.3534 10.1877 85.3534 10.8481C85.3534 11.3512 85.1962 13.1752 84.6616 13.1752H81.3281V26.1946C81.3281 26.7921 80.6363 26.9808 80.1646 26.9808C79.0953 26.9808 77.7745 26.9808 77.7745 26.3518V13.1752H75.6675C75.2902 13.1752 75.0386 12.8293 75.0386 12.4834C75.0386 11.8229 75.1644 9.99898 75.699 9.99898H77.7745V7.35737C77.7745 4.49562 79.567 3.01758 82.8062 3.01758C83.9697 3.01758 84.8817 3.20626 85.5107 3.39495C86.0138 3.55219 86.2025 4.02391 86.2025 4.65286C86.2025 5.5334 85.9509 6.57117 85.4478 6.53973C84.8817 6.35104 84.4415 6.1938 83.3722 6.1938C82.2087 6.1938 81.3281 6.41394 81.3281 7.82908V9.99898Z" fill="#71BE44" />
                    <path d="M86.6951 15.534C86.6951 11.0056 88.6448 9.65332 93.4249 9.65332H93.5821C98.4251 9.65332 100.375 11.0056 100.375 15.534V21.1003C100.375 25.6288 98.4251 26.981 93.5821 26.981H93.4249C88.6134 26.981 86.6951 25.6288 86.6951 21.1003V15.534ZM93.645 23.8363C96.098 23.8363 96.8212 23.0501 96.8212 21.1318V15.534C96.8212 13.5214 96.098 12.7981 93.645 12.7981H93.3934C90.972 12.7981 90.2172 13.5214 90.2172 15.534V21.1318C90.2172 23.113 90.9405 23.8363 93.362 23.8363H93.645Z" fill="#71BE44" />
                    <path d="M0 2.88167C0 1.7244 0.93924 0.785156 2.09652 0.785156C3.68987 0.785156 5.47191 0.785156 5.47191 0.785156C5.91218 0.785156 6.19521 1.16253 6.19521 1.6028C6.19521 2.2632 6.10086 3.89848 5.62915 3.89848H3.52215V26.1006H5.6606C6.10086 26.1006 6.19521 27.7988 6.19521 28.4592C6.19521 28.868 5.84928 29.2139 5.40901 29.2139H2.09652C0.93924 29.2139 0 28.2747 0 27.1174V2.88167Z" fill="#8C2C66" />
                    <path d="M109.558 27.1174C109.558 28.2747 108.619 29.2139 107.461 29.2139C105.868 29.2139 104.086 29.2139 104.086 29.2139C103.646 29.2139 103.363 28.8366 103.363 28.3963C103.363 27.7359 103.457 26.1006 103.929 26.1006H106.036V3.89848H103.897C103.457 3.89848 103.363 2.20031 103.363 1.5399C103.363 1.13108 103.708 0.785156 104.149 0.785156H107.461C108.619 0.785156 109.558 1.7244 109.558 2.88167V27.1174Z" fill="#8C2C66" />
                </g>
                <defs>
                    <clipPath id="clip0_688_10459">
                        <rect width="109.558" height="28.4288" fill="white" transform="translate(0 0.785156)" />
                    </clipPath>
                </defs>
            </svg>
        </div>

    )
}