import { useEffect, useState } from "react"
import { OptionType } from "../../../utils"
import { SelectCustom } from "../select"
import { NewDepartureProtocolService } from "../../../pages/protocol/departure/dataService/service"
import { NewEntryProtocolService } from "../../../pages/protocol/entry/dataService/service"
import './styles.scss'

interface Props {
    customerId: string
    fromDate?: Date
    defaultValue?: string
    value?: string
    disabled?: boolean
    placeholder: string
    error?: boolean
    errorLabel?: string
    title?: string
    onChange?: (value: string | undefined) => void
    type: 'entry' | 'departure'
}

export function ProtocolSelectFromDate(props: Props) {
    const [options, setOptions] = useState<OptionType[]>([])
    const DepartureProtocolService = NewDepartureProtocolService()
    const EntryProtocolService = NewEntryProtocolService()

    useEffect(() => {
        if(props.type === 'entry')
            EntryProtocolService.GetAllEntryProtocols({client: props.customerId, from: props.fromDate, itemsPerPage: '0', page: 0})
                .then(response => {
                    const data: OptionType[] = response.data.map(ep => {
                        return {
                            label: `${ep.headQuarter} E${ep.protocolNumber}`,
                            value: ep.id
                        } as OptionType
                    })
                    setOptions(data)
                    })
        else
            DepartureProtocolService.GetAllDepartureProtocols({client: props.customerId, from: props.fromDate, itemsPerPage: '0', page: 0})
                .then(response => {
                    const data: OptionType[] = response.data.map(ep => {
                        return {
                            label: `${ep.headQuarter} P${ep.protocolNumber}`,
                            value: ep.id
                        } as OptionType
                    })
                    setOptions(data)
                })
    }, [props.fromDate])
    
    return (
        <div>
            {
                props.title && (
                    <div className="text-left">
                        <span className="select-search-title">{props.title}</span>
                    </div>
                )
            }
            <SelectCustom
                defaultValue={props.defaultValue}
                value={props.value}
                disabled={props.disabled}
                placeholder={props.placeholder}
                error={props.error}
                errorLabel={props.errorLabel}
                onChange={(value) => props.onChange && props.onChange(value)}
                options={options} 
                />
            
        </div>
    )
}