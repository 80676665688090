import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import Button from '../../../../ui/molecules/button'
import { Calendar } from '../../../../ui/molecules/calendar'
import Input from '../../../../ui/molecules/input'
import { ProtocolSearch } from '../../../../ui/molecules/ProtocolSearch'
import { SelectCustom } from '../../../../ui/molecules/select'
import TextArea from '../../../../ui/molecules/textArea'
import { ReportingStatusOption, ReportingTypologyOption } from '../../../../utils'
import { setReportingCustomerOtherODC, setReportingDetails, setReportingEntryProtocolId, setReportingNote, setReportingReportingDate, setReportingReportingState, setReportingReportingType, setReportingSender, setUpdateReportingCustomerOtherODC, setUpdateReportingDetails, setUpdateReportingEntryProtocolId, setUpdateReportingNote, setUpdateReportingReportingDate, setUpdateReportingReportingState, setUpdateReportingReportingType, setUpdateReportingSender } from '../slice'
import '../styles.scss'

export function AddReportingForms() {
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)

    return (
        <div>

            {/* HEADER */}
            <div className="reporting-header">
                <span className='title'>Nuova segnalazione</span>
            </div>

            {/* CONTENT */}
            <div className='reporting-content'>

                {/* FIRST ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data</span>
                            <Calendar
                                errorLabel={reportingState.reportingErrors.reportingDate ? 'Data mancante' : undefined}
                                selected={!reportingState.fromCreationToUpdate ?
                                            reportingState.reportingCreationRequest.reportingDate :
                                            reportingState.reportingUpdateRequest.reportingDate}
                                onChange={(date) => !reportingState.fromCreationToUpdate ?
                                                        dispatch(setReportingReportingDate(date)) :
                                                        dispatch(setUpdateReportingReportingDate(date))
                                }
                            />
                        </div>
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mittente</span>
                        </div>
                        <Input
                            error={reportingState.reportingErrors.sender}
                            supportingText={reportingState.reportingErrors.sender ? 'Mittente mancante' : undefined}
                            placeholder="Specifica mittente..."
                            onChangeText={(text) => !reportingState.fromCreationToUpdate ?
                                dispatch(setReportingSender(text)) :
                                dispatch(setUpdateReportingSender(text))}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Cliente Altro ODC</span>
                        </div>
                        <Input
                            placeholder="Specifica cliente..."
                            onChangeText={(text) => !reportingState.fromCreationToUpdate ?
                                dispatch(setReportingCustomerOtherODC(text)) :
                                dispatch(setUpdateReportingCustomerOtherODC(text))}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Tipologia</span>
                        </div>
                        <SelectCustom
                            error={reportingState.reportingErrors.reportingType}
                            errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            options={ReportingTypologyOption}
                            onChange={(value) => !reportingState.fromCreationToUpdate ?
                                dispatch(setReportingReportingType(value)) :
                                dispatch(setUpdateReportingReportingType(value))}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Stato</span>
                        </div>
                        <SelectCustom
                            error={reportingState.reportingErrors.reportingState}
                            errorLabel='Stato mancante'
                            placeholder={'Seleziona stato...'}
                            options={ReportingStatusOption}
                            onChange={(value) => !reportingState.fromCreationToUpdate ?
                                dispatch(setReportingReportingState(value)) :
                                dispatch(setUpdateReportingReportingState(value))}
                        />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className='pb-5 pt-5'>
                    <ProtocolSearch
                        errorLabel={reportingState.reportingErrors.entryProtocolId ? 'Protocollo mancante' : undefined}
                        type='entry'
                        onSelect={(value) => !reportingState.fromCreationToUpdate ?
                            dispatch(setReportingEntryProtocolId(value)) :
                            dispatch(setUpdateReportingEntryProtocolId(value))} 
                        />
                </div>

                {/* FORTH ROW */}
                <div className='text-left'>
                    <span className='input-label'>Dettagli della segnalazione </span>
                </div>
                <div className='pb-5'>
                    <TextArea
                        error={reportingState.reportingErrors.details}
                        supportingText={reportingState.reportingErrors.details ? 'Dettagli mancanti' : undefined}
                        placeholder="Specificare i dettagli della segnalazione..."
                        onChangeText={(text) => !reportingState.fromCreationToUpdate ?
                            dispatch(setReportingDetails(text)) :
                            dispatch(setUpdateReportingDetails(text))}
                    />
                </div>

                {/* FIFTH ROW */}
                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        onChangeText={(text) => !reportingState.fromCreationToUpdate ?
                            dispatch(setReportingNote(text)) :
                            dispatch(setUpdateReportingNote(text))}
                        />
                </div>
                
            </div>
        </div>
    )
}