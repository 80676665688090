import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import '../style.scss';
import { Province, Region, formatterDate, genreSelect, regioniProvinceMap, regions } from "../../../utils";
import { provinceComuniMap } from "../../../utils/listaComuni";
import { SelectCustom } from "../../../ui/molecules/select";
import { GetBusinessTechnician, GetBusinessTechnicianById, resetBusinessTechnicianByIdResponse, setBusinessTechnician, setBusinessTechnicianByIdStatusIdle, setIdBusinessTechUser } from "../../businessTechnician/slice";
import { GetBusinessTechnicianDTO } from "../../businessTechnician/dto";
import Button from "../../../ui/molecules/button";
import { findUserById, RemoveBusinessTechnician, resetGetUserByIdStatusIdle, resetRemoveBusTechFromUserStatus } from "../../users/slice";

registerLocale('it', it)

type OptionType = {
    value: string;
    label: string;
};


export function BoxTecnicoAziendaleEdit() {

    const [showBoxBT, setShowBoxBT] = useState<boolean>(false);
    const sessionState = useAppSelector(state => state.session)
    const businessTechinianState = useAppSelector(state => state.businessTechinician)
    const userState = useAppSelector(state => state.users)
    const clientiState = useAppSelector(state => state.clienti)
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const [optionsBT, setOptionsBT] = useState<OptionType[]>([]);
    const dispatch = useAppDispatch()
    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    useEffect(() => {
        dispatch(setIdBusinessTechUser(undefined))
        dispatch(GetBusinessTechnician())
        console.info('id attuale ', businessTechinianState.idBusinessTechUser);
        
        if (businessTechinianState.idBusinessTechUser && businessTechinianState.idBusinessTechUser.length > 0) {
            dispatch(GetBusinessTechnicianById(businessTechinianState.idBusinessTechUser[businessTechinianState.idBusinessTechUser.length - 1]))
            dispatch(setBusinessTechnician(businessTechinianState.idBusinessTechUser[businessTechinianState.idBusinessTechUser.length - 1]))
        }

    }, [])

    useEffect(() => {
        if (userState.findUserByIdStatus === 'successfully') {
            dispatch(setIdBusinessTechUser(undefined))
            if (userState.findUserByIdResponse !== undefined) {
                
                if (userState.findUserByIdResponse.businessTechnicianIds !== undefined && userState.findUserByIdResponse.businessTechnicianIds.length > 0) {
                    dispatch(setIdBusinessTechUser(userState.findUserByIdResponse.businessTechnicianIds[userState.findUserByIdResponse.businessTechnicianIds.length - 1]))
                }
               
            }
            dispatch(resetGetUserByIdStatusIdle())
        }
    }, [userState.findUserByIdStatus])

    useEffect(() => {
        if (clientiState.customerEditStatus === 'successfully') {
            dispatch(GetBusinessTechnicianById(businessTechinianState.userBusinessTechnicianLink.techId))
        }
    }, [clientiState.customerEditStatus])

    useEffect(() => {
        if (businessTechinianState.businessTechByIdStatus === 'successfully') {
            setShowBoxBT(true)
            dispatch(setBusinessTechnicianByIdStatusIdle())
        }
    }, [businessTechinianState.businessTechByIdStatus])

    useEffect(() => {
        if (businessTechinianState.businessTechinicianResponseStatus === 'successfully') {
            if (businessTechinianState.businessTechinicianResponse !== undefined) {
                const mappedOptions = businessTechinianState.businessTechinicianResponse.data.map((techinician: GetBusinessTechnicianDTO) => ({
                    value: techinician.id,
                    label: techinician.companyName
                }));
                setOptionsBT(mappedOptions);
            }

        }
    }, [businessTechinianState.businessTechinicianResponseStatus])

    

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };


    return (
        <>
            <div id="boxTecnicoAziendale" className="" style={{ padding: '24px' }}>

                <>
                    <div className="boxCompanyTech bg-brandPrimary-100">
                        <SelectCustom
                            defaultValue={businessTechinianState.userBusinessTechnicianLink.techId}
                            value={businessTechinianState.userBusinessTechnicianLink.techId}
                            placeholder={'Seleziona Tecnico'}
                            options={optionsBT}
                            onChange={(value) => {
                                dispatch(setBusinessTechnician(value))

                            }
                            }
                        />
                    </div>
                    {(businessTechinianState.businessTechById !== undefined && businessTechinianState.businessTechById.id) && (
                        <div className="reviewCompanyTech mt-6">
                            <div className="headerReview  bg-white">
                                <div className="left">
                                    <span>{businessTechinianState.businessTechById.companyName}</span>
                                </div>

                                <div className="right">
                                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Dissocia" color={"red"}
                                        onClick={() => {
                                            if(businessTechinianState.businessTechById && businessTechinianState.businessTechById.id !== undefined && clientiState.getCustomersByIdResponse?.userId !== undefined) {
                                                dispatch(RemoveBusinessTechnician({id: clientiState.getCustomersByIdResponse?.userId, techId: businessTechinianState.businessTechById.id}))
                                            }
                                           
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="boxTableReview bg-brandPrimary-200 ">
                                <table className="tableReview w-[100%]">
                                    <tbody>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Cognome e nome</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.companyName}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Codice Fiscale</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.fiscalCode}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Partita IVA</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.vat}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Regione</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.countryRegion}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Provincia</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.province}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Comune</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.city}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Indirizzo</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.address}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>CAP</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.zipCode}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Email</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.email}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>PEC</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.pec}t</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Data Ingresso</td>
                                            <td className="text-left">{formatterDate(businessTechinianState.businessTechById.activationDate)}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Data Usicta</td>
                                            <td className="text-left">{formatterDate(businessTechinianState.businessTechById.deactivationDate)}</td>
                                        </tr>
                                        <tr className="rowDetails">
                                            <td className="text-left label" style={{ width: '240px' }}>Note</td>
                                            <td className="text-left">{businessTechinianState.businessTechById.notes}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                </>

            </div>
        </>
    )
}