import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocols, resetGetAllEntryProtocolsStatus, setEntryProtocolIdToUpdate, setGetAllEPFilterPage, setUpdatedEPFileAndAtt, setUpdatedFile, setUpdateEPCustomerIds, setUpdateEPCustomersNotRegistered, setUpdateEPDestinationOffice, setUpdateEPEntryAttachmentIds, setUpdateEPEntryChannel, setUpdateEPEntryObjectId, setUpdateEPHeadquarter, setUpdateEPLinkExistingProtocol, setUpdateEPNation, setUpdateEPObjectSpec, setUpdateEPProtocolDate, setUpdateEPRegion, setUpdateEPSend, setUpdateEPSender, setUpdateEPTotalPage, setUpdateEPUsersVisibility } from "./slice";
import { entryMeansOptions, formatterDate, optionsFormatter } from "../../../utils";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { EntryProtocolDTO } from "./dataService/dto";
import { GetFileObjectById } from "../../../objectStorage/slice";
import { Pagination } from "../../../ui/organisms/pagination";


export function EntryListProtocol() {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const objectStorageState = useAppSelector(state => state.objectStorage)

    const handleOnEdit = (data: EntryProtocolDTO) => {
        dispatch(setEntryProtocolIdToUpdate(data.id))
        dispatch(setUpdateEPHeadquarter(data.headQuarter))
        dispatch(setUpdateEPProtocolDate(data.protocolDate))
        dispatch(setUpdateEPNation(data.nation))
        dispatch(setUpdateEPRegion(data.region))
        dispatch(setUpdateEPSender(data.sender))
        if (data.customersInfo !== null)
            dispatch(setUpdateEPCustomerIds(data.customersInfo.map(c => c.id)))
        dispatch(setUpdateEPCustomersNotRegistered(data.customersNotRegistered))
        dispatch(setUpdateEPEntryObjectId(data.entryObject.id))
        dispatch(setUpdateEPObjectSpec(data.objectSpec))
        dispatch(setUpdateEPTotalPage(data.totalPage))
        dispatch(setUpdateEPEntryChannel(data.entryChannel))
        dispatch(setUpdateEPSend(data.send))
        dispatch(setUpdateEPUsersVisibility(data.usersVisibility))
        dispatch(setUpdateEPLinkExistingProtocol(data.linkExistingProtocol))
        dispatch(setUpdateEPDestinationOffice(data.destinationOffice))
        dispatch(setUpdateEPEntryAttachmentIds(data.entryAttachments.map(a => a.id)))
        if (data.fileId !== null && data.fileId !== undefined) {
            dispatch(GetFileObjectById(data.fileId))
        }
        dispatch(setUpdatedEPFileAndAtt(data.attachmentFile))
        navigate('/editEntryProtocol')
    }

    useEffect(() => {
        if (objectStorageState.getFileObjectByIdStatus === 'successfully') {
            dispatch(setUpdatedFile(objectStorageState.getFileObjectByIdResponse))
        }

    }, [objectStorageState.getFileObjectByIdStatus])

    // useEffect(() => {
    //     dispatch(GetAllEntryProtocols(entryProtocolState.getAllEntryProtocolFilters))
    // }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolsStatus === 'successfully') {
            dispatch(resetGetAllEntryProtocolsStatus())
        }
    }, [entryProtocolState.getAllEntryProtocolsStatus])
    return (
        <div className="w-[100%]">
            <Pagination
                numbResults={entryProtocolState.getAllEntryProtocolsResponse ? entryProtocolState.getAllEntryProtocolsResponse?.total : 0}
                detailsResults={'Protocolli'}
                fullWidth={true}
                pages={entryProtocolState.getAllEntryProtocolsResponse?.totalPage || 1}
                currentPage={entryProtocolState.getAllEntryProtocolFilters.page ? entryProtocolState.getAllEntryProtocolFilters.page : 0} setPage={(page) => dispatch(setGetAllEPFilterPage(page))}
            />
            <div style={{ padding: '24px 0' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th>Numero</th>
                            <th>Sede</th>
                            <th>Anno</th>
                            <th>Data</th>
                            <th>Mittente</th>
                            <th>Cliente</th>
                            <th>Oggetto</th>
                            <th>Oggetto Extra</th>
                            <th>Mezzo Entrata</th>
                            <th>Funzionario</th>
                            <th>Allegati</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (entryProtocolState.getAllEntryProtocolsResponse?.data && entryProtocolState.getAllEntryProtocolsResponse?.data.length > 0)
                            && entryProtocolState.getAllEntryProtocolsResponse?.data.map((protocol, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>{protocol.headQuarter} {'E' + protocol.protocolNumber}</td>
                                    <td>{protocol.headQuarter}</td>
                                    <td>{protocol.year}</td>
                                    <td>{formatterDate(protocol.protocolDate)}</td>
                                    <td>{protocol.sender}</td>
                                    <td>
                                        {protocol.customersInfo && protocol.customersInfo.map((customer, index, customers) =>
                                            <span>{customer.companyName}{index < customers.length - 1 && ', '}</span>
                                        )}
                                    </td>
                                    <td>
                                        {
                                            protocol.entryObject.description
                                        }
                                    </td>
                                    <td>{protocol.objectSpec}</td>
                                    <td>
                                        {
                                            protocol.entryChannel &&
                                            optionsFormatter(protocol.entryChannel, entryMeansOptions)
                                        }
                                    </td>
                                    <td>{`${protocol.user.name} ${protocol.user.surname}`}</td>
                                    <td>
                                        {
                                            protocol.entryAttachments.map((att, index) => {
                                                if (index !== protocol.entryAttachments.length - 1)
                                                    return `[${att.coding}] - `
                                                return `[${att.coding}]`
                                            })
                                        }
                                    </td>
                                    <td className="w-[30px]">
                                        <div className="flex justify-content-between align-items-center">
                                            <button onClick={() => {
                                                handleOnEdit(protocol)
                                            }}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>

                                            {/* <div className="pl-3 flex align-items-center">
                                                <button onClick={() => { }}>
                                                    <TrashIcon color={"Orange"} size={0} />
                                                </button>
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>

            </div>
        </div>
    )
}