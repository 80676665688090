import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import Button from "../../../ui/molecules/button"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { GenericBox } from "../components/genericBox"
import { GestioneAllegatiBox } from "../components/gestioneAllegatiBox"
import { LogoSpinnerComponent } from "../../../lib/spinner/spinnerLogo"

export function Gestione() {
    const navigate = useNavigate()

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione']}
            noPaddingLat
            headerLabel="Gestione"
        >
            <div style={{ padding: '24px' }}>
                {/* <LogoSpinnerComponent fullScreen/> */}
                <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                    <div>
                        <GestioneAllegatiBox />
                    </div>
                    <div>
                        <GenericBox 
                            title="Gestione Tipologia Registri"
                            description="Creazione e modifica delle tipologie di registro"
                            onClick={() => navigate('/gestioneTipologiaRegistri')}
                            />
                    </div>
                </div>
            </div>
        </Layout>
    )
}